import React, { useState, useCallback, memo } from 'react'
import { Input, Button, Space, Typography, InputProps } from 'antd'
import { SettingOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { IntentBranch } from '../../model'

const { TextArea } = Input

interface IntentEditorProps {
  intent: IntentBranch
  inputStatus?: InputProps['status']
  onIntentChange: (
    intentToChange: string,
    field: keyof IntentBranch,
    value: any,
    index: number
  ) => void
  onRemoveIntent: (intentToRemove: string, index: number) => void
  onOpenSettings: (index: number) => void
  index: number
}

const IntentEditor = (props: IntentEditorProps) => {
  const {
    index,
    intent,
    inputStatus,
    onIntentChange,
    onRemoveIntent,
    onOpenSettings,
  } = props
  const [localIntent, setLocalIntent] = useState(intent.intent)
  const [localDescription, setLocalDescription] = useState(intent.description)

  const handleIntentBlur = useCallback(() => {
    if (localIntent !== intent.intent) {
      onIntentChange(intent.intent, 'intent', localIntent, index)
    }
  }, [localIntent, intent.intent, onIntentChange])

  const handleDescriptionBlur = useCallback(() => {
    if (localDescription !== intent.description) {
      onIntentChange(intent.intent, 'description', localDescription, index)
    }
  }, [localDescription, intent.description, intent.intent, onIntentChange])

  return (
    <div style={{ marginBottom: '16px' }}>
      <Space.Compact style={{ marginBottom: '10px', width: '100%' }}>
        <Input
          value={localIntent}
          onChange={(e) => setLocalIntent(e.target.value)}
          onBlur={handleIntentBlur}
          placeholder="Intent"
          status={inputStatus}
        />
        <Button
          icon={<SettingOutlined />}
          onClick={() => onOpenSettings(index)}
        />
        <Button
          icon={<MinusCircleOutlined />}
          onClick={() => onRemoveIntent(intent.intent, index)}
        />
      </Space.Compact>
      <TextArea
        value={localDescription}
        onChange={(e) => setLocalDescription(e.target.value)}
        onBlur={handleDescriptionBlur}
        placeholder="Intent description"
        autoSize={{ minRows: 2, maxRows: 4 }}
        style={{ marginBottom: '8px' }}
      />
    </div>
  )
}

export default IntentEditor
