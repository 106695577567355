import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { observer } from 'mobx-react'
import UserTable, { ActionType, UserTableRef } from './user-table'
import UserModal, { UserModalProps } from './user-modal'
import { UserStatus } from 'stores/models/user'
import userStore from 'stores/user'

const DEFAULT_USER_DATA: UserModalProps['data'] = {
  organizationId: 0,
  roleId: undefined,
  name: '',
  email: '',
  status: UserStatus.Active,
}

const UserPage: React.FC = () => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false)
  const [user, setUser] = useState<UserModalProps['data']>(DEFAULT_USER_DATA)
  const userTableRef = React.createRef<UserTableRef>()

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>User</h1>
            <p></p>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setUser(DEFAULT_USER_DATA)
                setIsUserModalOpen(true)
              }}
            >
              Create User
            </Button>
          </div>
        </div>
        <UserTable
          ref={userTableRef}
          onAction={(type, user) => {
            if (type === ActionType.Edit) {
              setUser({
                id: user.id,
                organizationId: user.organizationID,
                roleId: user.roleID,
                name: user.name,
                email: user.email,
                password: '',
                status: user.status,
              })
              setIsUserModalOpen(true)
            }
          }}
        />
        <UserModal
          open={isUserModalOpen}
          title={!!user.id ? 'Edit User' : 'Create User'}
          okText="Save"
          data={{
            ...user,
            organizationId: userStore.loginUser?.organizationID ?? 0,
          }}
          onFinish={() => {
            userTableRef.current?.refresh()
            setIsUserModalOpen(false)
          }}
          onCancel={() => setIsUserModalOpen(false)}
        />
      </div>
    </>
  )
}

export default observer(UserPage)
