import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Breadcrumb,
  Menu,
  Input,
  Skeleton,
  message,
  MenuProps,
} from 'antd'
import { PlusOutlined, SendOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import Header from 'layouts/portal/header'
import chatStore from 'stores/chat'
import Message from './message'
import config from 'config'

const Chat: React.FC = observer(() => {
  const { TextArea } = Input
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const [sendMessageLoading, setSendMessageLoading] = useState(false)
  const [sessionLoading, setSessionLoading] = useState(false)
  const [contextLoading, setContextLoading] = useState(false)
  const [agentLoading, setAgentLoading] = useState(false)
  const [items, setItems] = useState<MenuProps['items']>([])
  const contentRef = useRef<HTMLDivElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const agentUUID = searchParams.get('agent_uuid')
  const sessionUUID = searchParams.get('session_uuid')

  const menuItems: MenuProps['items'] = []
  useEffect(() => {
    chatStore.clearConversations()
    chatStore.newSession()
    if (sessionUUID) {
      setContextLoading(true)
      chatStore.getConversations(sessionUUID).finally(() => {
        setContextLoading(false)
        autoScroll()
      })
    }
    setSessionLoading(true)
    chatStore.getSessions(agentUUID ? agentUUID : '').finally(() => {
      setSessionLoading(false)
    })
    setAgentLoading(true)
    chatStore.getAgent(agentUUID ? agentUUID : '').finally(() => {
      setAgentLoading(false)
    })
  }, [])

  const getMenuItems = (): MenuProps['items'] => {
    return chatStore.groupedSessions
      .filter((group) => group.sessions.length > 0)
      .map((group) => ({
        key: `g-${group.period}`,
        label: group.period.charAt(0).toUpperCase() + group.period.slice(1),
        type: 'group' as const,
        children: group.sessions.map((session) => ({
          key: session.uuid,
          label: session.title,
        })),
      }))
  }

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(
      '/portal/agent/chat?agent_uuid=' + agentUUID + '&session_uuid=' + e.key,
      { replace: true }
    )

    setContextLoading(true)
    chatStore.getConversations(e.key).finally(() => {
      setContextLoading(false)
      autoScroll()
    })
  }

  const handleNewSession = () => {
    chatStore.newSession()
  }

  const handleSendMessage = () => {
    if (content && !sendMessageLoading) {
      setContent('')
      setSendMessageLoading(true)
      autoScroll()
      chatStore
        .send(content)
        .then(() => {
          autoScroll()
        })
        .catch((err) => {
          message.error(
            'Oops! There was a problem delivering your message. Please try sending it again.'
          )
        })
        .finally(() => {
          setSendMessageLoading(false)
        })
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const autoScroll = () => {
    setTimeout(() => {
      const element = contentRef.current
      if (!element) return
      const maxScroll = element.scrollHeight - element.clientHeight
      if (element.scrollTop === maxScroll) return
      const scrollStep = (maxScroll - element.scrollTop) / 10
      let lastScrollTop = element.scrollTop
      const animateScroll = () => {
        if (!element) return
        const newScrollTop = Math.min(element.scrollTop + scrollStep, maxScroll)
        element.scrollTop = newScrollTop
        if (newScrollTop < maxScroll && lastScrollTop !== newScrollTop) {
          lastScrollTop = newScrollTop
          requestAnimationFrame(animateScroll)
        }
      }
      requestAnimationFrame(animateScroll)
    }, 100)
  }

  return (
    <>
      <div className="navigation">
        <div className="title">
          <Breadcrumb
            items={[
              {
                title: <Link to="/portal/agent">Agent</Link>,
              },
              {
                title: 'Chat Session',
              },
            ]}
          />
        </div>
      </div>
      <div className="chat">
        <div className="side">
          <Button
            icon={<PlusOutlined />}
            className="new_chat_button"
            onClick={handleNewSession}
          >
            New Chat
          </Button>
          <div className="sessions">
            {sessionLoading ? (
              <Skeleton active />
            ) : (
              <Menu
                style={{ width: 210 }}
                mode="inline"
                items={getMenuItems()}
                onClick={onClick}
                selectedKeys={
                  chatStore.currentSessionUUID
                    ? [chatStore.currentSessionUUID]
                    : []
                }
              />
            )}
          </div>
        </div>
        <div className="chatbox">
          <div className="content" ref={contentRef}>
            {chatStore.conversations.map((conversation, i: number) => {
              const avatarUrl = chatStore.agent?.agentIconPath
                ? config.STATIC_URL + chatStore.agent?.agentIconPath
                : ''
              const name =
                conversation.role === 'assistant'
                  ? chatStore.agent?.agentName
                  : 'User'
              return (
                <Message
                  key={i}
                  avatarUrl={avatarUrl}
                  name={name}
                  content={conversation.content}
                  role={conversation.role}
                  isLoading={conversation.isLoading}
                  currentNodeID={conversation.currentNodeID}
                  currentNodeLabel={conversation.currentNodeLabel}
                  error={conversation.error}
                  startTime={conversation.startTime}
                  endTime={conversation.endTime}
                />
              )
            })}
          </div>
          <div className="inputbar">
            <div className="input">
              <TextArea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type Something..."
                autoSize={{ minRows: 1, maxRows: 5 }}
              />
            </div>
            <div className="send_button">
              <Button
                icon={<SendOutlined />}
                size={'large'}
                onClick={handleSendMessage}
                loading={sendMessageLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default Chat
