import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, Typography, Button } from 'antd';
import ReactMarkdown from 'react-markdown';
import { UserOutlined, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration';
import remarkGfm from 'remark-gfm'


dayjs.extend(duration);

type TimeInput = string | number | Date | dayjs.Dayjs;
interface MessageProps {
  avatarUrl: string;
  name: string | undefined;
  content: string;
  role: string;
  isLoading: boolean;
  error: boolean;
  currentNodeLabel: string;
  currentNodeID: string;
  startTime: string;
  endTime: string;
}

const Message: React.FC<MessageProps> = observer(
  ({ avatarUrl, name, content, role, isLoading, error, currentNodeLabel, currentNodeID, startTime, endTime }) => {
    
    function getConciseDuration(time1: TimeInput, time2: TimeInput): string {
      const date1 = dayjs(time1);
      const date2 = dayjs(time2);
      
      const diff = Math.abs(date2.diff(date1));
      const durationInSeconds = diff / 1000;
      
      if (durationInSeconds < 60) {
        return `${Math.round(durationInSeconds)} second${Math.round(durationInSeconds) !== 1 ? 's' : ''}`;
      } else {
        const duration = dayjs.duration(diff);
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
      }
    }
    
    function getTimeDifferenceFromNow(time: TimeInput): string {
      const now = dayjs();
      return getConciseDuration(now, time);
    }
    const components = {
      code({ node, inline, className, children, ...props }: any) {
        let match = /language-(\w+)/.exec(className || '');
        let language = "python"
        if (match){
          language = match[1]
        }
        return !inline && match ? (
          <SyntaxHighlighter
            style={oneLight}
            language={language}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        ) : (
          <code {...props}>
            {children}
          </code>
        );
      },
      p({ children, ...props }: React.HTMLProps<HTMLParagraphElement>) {
        return (
          <p {...props}>
            {children}
            {isLoading ? <span style={{paddingLeft: 5}}><LoadingOutlined /></span> : null}
          </p>
        );
      },
    };
    console.log(isLoading, content)
    return (
      <div className="dialog_box">
        <div className="avatar">
          <Avatar
            icon={role === 'assistant' && avatarUrl ? undefined : <UserOutlined />}
            src={role === 'assistant' ? avatarUrl : undefined}
            style={{ width: '35px', height: '35px' }}
          />
        </div>
        <div>
          <div className="user">{name}</div>
          <div className="markdown-body">
            {role === 'assistant' && (
              <>
                {isLoading && (
                  <div className='statusbar'>
                    <span style={{paddingLeft: 5}}>
                      <LoadingOutlined /> {currentNodeLabel} Processing {startTime ? getTimeDifferenceFromNow(startTime) : null}
                    </span>
                  </div>
                )}
                {!isLoading && startTime && (
                  <div className='statusbar'>
                    <span style={{paddingLeft: 5}}>
                      <CheckCircleOutlined style={{color: "green"}} /> Workflow Execution Completed ({getConciseDuration(startTime, endTime)})
                    </span>
                  </div>
                )}
              </>
            )}
            <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
              {content}
            </ReactMarkdown>
          </div>
          {error && (
            <Button 
              danger 
              onClick={() => {
                // Implement retry logic here
                console.log('Retry clicked for message:', content);
              }}
            >
              Retry
            </Button>
          )}
        </div>
      </div>
    );
  }
);

export default Message;