import BaseRequest from './request'
import { TIME_OUT, INSTANCE_INTERCEPTORS } from './config'

const defaultAxiosRequestConfig = {
  timeout: TIME_OUT,
}

const baseRequest = new BaseRequest({
  ...defaultAxiosRequestConfig,
  interceptors: INSTANCE_INTERCEPTORS,
})

export { baseRequest }
