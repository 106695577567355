import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Upload,
  UploadFile,
  UploadProps,
  GetProp,
  Space,
} from 'antd'
import {
  RobotOutlined,
  ApiOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { ReactNode, useEffect, useState } from 'react'
import { Agent, AgentType } from 'stores/models/agent'
import agentStore from 'stores/agent'
import { getFileUrl, getMessageFromError, isEmpty } from 'utils/common'
import fileStore, { FileDocument, FileBelongsType } from 'stores/file'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export interface AgentModalProps {
  data: {
    id?: number
    uuid?: string
    name: string
    description: string
    agentType: AgentType
    iconId?: number
    iconUuid?: string
    organizationId?: number
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (agentUuid?: string) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const AgentModal: React.FC<AgentModalProps> = (props) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile<any>[]>([])
  const [imageUrl, setImageUrl] = useState<string>(
    getFileUrl(props.data.iconUuid ?? '')
  )
  const [formValues, setFormValues] = useState({
    name: props.data.name,
    description: props.data.description,
    agentType: props.data.agentType,
  })

  useEffect(() => {
    setFormValues(props.data)
  }, [props.data])

  const handleSave = async (values: AgentModalProps['data']) => {
    setConfirmLoading(true)
    try {
      let iconId = props.data.iconId ?? 0
      if (fileList.length > 0) {
        const file = fileList[0].response[0] as FileDocument
        iconId = file.id
      }
      if (props.data.id === undefined) {
        const response = await agentStore.create(
          values.name,
          values.description,
          values.agentType,
          iconId
        )
        const data = response.data as Agent
        return data.uuid
      } else {
        await agentStore.update(
          props.data.id,
          values.name,
          values.description,
          values.agentType,
          iconId
        )
      }
      return props.data.uuid
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: AgentModalProps['data']) => {
    const agentUuid = await handleSave(values)
    if (isEmpty(agentUuid)) {
      return
    }
    if (props.onFinish) {
      props.onFinish(agentUuid)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    action: fileStore.getUploadUrl(FileBelongsType.AgentIcon),
    beforeUpload: (file: FileType) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        message.error('Image must smaller than 10MB!')
      }
      return isJpgOrPng && isLt10M
    },
    onChange: (info) => {
      setFileList(info.fileList)

      switch (info.file.status) {
        case 'uploading':
          setIsUploading(true)
          break
        case 'done':
          getBase64(info.file.originFileObj as FileType, (url) => {
            setImageUrl(url)
          })
          setIsUploading(false)
          break
        case 'error':
          message.error(`${info.file.name} file upload failed.`)
          setIsUploading(false)
          break
        case 'removed':
          setIsUploading(false)
          break
      }
    },
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )

  return (
    <Modal
      title={props.title ?? (props.data.id ? 'Edit' : 'Create')}
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 620 }}
        initialValues={formValues}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label={<h3>What type of app do you want to create?</h3>}
          name="agentType"
          rules={[{ required: true, message: '' }]}
        >
          <Radio.Group disabled={!!props.data.id}>
            <Radio.Button value={AgentType.ChatBot}>
              <Space size="small">
                <RobotOutlined />
                Chat Bot
              </Space>
            </Radio.Button>
            <Radio.Button value={AgentType.SmartApi}>
              <Space size="small">
                <ApiOutlined />
                Smart API
              </Space>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={<h3>Agent icon</h3>}>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            {...uploadProps}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label={<h3>Agent name</h3>}
          name="name"
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder="Give your agent a name" />
        </Form.Item>
        <Form.Item
          label={<h3>Description</h3>}
          name="description"
          rules={[{ required: false, message: '' }]}
        >
          <Input.TextArea
            showCount
            maxLength={150}
            rows={5}
            placeholder="Enter the description of the agent"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            {props.okText || 'Ok'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AgentModal
