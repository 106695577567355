import React, { useEffect, useRef, useState, memo } from 'react'
import { Button, Input, InputProps, Space } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import cls from 'classnames'
import { assignIn, cloneDeep } from 'lodash-es'

import styles from './index.scss'

export type CustomCompactInputValueItemType = {
  label: string
  value: string

  [key: string]: any
} & InputProps

export type CustomCompactInputType = {
  value: Array<Array<CustomCompactInputValueItemType>>
  onChange: (v: CustomCompactInputValueItemType[][]) => void
  defaultValue?: Array<CustomCompactInputValueItemType>

  maxCount?: number
  onAddItem?: () => void
  onDeleteItem?: (index: number) => void
  onFormatValue?: (v: any) => CustomCompactInputValueItemType[][]
  wrapperClassName?: string
}

const DEFAULT_VALUE = [{ label: 'Label', value: '' }]

const CustomCompactInput = (props: CustomCompactInputType) => {
  const {
    value,
    defaultValue,
    maxCount,
    onChange,
    onAddItem,
    onDeleteItem,
    onFormatValue,
    wrapperClassName,
  } = props
  const formatValue = onFormatValue?.(value) ?? value ?? []
  const [curValues, setCurValues] = useState(formatValue)
  const defaultValRef = useRef<Array<CustomCompactInputValueItemType>>()

  const handleValueChange = (
    pos: [number, number],
    key: string,
    value: string
  ) => {
    const [x, y] = pos
    const newValues = [...curValues]
    const curItem = newValues?.[x]?.[y]

    Reflect.set(curItem, key, value)

    onChange?.(newValues)
  }

  const handleAddItem = () => {
    if (onAddItem) return onAddItem()
    const newValues = [...curValues]

    newValues.push(defaultValRef.current ?? defaultValue ?? DEFAULT_VALUE)
    onChange?.(newValues)
  }

  const handleDeleteItem = (idx: number) => {
    if (onDeleteItem) return onDeleteItem(idx)
    const newValues = [...curValues]
    newValues.splice(idx, 1)
    onChange?.(newValues)
  }

  useEffect(() => {
    if (!defaultValRef.current && formatValue?.[0]) {
      defaultValRef.current = cloneDeep(formatValue[0])?.map?.((i) =>
        assignIn(i, { value: '' })
      )
    }
    setCurValues(formatValue)
  }, [value])

  return (
    <div className={cls(wrapperClassName, styles.customCompactInputWrapper)}>
      <div className={styles.customCompactInputItemWrapper}>
        {curValues?.map?.((curRowItems, row) => (
          <Space.Compact
            key={`${row}`}
            size="large"
            className={styles.customCompactInputItem}
          >
            {curRowItems?.map?.((item, col) => {
              const { label, value, ...inputProps } = item
              const isLast = col === curRowItems.length - 1
              return (
                <React.Fragment key={`${row}-${col}-${label}`}>
                  <Input
                    key={`${row}-${col}-${label}`}
                    placeholder={label}
                    onChange={(e) =>
                      handleValueChange([row, col], 'value', e.target.value)
                    }
                    value={value}
                    {...inputProps}
                  />

                  {isLast && (
                    <Button
                      onClick={() => handleDeleteItem(row)}
                      icon={<MinusCircleOutlined />}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </Space.Compact>
        ))}
      </div>

      <Button
        type="dashed"
        block
        icon={<PlusOutlined />}
        disabled={
          (maxCount ? curValues.length >= maxCount : undefined) ||
          curValues[curValues.length - 1]?.[0]?.label === ''
        }
        onClick={handleAddItem}
      >
        Add
      </Button>
    </div>
  )
}

export default memo(CustomCompactInput)
