import React from 'react'
import { Button, Result } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export type SubmissionResultStatus = 'success' | 'error' | 'loading'

export interface SubmissionResultProps {
  onOk?: () => void
  style?: React.CSSProperties
  status: SubmissionResultStatus
}

const SubmissionSuccess: React.FC<SubmissionResultProps> = (props) => {
  const handleBack = () => {
    if (props.onOk) {
      props.onOk()
    }
  }

  return (
    <Result
      status={props.status === 'loading' ? undefined : props.status}
      icon={props.status === 'loading' ? <LoadingOutlined /> : undefined}
      title={
        props.status === 'success'
          ? 'Submission Successful'
          : 'Submission Failed'
      }
      subTitle={
        props.status === 'success'
          ? 'Your intent has been successfully submitted.'
          : 'Failed to submit your intent.'
      }
      extra={[
        <Button
          type="primary"
          key="back"
          onClick={handleBack}
          disabled={props.status === 'loading'}
        >
          Back to Intent List
        </Button>,
      ]}
      style={{ ...props.style }}
    />
  )
}

export default SubmissionSuccess
