import {
  Dropdown,
  theme,
  Avatar,
  Layout,
  Tabs,
  MenuProps,
  App,
  Flex,
  AvatarProps,
  Space,
} from 'antd'

import { UserOutlined } from '@ant-design/icons'
import { useExNavigate } from 'hooks/use-ex-navigate'
import userStore from 'stores/user'
import globalStore from 'stores/global'
import { getFileUrl, getMessageFromError, isEmpty } from 'utils/common'
import Settings from 'views/portal/settings'
import TwoFactorAuth from 'views/portal/settings/two-factor-auth'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import './header.css'
import styles from './index.scss'

export type HeaderTabsProps = {
  link?: string
  key: string
  label: React.ReactNode
  children?: React.ReactNode
}[]

interface HeaderBarProps {
  tabItems?: HeaderTabsProps
  defaultActiveKey?: string
}

const HeaderBar = (props: HeaderBarProps) => {
  const { tabItems, defaultActiveKey } = props
  const { Header } = Layout
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const { message } = App.useApp()
  const navigate = useExNavigate()
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isTwoFactorAuthOpen, setIsIsTwoFactorAuthOpen] = useState(false)
  const [selectedKey, setSelectedKey] = useState(
    defaultActiveKey ? defaultActiveKey : globalStore.header.selectedKey
  )

  const menuItems = tabItems ? tabItems : globalStore.header.menuItems

  const logout = async () => {
    try {
      await userStore.logout()
      navigate('/')
    } catch (error) {
      navigate('/')
      message.error(getMessageFromError(error))
    }
  }

  const userAvatar = (props?: AvatarProps) => {
    if (props?.icon) {
      const icon = props.icon as React.ReactElement
      if (icon.type === 'img' && isEmpty(icon.props.src)) {
        delete props.icon
      }
    }
    return (
      <Avatar
        icon={<UserOutlined />}
        style={{ backgroundColor: '#ccc', ...props?.style }}
        {...props}
      />
    )
  }

  const handleClickSettings = () => {
    setIsSettingsOpen(true)
  }

  const handleClickTwoFactorAuth = () => {
    setIsIsTwoFactorAuthOpen(true)
  }

  const avatarMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      label: (
        <Flex gap="12px" className="deselected-item">
          <span style={{ alignSelf: 'center' }}>
            {userAvatar({
              size: 'large',
              icon: (
                <img src={getFileUrl(userStore.loginUser?.avatarUUID ?? '')} />
              ),
            })}
          </span>
          <div>
            <Space>
              <span style={{ fontWeight: 'bold' }}>
                {userStore.loginUser?.name}
              </span>
              <span>({userStore.loginUser?.roleName})</span>
            </Space>
            <div>{userStore.loginUser?.email}</div>
          </div>
        </Flex>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'settings',
      label: <a onClick={handleClickSettings}>Settings</a>,
    },
    {
      key: 'two-factor-auth',
      label: <a onClick={handleClickTwoFactorAuth}>2FA Setup</a>,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: <a onClick={logout}>Logout</a>,
    },
  ]

  useEffect(() => {
    if (!menuItems || !menuItems.length) return

    const pathArr = location.pathname.split('/')
    const item = menuItems.find((i) => {
      if (!i?.key) return false
      return pathArr.includes(`${i.key}`)
    })
    const curTab = `${item?.key ? item?.key : menuItems?.[0]?.key}`

    setSelectedKey(curTab)
    globalStore.setHeaderSelectedKey(curTab)
  }, [menuItems])

  const handleTabChange = (tab: string) => {
    const curItem = menuItems.find((item) => item.key === tab)
    if (!curItem?.link) return
    setSelectedKey(tab)
    globalStore.setHeaderSelectedKey(tab)
    navigate(curItem.link)
  }

  return (
    <Header className="main-layout-header">
      {menuItems.length ? (
        <div className="sub-menu">
          <Tabs
            defaultActiveKey={defaultActiveKey}
            activeKey={selectedKey}
            items={menuItems}
            centered
            className={styles.headerTabsWrapper}
            tabBarStyle={{ margin: 0 }}
            indicator={{ size: 0 }}
            onChange={handleTabChange}
          />
        </div>
      ) : null}
      <div className="side-menu">
        <Dropdown menu={{ items: avatarMenuItems }} trigger={['click']}>
          <a
            href="#"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <Space>
              <span className="hi-message">
                Hi, {userStore.loginUser?.name}!
              </span>
              {userAvatar({
                icon: (
                  <img
                    src={getFileUrl(userStore.loginUser?.avatarUUID ?? '')}
                  />
                ),
              })}
            </Space>
          </a>
        </Dropdown>
      </div>
      <Settings
        open={isSettingsOpen}
        okText="Save"
        onFinish={() => setIsSettingsOpen(false)}
        onCancel={() => setIsSettingsOpen(false)}
      />
      <TwoFactorAuth
        open={isTwoFactorAuthOpen}
        okText="Save"
        onFinish={() => setIsIsTwoFactorAuthOpen(false)}
        onCancel={() => setIsIsTwoFactorAuthOpen(false)}
      />
    </Header>
  )
}

export default observer(HeaderBar)
