import AppRoutes from './routes'
import { ConfigProvider, App as AntApp } from 'antd'
import { handleAfterMounted } from './global'
import AntdStaticFunction, {
  NotificationGlobalConfig,
} from 'components/antd-static-function'

handleAfterMounted()

const App = () => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#3e3958' } }}>
      <AntApp className={'app-wrapper'} notification={NotificationGlobalConfig}>
        <AntdStaticFunction />
        <AppRoutes />
      </AntApp>
    </ConfigProvider>
  )
}

export default App
