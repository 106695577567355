import React, { useMemo, useState } from 'react'
import {
  Input,
  Select,
  TreeSelect,
  Space,
  Row,
  Col,
  DatePicker,
  InputProps,
} from 'antd'
import { Node, Edge } from '@xyflow/react'
import { InputField as InputFieldType, NodeData, DataTypes } from '../model'
import dayjs from 'dayjs'
import { FunctionOutlined } from '@ant-design/icons'

const { Option } = Select
const { TreeNode } = TreeSelect

type InputFieldProps = {
  index: number
  item: InputFieldType
  currentNodeId: string
  nodes: Node[]
  edges: Edge[]
  handleFormChange: <K extends keyof InputFieldType>(
    index: number,
    key: K,
    value: InputFieldType[K]
  ) => void
  allowEditFieldName?: boolean
  allowEditDataType?: boolean
  allowEditInputType?: boolean
  supportArray?: boolean
  supportObject?: boolean
  displayDataType?: boolean
  displayFieldName?: boolean
  displayInputType?: boolean
  expandSelected?: boolean
  labelInputProps?: InputProps
}

const InputField = (props: InputFieldProps) => {
  const {
    index,
    item,
    currentNodeId,
    nodes,
    edges,
    handleFormChange,
    allowEditFieldName = true,
    allowEditDataType = true,
    allowEditInputType = true,
    supportArray = true,
    supportObject = true,
    displayDataType = true,
    displayFieldName = true,
    displayInputType = true,
    expandSelected = true,
    labelInputProps,
  } = props
  const [fieldType, setFieldType] = useState<string | undefined>(item.dataType)
  const getParentNodes = (
    nodeId: string,
    nodes: Node[],
    edges: Edge[],
    visited: Set<string>
  ): Node[] => {
    if (visited.has(nodeId)) return []
    visited.add(nodeId)

    const parentEdges = edges.filter(
      (edge) => edge.target === nodeId || edge.target.split('-')[0] === nodeId
    )
    const parentNodeIds = parentEdges.map((edge) => edge.source)
    const parentNodes = nodes.filter((node) => parentNodeIds.includes(node.id))

    let allParentNodes: Node[] = []
    parentNodes.forEach((parentNode) => {
      allParentNodes.push(parentNode)
      allParentNodes = [
        ...allParentNodes,
        ...getParentNodes(parentNode.id, nodes, edges, visited),
      ]
    })
    return allParentNodes
  }

  const parentNodes = useMemo(
    () => getParentNodes(currentNodeId, nodes, edges, new Set<string>()),
    [currentNodeId, nodes, edges]
  )
  const renderTreeNodes = (nodes: Node[]): React.ReactNode => {
    const renderedNodes = new Set<string>()

    const renderOutputNodes = (
      parentKey: string,
      outputs: any[]
    ): React.ReactNode => {
      return outputs.map((output) => {
        if (
          output.children &&
          output.children.length > 0 &&
          output.type === 'Object'
        ) {
          return (
            <TreeNode
              key={parentKey + ',' + output.name}
              value={parentKey + ',' + output.name}
              title={
                <span>
                  <FunctionOutlined />
                  <span style={{ marginLeft: '5px' }}>
                    {output.name + ' [' + output.type + ']'}
                  </span>
                </span>
              }
              disabled={output.type !== fieldType}
            >
              {renderOutputNodes(
                parentKey + ',' + output.name,
                output.children
              )}
            </TreeNode>
          )
        }
        return (
          <TreeNode
            key={parentKey + ',' + output.name}
            value={parentKey + ',' + output.name}
            title={
              <span>
                <FunctionOutlined />
                <span style={{ marginLeft: '5px' }}>
                  {output.name + ' [' + output.type + ']'}
                </span>
              </span>
            }
            disabled={output.type !== fieldType}
          />
        )
      })
    }
    return nodes.map((node) => {
      let data = node.data.data as NodeData
      if (
        data &&
        data.output &&
        Array.isArray(data.output) &&
        data.output.length > 0
      ) {
        if (!renderedNodes.has(node.id)) {
          renderedNodes.add(node.id)
          return (
            <TreeNode
              key={node.id}
              value={node.id}
              title={data.label}
              selectable={false}
            >
              {renderOutputNodes(node.id, data.output)}
            </TreeNode>
          )
        }
      }
      if (data && data.branchOutput) {
        if (!renderedNodes.has(node.id)) {
          renderedNodes.add(node.id)
          return (
            <TreeNode
              key={node.id}
              value={node.id}
              title={data.label}
              selectable={false}
            >
              {Object.keys(data.branchOutput).map((handleKey) => {
                const isBranchConnected = edges.some(
                  (edge) =>
                    (edge.source === node.id &&
                      edge.target === currentNodeId &&
                      edge.sourceHandle === handleKey) ||
                    (edge.source === node.id &&
                      parentNodes.some(
                        (parentNode) =>
                          edge.target === parentNode.id &&
                          edge.sourceHandle === handleKey
                      ))
                )
                if (isBranchConnected) {
                  if (!renderedNodes.has(`${node.id},${handleKey}`)) {
                    renderedNodes.add(`${node.id},${handleKey}`)
                    return (
                      <TreeNode
                        key={`${node.id},${handleKey}`}
                        value={`${node.id},${handleKey}`}
                        title={handleKey}
                        selectable={false}
                      >
                        {data.branchOutput[handleKey].output &&
                          renderOutputNodes(
                            `${node.id},${handleKey}`,
                            data.branchOutput[handleKey].output
                          )}
                      </TreeNode>
                    )
                  }
                }
                return null
              })}
            </TreeNode>
          )
        }
      }
      return null
    })
  }

  const renderComparisonInput = () => {
    if (item.type === 'input') {
      switch (fieldType) {
        case 'Boolean':
          return (
            <Select
              placeholder="Value"
              value={item.value}
              onChange={(value) => handleFormChange(index, 'value', value)}
              style={{ width: '100%' }}
            >
              <Option value="TRUE">TRUE</Option>
              <Option value="FALSE">FALSE</Option>
            </Select>
          )
        case 'Date':
        case 'Datetime':
          return (
            <DatePicker
              placeholder="Select Date"
              value={item.value ? dayjs(item.value) : null}
              onChange={(date) =>
                handleFormChange(index, 'value', date ? date.toISOString() : '')
              }
              style={{ width: '100%' }}
              showTime={fieldType === 'Datetime'}
            />
          )
        case 'Number':
          return (
            <Input
              type="number"
              placeholder="Value"
              value={item.value}
              onChange={(e) =>
                handleFormChange(index, 'value', Number(e.target.value))
              }
              style={{ width: '100%' }}
            />
          )
        default:
          return (
            <Input
              placeholder="Value"
              value={item.value}
              onChange={(e) => handleFormChange(index, 'value', e.target.value)}
              style={{ width: '100%' }}
            />
          )
      }
    } else if (item.type === 'reference') {
      return (
        <TreeSelect
          value={item.reference || ''}
          treeDefaultExpandedKeys={
            expandSelected ? [item.reference || ''] : undefined
          }
          onChange={(value) => handleFormChange(index, 'reference', value)}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, minWidth: 300, overflow: 'auto' }}
          popupMatchSelectWidth={false}
          className="nodrag nopan"
        >
          {renderTreeNodes(parentNodes)}
        </TreeSelect>
      )
    }
    return null
  }

  const filteredDataTypes = DataTypes.filter((type) => {
    if (!supportArray && type.startsWith('Array')) {
      return false
    }
    if (!supportObject && (type === 'Object' || type === 'Array<Object>')) {
      return false
    }
    return true
  })

  return (
    <Row>
      <Col span={24}>
        <Space.Compact style={{ width: '100%' }}>
          {displayFieldName ? (
            <Input
              placeholder="Field Name"
              value={item.name}
              onChange={(e) => handleFormChange(index, 'name', e.target.value)}
              disabled={!allowEditFieldName}
              style={{ minWidth: 100 }}
              {...labelInputProps}
            />
          ) : null}
          {displayDataType ? (
            <Select
              value={item.dataType}
              onChange={(value: InputFieldType['dataType']) => {
                handleFormChange(index, 'dataType', value)
                setFieldType(value)
              }}
              className="nodrag nopan"
              disabled={!allowEditDataType}
              style={{ width: 120 }}
              dropdownStyle={{ width: 150 }}
            >
              {filteredDataTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          ) : null}
          {displayInputType ? (
            <Select
              value={item.type}
              onChange={(value: InputFieldType['type']) =>
                handleFormChange(index, 'type', value)
              }
              className="nodrag nopan"
              disabled={!allowEditInputType}
              defaultValue="input"
              style={{ width: 120 }}
              dropdownStyle={{ width: 150 }}
            >
              <Option value="reference">Reference</Option>
              <Option value="input">Input</Option>
            </Select>
          ) : null}
          {renderComparisonInput()}
        </Space.Compact>
      </Col>
    </Row>
  )
}

export default InputField
