type HttpMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'delete'
  | 'patch'
  | 'options'
  | 'head'

export interface ApiEndpoint {
  url: string
  method: HttpMethod
}

const apiConfig = {
  userLogin: { url: '/api/v1/user/login', method: 'post' },
  userLogout: { url: '/api/v1/user/logout', method: 'get' },
  userCurrent: { url: '/api/v1/user/current', method: 'get' },
  userUpdateName: { url: '/api/v1/user/update-name', method: 'patch' },
  userUpdateStatus: { url: '/api/v1/user/update-status', method: 'patch' },
  userUpdateAvatar: { url: '/api/v1/user/update-avatar', method: 'patch' },
  userUpdatePassword: { url: '/api/v1/user/change-password', method: 'post' },
  userUpdatePasswordSession: { url: '/api/v1/user/change-password/session', method: 'post' },
  userResetPassword: { url: '/api/v1/user/reset-password', method: 'post' },
  userResendCreationEmail: { url: '/api/v1/user/resend-creation-email', method: 'post' },
  userList: { url: '/api/v1/user/list', method: 'get' },
  userCreate: { url: '/api/v1/user/create', method: 'post' },

  user2faQr: { url: '/api/v1/user/2fa/qr', method: 'get' },
  user2faQrSession: { url: '/api/v1/user/2fa/qr/session', method: 'get' },
  user2faSetup: { url: '/api/v1/user/2fa/setup', method: 'post' },
  user2faSetupSession: { url: '/api/v1/user/2fa/setup/session', method: 'post' },
  user2faValidate: { url: '/api/v1/user/2fa/validate', method: 'post' },
  user2faValidateSetupSession: {
    url: '/api/v1/user/2fa/validate-setup-session',
    method: 'post',
  },

  roleList: { url: '/api/v1/role/list', method: 'get' },
  roleCreate: { url: '/api/v1/role/create', method: 'post' },
  roleUpdate: { url: '/api/v1/role/update', method: 'patch' },
  roleDelete: { url: '/api/v1/role/delete', method: 'delete' },

  organizationList: { url: '/api/v1/organization/list', method: 'get' },
  organizationCreate: { url: '/api/v1/organization/create', method: 'post' },
  organizationUpdate: { url: '/api/v1/organization/update', method: 'put' },
  organizationDelete: { url: '/api/v1/organization/delete', method: 'delete' },
  organizationUpdateExpireData: { url: '/api/v1/organization/update-expire-date', method: 'patch' },
  organizationUpdateName: { url: '/api/v1/organization/update-name', method: 'patch' },
  organizationUpdateStatus: { url: '/api/v1/organization/update-status', method: 'patch' },
  organizationUpdateLanguageCode: { url: '/api/v1/organization/update-language-code', method: 'patch' },
  organizationUpdateRequired2FA: { url: '/api/v1/organization/update-required-2fa', method: 'patch' },

  permissionTree: {url: '/api/v1/permission/tree', method: 'get' },
  llmModels: { url: '/api/v1/llm/models', method: 'get' },
  rerankModels: { url: '/api/v1/llm/rerank-models', method: 'get' },
  agentList: { url: '/api/v1/agent/list', method: 'get' },
  agentGet: { url: '/api/v1/agent', method: 'get' },
  agentDelete: { url: '/api/v1/agent/delete', method: 'delete' },
  agentCreate: { url: '/api/v1/agent/create', method: 'post' },
  agentUpdate: { url: '/api/v1/agent/update', method: 'patch' },
  chatSessionsRecent: { url: '/api/v1/chat/sessions/recent', method: 'get' },
  chatConversations: { url: '/api/v1/chat/conversations', method: 'get' },
  chatSend: { url: '/api/v1/chat/conversations/send', method: 'post' },
  fileDelete: { url: '/api/v1/file/delete', method: 'delete' },
  filePaginate: { url: '/api/v1/file/paginate', method: 'get' },
  fileUpdate: { url: '/api/v1/file', method: 'patch' },
  file: { url: '/api/v1/file', method: 'get' },
  indexCreate: { url: '/api/v1/index/create', method: 'post' },
  indexUpdate: { url: '/api/v1/index/update', method: 'patch' },
  indexDelete: { url: '/api/v1/index/delete', method: 'delete' },
  indexList: { url: '/api/v1/index/list', method: 'get' },
  indexAllList: { url: '/api/v1/index/get-by-agent', method: 'get' },
  indexMappingList: { url: '/api/v1/index-mappings/list', method: 'get' },
  indexMappingBatchDelete: {
    url: '/api/v1/index-mappings/batch-delete',
    method: 'post',
  },
  indexMappingBatchAdd: { url: '/api/v1/index-mappings/batch-add', method: 'post' },

  intentCreate: { url: '/api/v1/intent/create', method: 'post' },
  intentUpdate: { url: '/api/v1/intent/update', method: 'patch' },
  intentDelete: { url: '/api/v1/intent/delete', method: 'delete' },
  intentList: { url: '/api/v1/intent/list', method: 'get' },
  intentAllList: { url: '/api/v1/intent/get-by-agent', method: 'get' },

  knowledgeCreate: { url: '/api/v1/knowledge/create', method: 'post' },
  knowledgeDelete: { url: '/api/v1/knowledge/delete', method: 'delete' },
  knowledgeList: { url: '/api/v1/knowledge/list', method: 'get' },
  knowledgeUpdate: { url: '/api/v1/knowledge/update', method: 'patch' },

  documentList: { url: '/api/v1/knowledge/document/list', method: 'get' },
  documentCreate: { url: '/api/v1/knowledge/document', method: 'post' },
  documentDelete: { url: '/api/v1/knowledge/document', method: 'delete' },
  documentUpdate: { url: '/api/v1/knowledge/document', method: 'patch' },

  chunkList: { url: '/api/v1/knowledge/document/chunks', method: 'get' },
  chunkCreate: { url: '/api/v1/knowledge/document/chunk', method: 'post' },
  chunkDelete: { url: '/api/v1/knowledge/document/chunk', method: 'delete' },
  chunkUpdate: { url: '/api/v1/knowledge/document/chunk', method: 'patch' },
  chunkStatusUpdate: {
    url: '/api/v1/knowledge/document/chunk/status',
    method: 'patch',
  },

  apiCreate: { url: '/api/v1/api/create', method: 'post' },
  apiUpdate: { url: '/api/v1/api/update', method: 'patch' },
  apiDelete: { url: '/api/v1/api/delete', method: 'delete' },
  apiList: { url: '/api/v1/api/list', method: 'get' },
  apiAllList: { url: '/api/v1/api/get-by-agent', method: 'get' },

  memoryCreate: { url: '/api/v1/memory/create', method: 'post' },
  memoryUpdate: { url: '/api/v1/memory/update', method: 'patch' },
  memoryDelete: { url: '/api/v1/memory/delete', method: 'delete' },
  memoryList: { url: '/api/v1/memory/list', method: 'get' },

  workflowGetLatest: { url: '/api/v1/workflow/get-latest-workflow-by-agent', method: 'get' },
  workflowGetPublished: { url: '/api/v1/workflow/get-published-workflow-by-agent', method: 'get' },
  workflowSave: { url: '/api/v1/workflow/save', method: 'post' },
  workflowPublish: { url: '/api/v1/workflow/publish', method: 'post' },

  channelCreate: { url: '/api/v1/publishchannel/create', method: 'post' },
  channelUpdate: { url: '/api/v1/publishchannel/update', method: 'patch' },
  channelDelete: { url: '/api/v1/publishchannel/delete', method: 'delete' },
  channelList: { url: '/api/v1/publishchannel/list', method: 'get' },
  channelGetByUUID: { url: '/public-api/v1/chat/publish-channel', method: 'get' },
  publicChatSend: { url: '/public-api/v1/chat/conversations/send', method: 'post' },
  publicChatLike: { url: '/public-api/v1/chat/conversations/like', method: 'post' },
  publicChatUnlike: { url: '/public-api/v1/chat/conversations/unlike', method: 'post' },
} satisfies Record<string, ApiEndpoint>

export default apiConfig
