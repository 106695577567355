import { App, Button, Form, Input, Modal } from 'antd'
import { ReactNode, useState } from 'react'
import { Knowledge } from 'stores/models/knowledge'
import knowledgeStore from 'stores/knowledge'
import { getMessageFromError } from 'utils/common'

interface KnowledgeModalProps {
  data: {
    id?: number
    name: string
    description: string
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (knowledgeId: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const KnowledgeModal: React.FC<KnowledgeModalProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    name: props.data.name,
    description: props.data.description,
  })

  const handleSave = async (values: KnowledgeModalProps['data']) => {
    setConfirmLoading(true)
    try {
      let knowledgeId = props.data.id
      if (knowledgeId === undefined) {
        const response = await knowledgeStore.create(
          values.name,
          values.description
        )
        const data = response.data as Knowledge
        knowledgeId = data.id
      } else {
        await knowledgeStore.update(
          knowledgeId,
          values.name,
          values.description
        )
      }
      return knowledgeId
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
    return -1
  }

  const handleFinish = async (values: KnowledgeModalProps['data']) => {
    const knowledgeId = await handleSave(values)
    if (knowledgeId < 0) {
      return
    }
    if (props.onFinish) {
      props.onFinish(knowledgeId)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  return (
    <Modal
      title={
        props.title ?? (props.data.id ? 'Edit Knowledge' : 'New Knowledge')
      }
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 600 }}
        initialValues={formValues}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false, message: '' }]}
        >
          <Input.TextArea showCount maxLength={150} rows={5} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            {props.okText || 'Ok'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default KnowledgeModal
