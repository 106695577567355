import React from 'react'
import { Button, Result } from 'antd'
import {useExNavigate} from 'hooks/use-ex-navigate'

const NotFound: React.FC = () => {
  const navigate = useExNavigate()

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={() => navigate(-1) }>Go Back</Button>}
    />
  )
}

export default NotFound
