import { Node, Edge, MarkerType } from '@xyflow/react'
import {
  OutputField,
  InputField,
  DataType,
  InputType,
  CustomNodeProps,
  NodeData,
} from 'views/portal/agent/studio/workflow/model'

const defaultLLMNodeInputs: InputField[] = [
  {
    name: 'model_id',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: 1,
    reference: '',
  },
  {
    name: 'stop_sequence',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
  {
    name: 'temperature',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 0.7,
    reference: '',
  },
  {
    name: 'top_p',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 0.7,
    reference: '',
  },
  {
    name: 'chat_context',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: true,
    reference: '',
  },
  {
    name: 'system_prompt',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
  {
    name: 'user_prompt',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '{{.start.query}}',
    reference: '',
  },
]

const defaultLLMNodeOutputs: OutputField[] = [
  {
    name: 'text',
    type: 'String' as DataType,
    description: 'Generated Text',
    required: true,
    children: [],
  },
]
const defaultNodeData: NodeData = {
  label: 'LLM 1',
  description: 'Language Model for text generation',
  input: defaultLLMNodeInputs,
  output: defaultLLMNodeOutputs,
  intentBranch: [],
  conditionBranch: [],
  branchOutput: {},
}

// Initial configuration for nodes
export const initialNodes: Node[] = []
//   [
//   {
//     id: 'start',
//     type: 'Start',
//     data: {},
//     position: { x: 250, y: 400 },
//   },
//   {
//     id: 'llm_1',
//     type: 'LLM',
//     data: { data: defaultNodeData },
//     position: { x: 600, y: 400 },
//   },
//   {
//     id: 'end',
//     type: 'End',
//     data: {},
//     position: { x: 950, y: 400 },
//   },
//   {
//     id: 'tools',
//     type: 'Tools',
//     data: {},
//     position: { x: 1200, y: 400 },
//     zIndex: 10000
//   },
// ];

// Initial configuration for edges
export const initialEdges: Edge[] = []
//   [
//   {
//     id: 'edge_1',
//     source: 'start',
//     target: 'llm_1',
//     sourceHandle: null,
//     targetHandle: null,
//     type: 'custom',
//     animated: true,
//     style: { strokeWidth: 3 },
//     markerEnd: { type: MarkerType.ArrowClosed },
//   },
//   {
//     id: 'edge_2',
//     source: 'llm_1',
//     target: 'end',
//     sourceHandle: null,
//     targetHandle: null,
//     type: 'custom',
//     animated: true,
//     style: { strokeWidth: 3 },
//     markerEnd: { type: MarkerType.ArrowClosed },
//   }
// ];
// Default options for edges
export const defaultEdgeOptions = {
  type: 'custom',
  animated: true,
  style: { strokeWidth: 3 },
  markerEnd: { type: MarkerType.ArrowClosed },
}
