import React, { useRef, useCallback } from 'react';
import Editor, { Monaco, OnChange, loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { Button, Space, Tooltip, Divider } from 'antd';
import { PlusOutlined, FullscreenOutlined } from '@ant-design/icons';
import { registerGolangTemplateLanguage, registerGolangTemplateCompletionItemProvider } from './golang-template-language';

loader.config({ monaco });

interface EditorComponentProps {
  value: string;
  onChange: OnChange;
  variables: any[];
  codeSnippets: any[];
  isFullscreen: boolean;
  onToggleFullscreen: () => void;
}
const EditorComponent: React.FC<EditorComponentProps> = ({
  value,
  onChange,
  variables,
  codeSnippets,
  isFullscreen,
  onToggleFullscreen,
}) => {
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const handleEditorDidMount = useCallback((editor: monaco.editor.IStandaloneCodeEditor, monaco: Monaco) => {
    editorRef.current = editor;

    registerGolangTemplateLanguage(monaco);
    registerGolangTemplateCompletionItemProvider(monaco, variables);

    editor.updateOptions({
      minimap: { enabled: isFullscreen },
      scrollbar: { vertical: 'auto', horizontal: 'auto' },
      lineNumbers: isFullscreen ? 'on' : 'off',
      glyphMargin: isFullscreen,
      folding: true,
      lineDecorationsWidth: isFullscreen ? 10 : 0,
      lineNumbersMinChars: isFullscreen ? 5 : 0,
      renderLineHighlight: isFullscreen ? 'all' : 'none',
      overviewRulerLanes: isFullscreen ? 2 : 0,
      hideCursorInOverviewRuler: !isFullscreen,
      overviewRulerBorder: isFullscreen,
    });
  }, [variables, isFullscreen]);

  const triggerSuggestions = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.focus();
      editorRef.current.trigger('button', 'editor.action.triggerSuggest', {});
    }
  }, []);

  const insertCodeSnippet = useCallback((snippet: string) => {
    if (editorRef.current) {
      const position = editorRef.current.getPosition();
      editorRef.current.executeEdits('', [
        {
          range: new monaco.Range(
            position!.lineNumber,
            position!.column,
            position!.lineNumber,
            position!.column
          ),
          text: snippet,
        },
      ]);
      editorRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className='toolbar'>
        <Space>
          <Space.Compact>
            {codeSnippets.map((snippet, index) => (
              <Tooltip key={index} title={`${snippet.name}`}>
                <Button 
                  icon={snippet.icon}
                  onClick={() => insertCodeSnippet(snippet.code)}
                />
              </Tooltip>
            ))}
          </Space.Compact>
          <Divider type="vertical" />
          <Tooltip title="Insert Variable">
            <Button icon={<PlusOutlined />} onClick={triggerSuggestions} />
          </Tooltip>
          <Tooltip title="Toggle Fullscreen">
            <Button icon={<FullscreenOutlined />} onClick={onToggleFullscreen} />
          </Tooltip>
        </Space>
      </div>

      <Editor
        height={isFullscreen ? "calc(90vh - 170px)" : "300px"}
        language="golangTemplate"
        value={value}
        onChange={onChange}
        onMount={handleEditorDidMount}
        options={{
          fontSize: isFullscreen ? 14 : 12,
          lineHeight: isFullscreen ? 24 : 30,
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
          wordWrap: 'on',
          scrollBeyondLastLine: false,
          renderWhitespace: isFullscreen ? 'all' : 'none',
          contextmenu: true,
          quickSuggestions: {
            other: true,
            comments: true,
            strings: true
          },
          parameterHints: { enabled: true },
        }}
      />
    </>
  );
};

export default EditorComponent;