import React, { useState, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import IntentTable, { ActionType } from './intent-table'
import { Button } from 'antd'
import IntentEditor from './intent-editor'
import './index.css'
import { Intent } from 'stores/models/intent'

const AgentEditorIntent: React.FC = () => {
  const tableRef = useRef<{ refresh: () => void }>(null)
  const [intentEditorData, setIntentEditorData] = useState<Intent>({
    id: 0,
    intent: '',
    description: '',
    examples: [],
    parameters: [],
    organizationID: 0,
  })
  const [isIntentEditorOpen, setIsIntentEditorOpen] = useState(false)

  return (
    <div>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>Intent</h1>
            <div className="operation"></div>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setIntentEditorData({
                  id: 0,
                  intent: '',
                  description: '',
                  examples: [],
                  parameters: [],
                  organizationID: 0,
                })
                setIsIntentEditorOpen(true)
              }}
            >
              Create Intent
            </Button>
          </div>
        </div>
        <div className="intent-list" style={{ gap: '20px' }}>
          <IntentTable
            ref={tableRef}
            onAction={(type, data) => {
              if (type === ActionType.Edit) {
                setIntentEditorData(data)
                setIsIntentEditorOpen(true)
              }
            }}
          />
        </div>
        <IntentEditor
          open={isIntentEditorOpen}
          data={intentEditorData}
          onFinish={() => {
            tableRef.current?.refresh()
            setIsIntentEditorOpen(false)
          }}
          onCancel={() => setIsIntentEditorOpen(false)}
        />
      </div>
    </div>
  )
}

export default observer(AgentEditorIntent)
