import { Form, FormInstance, Input } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { escapeRegExp, isEmpty } from 'utils/common'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

export interface PasswordFormItemRefProps {
  reset: () => void
  isPasswordChanged: () => boolean
}

export interface PasswordFormItemProps {
  form: FormInstance
  name?: string
  password?: string | null
  needOldPassword?: boolean
  passwordVisible?: boolean
  validateFirst?: boolean
}

const PASSWORD_MASK = '@_PassMask4202@!'

const PasswordFormItem: ForwardRefRenderFunction<
  PasswordFormItemRefProps,
  PasswordFormItemProps
> = (
  {
    form,
    name = 'password',
    password = null,
    needOldPassword = false,
    passwordVisible = false,
    validateFirst = false,
  },
  ref
) => {
  const inputPassword = Form.useWatch<string>(name, form)
  const [isPasswordVisible, setIsPasswordVisible] = useState(passwordVisible)
  const isPasswordChanged = useMemo(
    () => ![PASSWORD_MASK, password].includes(inputPassword),
    [inputPassword, password]
  )

  const reset = useCallback(() => {
    setIsPasswordVisible(false)
    if (passwordVisible) {
      if (null === password) {
        form.setFieldValue(name, '')
      }
    } else {
      if (null === password) {
        form.setFieldValue(name, '')
      } else {
        form.setFieldValue(name, PASSWORD_MASK)
      }
    }
    form.setFieldValue('re-password', '')
    form.setFieldValue('old-password', '')
  }, [form, name, password, passwordVisible])

  useImperativeHandle(
    ref,
    () => ({
      reset,
      isPasswordChanged: () => isPasswordChanged,
    }),
    [reset, isPasswordChanged]
  )

  useEffect(() => {
    setIsPasswordVisible(passwordVisible)
  }, [passwordVisible])

  useEffect(() => {
    reset()
  }, [password])

  return (
    <>
      <Form.Item
        name={name}
        label="Password"
        rules={[
          { required: true, message: 'Please input your password!' },
          {
            pattern: /^.{8,}$/,
            message: 'Password must have minimum 8 characters',
          },
          {
            pattern: /[a-z]/,
            message: 'Password must have at least one lowercase letter',
          },
          {
            pattern: /[A-Z]/,
            message: 'Password must have at least one uppercase letter',
          },
          {
            pattern: /\d/,
            message: 'Password must have at least one number',
          },
          {
            pattern: /[@$!%*?&#]/,
            message:
              'Password must have at least one special character (@$!%*?&#)',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!needOldPassword) {
                return Promise.resolve()
              }
              if (!value || getFieldValue('old-password') !== value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  'The password must not be the same as your old password!'
                )
              )
            },
          }),
        ]}
        validateFirst={validateFirst}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Your new password"
          visibilityToggle={{
            visible: isPasswordVisible,
            onVisibleChange: (visible: boolean) => {
              setIsPasswordVisible(visible)
            },
          }}
          onBlur={() => {
            if (isPasswordVisible) {
              if (inputPassword === PASSWORD_MASK) {
                form.setFieldValue(name, '')
              }
            } else {
              if (!isPasswordChanged) {
                form.setFieldValue(name, PASSWORD_MASK)
              }
            }
          }}
          onFocus={() => {
            if (!isPasswordChanged) {
              form.setFieldValue(name, '')
            }
          }}
        />
      </Form.Item>
      {isPasswordChanged && (
        <Form.Item
          name="re-password"
          label="Re-password"
          rules={[
            {
              required: true,
              message: 'Please input your new password again!',
            },
            {
              pattern: new RegExp(escapeRegExp(inputPassword)),
              message: 'Password must be the same as above!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Your new password again"
            visibilityToggle={{ visible: isPasswordVisible }}
          />
        </Form.Item>
      )}
      {needOldPassword ? (
        isPasswordChanged && (
          <Form.Item
            name="old-password"
            label="Old Password"
            rules={[
              { required: true, message: 'Please input your old password!' },
            ]}
            validateFirst={validateFirst}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Your old Password"
              visibilityToggle={{ visible: isPasswordVisible }}
              onChange={() => form.validateFields([name])}
            />
          </Form.Item>
        )
      ) : (
        <></>
      )}
    </>
  )
}

export default observer(forwardRef(PasswordFormItem))
