import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react'
import { observer } from 'mobx-react'
import { Table, Button, Popconfirm, App, Flex } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import organizationStore from 'stores/organization'
import userStore from 'stores/user'
import { Organization } from 'stores/models/organization'
import { formatDate } from 'utils/filter'
import { getMessageFromError } from 'utils/common'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface OrganizationTableProps {
  onAction?: (type: ActionType, organization: Organization) => void
}

export interface OrganizationTableRef {
  refresh: () => void
}

const OrganizationTable: ForwardRefRenderFunction<
  OrganizationTableRef,
  OrganizationTableProps
> = (props, ref) => {
  const { message } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deletingId, setDeletingId] = useState(0)

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => load(page, size),
    }),
    [page, size]
  )

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      await organizationStore.getList(page, size)
      setTotal(organizationStore.organizationList.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  const deleteOrganization = async (key: number) => {
    setDeletingId(key)
    try {
      await organizationStore.delete(key)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingId(0)
    load(page, size)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: false,
      render: (text: string, record: Organization) => {
        return <div key={'name_' + record.id}>{text}</div>
      },
    },
    {
      title: 'Type',
      dataIndex: 'organizationType',
      render: (organizationType: string, record: Organization) => {
        return (
          <div
            key={'type_' + record.id}
            style={{ textTransform: 'capitalize' }}
          >
            {organizationType}
          </div>
        )
      },
    },
    {
      title: 'Language Code',
      dataIndex: 'languageCode',
      render: (text: string, record: Organization) => {
        return (
          <div
            key={'language_code_' + record.id}
            style={{ textTransform: 'uppercase' }}
          >
            {text}
          </div>
        )
      },
    },
    {
      title: 'Required 2FA',
      dataIndex: 'required2FA',
      render: (value: boolean, record: Organization) => {
        return (
          <div
            key={'required_2fa_' + record.id}
            style={{ textTransform: 'capitalize' }}
          >
            {value ? 'Yes' : 'No'}
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string, record: Organization) => {
        return (
          <div
            key={'status_' + record.id}
            style={{ textTransform: 'capitalize' }}
          >
            {text}
          </div>
        )
      },
    },
    {
      title: 'Expire At',
      dataIndex: 'expireDate',
      render: (text: string, record: Organization) => (
        <div key={'created_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (text: string, record: Organization) => (
        <div key={'created_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (text: string, record: Organization) => (
        <div key={'updated_at_' + record.id}>{formatDate(text)}</div>
      ),
    },
    {
      title: 'Actions',
      render: (_: any, record: Organization) => {
        return (
          <Flex key={'actions_' + record.id} gap="6px">
            <Button
              key={'edit_' + record.id}
              icon={<EditOutlined />}
              size="small"
              disabled={false}
              onClick={() => {
                props.onAction && props.onAction(ActionType.Edit, record)
              }}
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the document?"
              onConfirm={() => deleteOrganization(record.id)}
            >
              <Button
                key={'delete_' + record.id}
                icon={<DeleteOutlined />}
                size="small"
                disabled={
                  userStore.loginUser?.roleName.toLowerCase() !== 'admin'
                }
                loading={deletingId === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  return (
    <div className="file-table">
      <Table
        dataSource={organizationStore.organizationList.organizations}
        columns={columns}
        loading={loading}
        pagination={{
          current: page,
          pageSize: size,
          total: total,
          position: ['bottomLeft'],
          onChange: (page, size) => {
            setPage(page)
            setSize(size)
          },
        }}
      />
    </div>
  )
}

export default observer(forwardRef(OrganizationTable))
