import React, { useState, useEffect, useContext, useMemo } from 'react'
import { observer } from 'mobx-react'
import {
  Space,
  Input,
  Switch,
  List,
  Typography,
  Select,
  Slider,
  InputNumber,
} from 'antd'
import { FunctionOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import {
  InputField,
  DataType,
  OutputField,
  CustomNodeProps,
  NodeData,
} from '../../model'
import TemplateEditor from '../../components/template-editor'
import { FlowContext } from '../../context'
import NodeSidebar from '../../components/node-sidebar'
import { v1LlmModelsList } from 'api/Api'
import { ResponsesLLMResponse } from 'api/data-contracts'

const { Text } = Typography
const { Option, OptGroup } = Select
const TextArea = Input.TextArea

const LLMNodeSidebar: React.FC = observer(() => {
  const context = useContext(FlowContext)
  const [loading, setLoading] = useState(false)
  const [modelList, setModelList] = useState<ResponsesLLMResponse[]>([])

  const groupedModels = useMemo(
    () =>
      modelList.reduce<Record<string, ResponsesLLMResponse[]>>((pre, cur) => {
        const { developer } = cur
        const curGroup = Reflect.get(pre, developer)
        if (!curGroup) {
          Reflect.set(pre, developer, [cur])
        } else {
          curGroup.push(cur)
        }
        return pre
      }, {}),
    [modelList]
  )

  useEffect(() => {
    fetchModels()
  }, [])

  const fetchModels = async () => {
    setLoading(true)
    const getModelsResp = await v1LlmModelsList()
    setLoading(false)

    setModelList(getModelsResp?.data ?? [])
    WorkflowStore.setModels(getModelsResp?.data ?? [])
  }

  // const formattedModels = data.map((item) => ({
  //   id: item.id,
  //   name: `${item.name} - Context: ${item.contextWindow} tokens`,
  //   developer: item.developer,
  // }))

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      let updatedInput = [...(currentNodeData.input || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else {
        const inputIndex = updatedInput.findIndex(
          (input) => input.name === field
        )
        if (inputIndex !== -1) {
          updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
        } else {
          const newInputField: InputField = {
            name: field,
            type: 'input',
            dataType: dataType,
            value: value,
            reference: '',
          }
          updatedInput.push(newInputField)
        }
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
        },
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  // const groupedModels = WorkflowStore.models.reduce(
  //   (acc, model) => {
  //     if (!acc[model.developer]) {
  //       acc[model.developer] = []
  //     }
  //     acc[model.developer].push(model)
  //     return acc
  //   },
  //   {} as Record<string, typeof WorkflowStore.models>
  // )

  const getInputValue = (name: string) => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputField) => i.name === name
    )
    return input ? input.value : undefined
  }

  if (WorkflowStore.selectedNode?.type !== 'LLM') {
    return null
  }

  return (
    <NodeSidebar
      nodeType={'llm'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={WorkflowStore.selectedNode?.data?.data?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <Section title="Settings">
        <div className="setting-item">
          <label>Model</label>
          <div className="element">
            <Select
              value={getInputValue('model_id')}
              onChange={(value) =>
                handleNodeDataChange('model_id', value, 'Integer')
              }
              style={{ width: '205px' }}
              dropdownStyle={{ width: '300px' }}
              loading={loading}
            >
              {Object.entries(groupedModels).map(([developer, models]) => (
                <OptGroup key={developer} label={developer}>
                  {models.map((model) => (
                    <Option key={model.id} value={model.id}>
                      {`${model.name} - Context: ${model.contextWindow} tokens`}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </div>
        </div>
        {getInputValue('model_id') && (
          <div className="setting-item">
            <label>Max Tokens</label>
            <InputNumber
              min={0}
              max={
                modelList.find((m) => m.id === getInputValue('model_id'))
                  ?.contextWindow
              }
              value={getInputValue('max_tokens')}
              onChange={(value) =>
                handleNodeDataChange('max_tokens', value, 'Number')
              }
            />
          </div>
        )}

        <div className="setting-item">
          <label>Stop Sequence</label>
          <div className="element">
            <Input
              value={getInputValue('stop_sequence')}
              onChange={(e) =>
                handleNodeDataChange('stop_sequence', e.target.value, 'String')
              }
              placeholder="Enter stop sequence"
              style={{ width: '205px' }}
            />
          </div>
        </div>
        <div className="setting-item">
          <label>Temperature</label>
          <div className="element">
            <Slider
              min={0.01}
              max={2}
              step={0.01}
              value={getInputValue('temperature')}
              onChange={(value) =>
                handleNodeDataChange('temperature', value, 'Number')
              }
            />
          </div>
        </div>
        <div className="setting-item">
          <label>Top P</label>
          <div className="element">
            <Slider
              min={0.01}
              max={1}
              step={0.01}
              value={getInputValue('top_p')}
              onChange={(value) =>
                handleNodeDataChange('top_p', value, 'Number')
              }
            />
          </div>
        </div>
        <div className="setting-item">
          <label>Chat Context</label>
          <Switch
            checked={getInputValue('chat_context')}
            onChange={(checked) =>
              handleNodeDataChange('chat_context', checked, 'Boolean')
            }
          />
        </div>

        {getInputValue('chat_context') && (
          <div className="setting-item">
            <label>Number of Conversation Record</label>
            <InputNumber
              min={0}
              max={100}
              value={getInputValue('conversation_context_number')}
              onChange={(value) =>
                handleNodeDataChange(
                  'conversation_context_number',
                  value,
                  'Number'
                )
              }
            />
          </div>
        )}
      </Section>
      <Section title="Prompts">
        <div className="template-editor-item">
          <label>System Prompt</label>
          <div className="element">
            <TemplateEditor
              value={getInputValue('system_prompt') || ''}
              onChange={(value) =>
                handleNodeDataChange('system_prompt', value, 'String')
              }
              currentNodeID={WorkflowStore.selectedNode?.id || ''}
              nodes={context?.nodes ?? []}
              edges={context?.edges ?? []}
            />
          </div>
        </div>
        <div className="template-editor-item">
          <label>User Prompt</label>
          <div className="element">
            <TemplateEditor
              value={getInputValue('user_prompt') || ''}
              onChange={(value) =>
                handleNodeDataChange('user_prompt', value, 'String')
              }
              currentNodeID={WorkflowStore.selectedNode?.id || ''}
              nodes={context?.nodes ?? []}
              edges={context?.edges ?? []}
            />
          </div>
        </div>
      </Section>
      <Section title="Output" defaultCollapsed={true}>
        <List
          dataSource={WorkflowStore.selectedNode?.data?.data?.output || []}
          renderItem={(item: OutputField) => (
            <List.Item>
              <Space>
                <FunctionOutlined />
                <Text>{item.name}</Text>
              </Space>
              <Text type="secondary">{item.type}</Text>
            </List.Item>
          )}
        />
      </Section>
    </NodeSidebar>
  )
})

export default LLMNodeSidebar
