import React, { useState, useEffect, MouseEventHandler } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Pagination,
  Skeleton,
  PaginationProps,
  App,
  Space,
  Flex,
  Empty,
  Breadcrumb,
} from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Document as DocumentType } from 'stores/document'
import chunkStore from 'stores/chunk'
import { Chunk } from 'stores/models/chunk'
import ChunkCard, { ActionType } from './chunk-card'
import { observer } from 'mobx-react'
import { getMessageFromError } from 'utils/common'
import ChunkEditor, { ChunkEditorProps } from './chunk-editor'

const DEFAULT_CHUNK_DATA: ChunkEditorProps['data'] = {
  _id: '',
  knowledgeID: 0,
  fileID: 0,
  documentID: 0,
  enabled: true,
  content: '',
  metadata: {},
}

const ChunkPage: React.FC = () => {
  const { message, modal } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [chunk, setChunk] =
    useState<ChunkEditorProps['data']>(DEFAULT_CHUNK_DATA)
  const location = useLocation()
  const document = location.state.document as DocumentType

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      await chunkStore.getList(document.knowledgeID, document.id, page, size)
      setTotal(chunkStore.chunkList.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  const onChange: PaginationProps['onChange'] = (
    page: number,
    size: number
  ) => {
    setPage(page)
    setSize(size)
  }

  const handleAddButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    setChunk({
      ...DEFAULT_CHUNK_DATA,
      knowledgeID: document.knowledgeID,
      fileID: document.fileID,
      documentID: document.id,
    })
    setIsEditorOpen(true)
  }

  const onChunkCardAction = (type: ActionType, chunk: Chunk) => {
    if (type === ActionType.Edit) {
      setChunk(chunk)
      setIsEditorOpen(true)
    }
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <Breadcrumb
              items={[
                {
                  title: <Link to="/portal/knowledge">Knowledge</Link>,
                },
                {
                  title: (
                    <Link
                      to="/portal/knowledge/document"
                      state={{ knowledge: { id: document.knowledgeID } }}
                    >
                      Document
                    </Link>
                  ),
                },
                {
                  title: 'Chunk',
                },
              ]}
            />
            <h1>Chunks</h1>
            <p>
              Import your own text data or write data in real-time via Webhook
              for LLM context enhancement.
            </p>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddButtonClick}
            >
              Add Chunk
            </Button>
          </div>
        </div>
        <div className="chunk-list">
          <Skeleton loading={loading} active>
            {chunkStore.chunkList.chunks.length > 0 ? (
              chunkStore.chunkList.chunks.map((item, i) => (
                <ChunkCard key={i} data={item} onAction={onChunkCardAction} />
              ))
            ) : (
              <Flex justify="start" style={{ width: '100%' }}>
                <Empty />
              </Flex>
            )}
          </Skeleton>
        </div>
        <div className="pagination">
          {!loading ? (
            <Pagination
              defaultCurrent={page}
              defaultPageSize={size}
              total={total}
              onChange={onChange}
            />
          ) : null}
        </div>
        <ChunkEditor
          title={
            <Space>
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                Chunk Editor
              </span>
            </Space>
          }
          data={chunk}
          open={isEditorOpen}
          onOk={() => {
            setIsEditorOpen(false)
            load(page, size)
          }}
          onCancel={() => setIsEditorOpen(false)}
        />
      </div>
    </>
  )
}

export default observer(ChunkPage)
