import { App, Button, Flex, Input, Modal, Popconfirm } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { observer } from 'mobx-react'
import { DeleteOutlined } from '@ant-design/icons'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import chunkStore from 'stores/chunk'
import { Chunk, MetadataType } from 'stores/models/chunk'
import { getMessageFromError, isEmpty } from 'utils/common'

export interface ChunkEditorProps {
  data: Chunk
  title?: ReactNode
  open?: boolean
  onOk?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type MetadataTuple = [keyof MetadataType, MetadataType[keyof MetadataType]]

const ChunkEditor: React.FC<ChunkEditorProps> = (props) => {
  const { message } = App.useApp()
  const [content, setContent] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [metadata, setMetadata] = useState<MetadataTuple[]>([])

  useEffect(() => {
    setContent(props.data.content)
  }, [props.data.content])

  useEffect(() => {
    const entries = Object.entries(props.data.metadata ?? {})
    if (entries.length === 0) {
      setMetadata([['', '']])
    } else {
      setMetadata(entries)
    }
  }, [props.data.metadata])

  const handleContentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setContent(e.target.value)
    },
    []
  )

  const handleSave = async () => {
    setConfirmLoading(true)
    try {
      const _metadata = metadata
        .filter((data) => data[0].length > 0)
        .reduce((accumulator, data) => {
          accumulator[data[0]] = data[1]
          return accumulator
        }, {} as MetadataType)
      if (isEmpty(props.data._id)) {
        await chunkStore.create(
          props.data.knowledgeID,
          props.data.documentID,
          props.data.fileID,
          content,
          _metadata
        )
      } else {
        await chunkStore.update(
          props.data._id,
          props.data.knowledgeID,
          content,
          _metadata
        )
      }
      return true
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
    return false
  }

  const handleOk = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(await handleSave())) {
      return
    }
    if (props.onOk) {
      props.onOk(e)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setContent(props.data.content)
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const metadataToInput = () => {
    return metadata.map((data, index) => {
      return (
        <Flex gap="small">
          <Input
            addonBefore="Key"
            placeholder="Key"
            value={data[0]}
            onChange={(e) => {
              metadata[index][0] = e.target.value
              setMetadata(JSON.parse(JSON.stringify(metadata)))
            }}
          />
          <Input
            addonBefore="Value"
            placeholder="Value"
            value={data[1]}
            onChange={(e) => {
              metadata[index][1] = e.target.value
              setMetadata(JSON.parse(JSON.stringify(metadata)))
            }}
          />
          <Popconfirm
            key={'popconfirm_' + data[0]}
            title="Are you sure to delete the metadata?"
            onConfirm={() => {
              metadata.splice(index, 1)
              setMetadata(JSON.parse(JSON.stringify(metadata)))
            }}
          >
            <Button
              key={'delete_' + data[0]}
              icon={<DeleteOutlined />}
              style={{ width: '72px' }}
            />
          </Popconfirm>
        </Flex>
      )
    })
  }

  return (
    <Modal
      title={props.title}
      open={props.open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      confirmLoading={confirmLoading}
      width={800}
    >
      <TextArea
        rows={12}
        value={content}
        onChange={handleContentChange}
        showCount
        style={{ marginBottom: '16px', height: '500px' }}
      />
      <Flex vertical={true} gap="small">
        <h3>Metadata</h3>
        {metadataToInput()}
        <Flex gap="small" style={{ margin: '12px 0' }}>
          <Button
            type="primary"
            onClick={() =>
              setMetadata(
                JSON.parse(JSON.stringify(metadata.concat([['', '']])))
              )
            }
          >
            Add Field
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default observer(ChunkEditor)
