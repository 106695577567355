import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { RequestData } from 'services/request'
import { createApiRequest } from 'services/request'
import { Agent, AgentList, AgentType } from 'stores/models/agent'

class AgentStore {
  agentList: AgentList = {
    totalCount: 0,
    agents: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async create(
    name: string,
    description: string,
    agentType: AgentType,
    iconId: number
  ) {
    const params: RequestData = {
      body: {
        name,
        description,
        agentIconID: iconId,
        agentType,
      },
    }

    const response = await createApiRequest<Agent>(
      apiConfig.agentCreate,
      params
    )

    return response
  }

  async update(
    id: number,
    name: string,
    description: string,
    agentType: string,
    iconId: number
  ) {
    const params: RequestData = {
      body: {
        id,
        name,
        description,
        agentType,
        iconID: iconId,
      },
    }

    const response = await createApiRequest(apiConfig.agentUpdate, params)
    const index = this.agentList.agents.findIndex((agent) => agent.id === id)
    if (index !== -1) {
      runInAction(() => {
        this.agentList.agents[index] = {
          ...this.agentList.agents[index],
          ...params.body,
        }
      })
    }

    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        size,
      },
    }

    const response = await createApiRequest<AgentList>(
      apiConfig.agentList,
      params
    )
    runInAction(() => {
      this.agentList = {
        ...response.data,
        agents: response.data.agents,
      }
    })

    return response
  }

  async delete(id: number) {
    await createApiRequest(apiConfig.agentDelete, {
      queryParams: { id },
    })

    this.agentList = {
      totalCount: this.agentList.totalCount - 1,
      agents: this.agentList.agents.filter((agent) => agent.id !== id),
    }
  }
}

export default new AgentStore()
