import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Space,
  Input,
  InputNumber,
  List,
  Typography,
  message,
} from 'antd'
import {
  FunctionOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import {
  InputField as InputFieldType,
  DataType,
  OutputField,
  CustomNodeProps,
  NodeData,
} from '../../model'
import InputField from '../../components/input-field'
import { FlowContext } from '../../context'
import NodeSidebar from '../../components/node-sidebar'

const { TextArea } = Input
const { Text } = Typography
const MAX_LABELS = 10

const PIIFilterNodeSidebar: React.FC = observer(() => {
  const context = useContext(FlowContext)
  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      let updatedInput = [...(currentNodeData.input || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else {
        const inputIndex = updatedInput.findIndex(
          (input) => input.name === field
        )
        if (inputIndex !== -1) {
          updatedInput[inputIndex] = { ...updatedInput[inputIndex], value }
        } else {
          const newInputField: InputFieldType = {
            name: field,
            type: 'input',
            dataType: dataType,
            value: value,
            reference: '',
          }
          updatedInput.push(newInputField)
        }
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
        },
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  const getInputValue = (name: string) => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    return input ? input.value : undefined
  }
  const getInput = (name: string): InputFieldType => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    return (
      input || {
        name,
        type: 'input',
        dataType: 'String',
        value: '',
        reference: '',
      }
    )
  }

  const handleAddLabel = () => {
    const currentLabels = getInputValue('labels') || []
    if (currentLabels.length < MAX_LABELS) {
      handleNodeDataChange('labels', [...currentLabels, ''], 'Array<String>')
    } else {
      message.warning(`You can add a maximum of ${MAX_LABELS} labels.`)
    }
  }
  const handleFormChange = (
    index: number,
    key: keyof InputFieldType,
    value: any
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      const updatedInput = [...(currentNodeData.input || [])]

      if (index < updatedInput.length) {
        updatedInput[index] = { ...updatedInput[index], [key]: value }
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
        },
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }

      WorkflowStore.selectNode(updatedNode as CustomNodeProps)

      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  const handleRemoveLabel = (index: number) => {
    const currentLabels = getInputValue('labels') || []
    handleNodeDataChange(
      'labels',
      currentLabels.filter((_: string, i: number) => i !== index),
      'Array<String>'
    )
  }

  const handleLabelChange = (index: number, value: string) => {
    const currentLabels = getInputValue('labels') || []
    const newLabels = [...currentLabels]
    newLabels[index] = value
    handleNodeDataChange('labels', newLabels, 'Array<String>')
  }

  if (WorkflowStore.selectedNode?.type !== 'PII') {
    return null
  }
  return (
    <NodeSidebar
      nodeType={'pii'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={WorkflowStore.selectedNode?.data?.data?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <Section title="Settings">
        <div className="template-editor-item">
          <label>Content</label>
          <div className="element">
            {getInput('content') && (
              <InputField
                index={0}
                item={getInput('content')}
                currentNodeId={WorkflowStore.selectedNode.id}
                nodes={context?.nodes || []}
                edges={context?.edges || []}
                allowEditFieldName={false}
                supportArray={false}
                supportObject={false}
                displayDataType={false}
                displayFieldName={false}
                handleFormChange={handleFormChange}
              />
            )}
          </div>
        </div>
        <div className="setting-item">
          <label>Placeholder</label>
          <div className="element">
            <Input
              value={getInputValue('placeholder')}
              onChange={(e) =>
                handleNodeDataChange('placeholder', e.target.value, 'String')
              }
              placeholder="Enter placeholder (default: ***)"
              style={{ width: '205px' }}
            />
          </div>
        </div>
        <div className="setting-item">
          <label>Threshold</label>
          <div className="element">
            <InputNumber
              value={getInputValue('threshold')}
              onChange={(value) =>
                handleNodeDataChange('threshold', value, 'Number')
              }
              min={0}
              max={1}
              step={0.1}
              style={{ width: '205px' }}
            />
          </div>
        </div>
        <div className="template-editor-item">
          <label>
            Labels ({(getInputValue('labels') || []).length}/{MAX_LABELS})
          </label>
          <div className="element" style={{ maxWidth: '400px' }}>
            {(getInputValue('labels') || []).map(
              (label: string, index: number) => (
                <div
                  key={index}
                  style={{
                    marginBottom: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    value={label}
                    onChange={(e) => handleLabelChange(index, e.target.value)}
                    style={{ flexGrow: 1, marginRight: '8px' }}
                  />
                  <Button
                    icon={<MinusCircleOutlined />}
                    onClick={() => handleRemoveLabel(index)}
                  />
                </div>
              )
            )}
            <div style={{ marginTop: '8px' }}>
              {(getInputValue('labels') || []).length >= MAX_LABELS ? null : (
                <Button
                  icon={<PlusOutlined />}
                  onClick={handleAddLabel}
                  type="dashed"
                  style={{ width: '100%' }}
                >
                  Add Label
                </Button>
              )}
            </div>
          </div>
        </div>
      </Section>
      <Section title="Output" defaultCollapsed={true}>
        <List
          dataSource={WorkflowStore.selectedNode?.data?.data?.output || []}
          renderItem={(item: OutputField) => (
            <List.Item>
              <Space>
                <FunctionOutlined />
                <Text>{item.name}</Text>
              </Space>
              <Text type="secondary">{item.type}</Text>
            </List.Item>
          )}
        />
      </Section>
    </NodeSidebar>
  )
})

export default PIIFilterNodeSidebar
