import React, { createContext, useContext, useState, ReactNode } from 'react'
import { Node, Edge } from '@xyflow/react'
import { Agent } from 'stores/models/agent'

interface FlowContextType {
  nodes: Node[]
  setNodes: (nodes: Node[]) => void
  edges: Edge[]
  setEdges: (edges: Edge[]) => void
}

export const FlowContext = createContext<FlowContextType | undefined>(undefined)
