import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
} from 'react'
import './index.css'
import { Avatar, Button, CheckboxRef, Radio, RadioChangeEvent } from 'antd'
import { observer } from 'mobx-react'

interface RadioCardProps {
  icon: ReactElement
  group?: string
  title: string
  value?: string
  checked?: boolean
  description: string
  className?: string[]
  avatarSize?: number
  avatarStyle?: CSSProperties
  children?: ReactNode
  onChange?: (e: RadioChangeEvent) => void
}

const RadioCard: React.FC<RadioCardProps> = (props) => {
  const radioRef = useRef<CheckboxRef>(null)
  const handleClick = useCallback((e: any) => {
    if (e.target.localName !== 'input') {
      radioRef.current?.nativeElement?.click()
    }
  }, [])

  return (
    <Button
      className={['radio-card', ...(props.className ?? [])].join(' ')}
      onClick={handleClick}
    >
      <div className="radio-card-header">
        <Avatar
          icon={props.icon}
          shape="square"
          size={props.avatarSize}
          style={props.avatarStyle}
        />
        <div>
          <h3>{props.title}</h3>
          <span>{props.description}</span>
        </div>
        <Radio
          ref={radioRef}
          name={props.group}
          value={props.value}
          checked={props.checked}
          onChange={props.onChange}
        />
      </div>
      {props.children ? (
        <div className="radio-card-body">{props.children}</div>
      ) : undefined}
    </Button>
  )
}

export default observer(RadioCard)
