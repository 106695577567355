import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Space, Input, Tree, List, Typography } from 'antd'
import { FunctionOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import {
  InputField as InputFieldType,
  DataType,
  OutputField,
  CustomNodeProps,
  NodeData,
} from '../../model'
import InputField from '../../components/input-field'
import { FlowContext } from '../../context'
import NodeSidebar from '../../components/node-sidebar'
import './sidebar.css'

const { Text } = Typography

const { TextArea } = Input
const { TreeNode } = Tree

const DocumentReaderNodeSidebar: React.FC = () => {
  const context = useContext(FlowContext)
  const currentNodeId = WorkflowStore.selectedNode?.id || ''

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String',
    action: 'update' | 'delete' = 'update'
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...WorkflowStore.selectedNode.data.data }
      let updatedInput = [...(currentNodeData.input || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else if (field === 'extends') {
        currentNodeData[field] = value
      } else {
        const inputIndex = updatedInput.findIndex(
          (input) => input.name === field
        )
        if (inputIndex !== -1) {
          if (action === 'update') {
            updatedInput[inputIndex] = { ...updatedInput[inputIndex], ...value }
          } else if (action === 'delete') {
            updatedInput.splice(inputIndex, 1)
          }
        } else {
          const newInputField: InputFieldType = {
            name: field,
            type: 'input',
            dataType: dataType,
            reference: '',
            ...value,
          }
          updatedInput.push(newInputField)
        }
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
        },
      }

      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  const getInputValue = (name: string) => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    return input ? input.value : undefined
  }

  const getInput = (name: string): InputFieldType => {
    const input = WorkflowStore.selectedNode?.data?.data?.input.find(
      (i: InputFieldType) => i.name === name
    )
    return (
      input || {
        name,
        type: 'input',
        dataType: 'String',
        value: '',
        reference: '',
      }
    )
  }

  const renderTreeNodes = (data: OutputField[]) => {
    return data.map((item) => {
      const title = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Space>
            <span>{item.name}</span>
            <span style={{ color: '#888' }}>[{item.type}]</span>
          </Space>
        </div>
      )

      if (item.children && item.children.length) {
        return (
          <TreeNode title={title} key={item.name}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return <TreeNode title={title} key={item.name} />
    })
  }

  if (WorkflowStore.selectedNode?.type !== 'DocumentReader') {
    return null
  }

  return (
    <NodeSidebar
      nodeType={'document_reader'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={WorkflowStore.selectedNode?.data?.data?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <Section title="Settings">
        <div className="setting-item document-reader">
          <label>Document</label>
          <div className="element" style={{ minWidth: 'fit-content' }}>
            <InputField
              index={0}
              item={getInput('document')}
              currentNodeId={currentNodeId}
              nodes={context?.nodes || []}
              edges={context?.edges || []}
              allowEditFieldName={false}
              supportArray={false}
              supportObject={false}
              displayDataType={false}
              displayFieldName={false}
              displayInputType={false}
              allowEditInputType={false}
              handleFormChange={(index, key, value) => {
                handleNodeDataChange('document', { [key]: value }, 'Document')
              }}
            />
          </div>
        </div>
      </Section>
      <Section title="Output" defaultCollapsed={true}>
        <List
          dataSource={WorkflowStore.selectedNode?.data?.data?.output || []}
          renderItem={(item: OutputField) => (
            <List.Item>
              <Space>
                <FunctionOutlined />
                <Text>{item.name}</Text>
              </Space>
              <Text type="secondary">{item.type}</Text>
            </List.Item>
          )}
        />
      </Section>
    </NodeSidebar>
  )
}

export default observer(DocumentReaderNodeSidebar)
