import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { RequestData } from 'services/request'
import { createApiRequest } from 'services/request'
import { Role, RoleList } from 'stores/models/role'
import { CodeMsgResponse } from './models/common'
import { PermissionType } from './models/permission'

class RoleStore {
  roleList: RoleList = {
    total_count: 0,
    roles: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async create(name: string, owner: boolean, permissions: PermissionType[]) {
    const params: RequestData = {
      body: {
        name,
        owner,
        permissions,
      },
    }
    const response = await createApiRequest<Role | CodeMsgResponse>(
      apiConfig.roleCreate,
      params
    )
    return response
  }

  async update(
    id: number,
    name: string,
    owner: boolean,
    permissions: PermissionType[]
  ) {
    const params: RequestData = {
      body: {
        id,
        name,
        owner,
        permissions,
      },
    }

    const response = await createApiRequest<Role | CodeMsgResponse>(
      apiConfig.roleUpdate,
      {
        body: {
          ...params.body,
          permissions: permissions.join(','),
        },
      }
    )
    const index = this.roleList.roles.findIndex((role) => role.id === id)
    if (index !== -1) {
      runInAction(() => {
        this.roleList.roles[index] = {
          ...this.roleList.roles[index],
          ...params.body,
        }
      })
    }
    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        size,
      },
    }
    const response = await createApiRequest<RoleList | CodeMsgResponse>(
      apiConfig.roleList,
      params
    )
    const data = response.data as RoleList
    runInAction(() => {
      this.roleList = {
        total_count: data.total_count,
        roles: data.roles.map((role: any) => {
          return {
            ...role,
            permissions: role.permissions.split(','),
          }
        }),
      }
    })

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.roleDelete,
      {
        queryParams: { id },
      }
    )
    runInAction(() => {
      this.roleList = {
        total_count: this.roleList.total_count - 1,
        roles: this.roleList.roles.filter((role) => role.id !== id),
      }
    })
    return response
  }

  getRoleById(id: number) {
    const role = this.roleList.roles.find((role) => role.id === id)
    return role
  }
}

export default new RoleStore()
