import React, { useRef, useCallback, useState } from 'react'
import Editor, { Monaco, loader } from '@monaco-editor/react/'
import * as monaco from 'monaco-editor'
import { Button, Space, Tooltip, Modal } from 'antd'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
loader.config({ monaco })

interface CodeEditorProps {
  value: string
  onChange: (value: string | undefined) => void
  language: string
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  value,
  onChange,
  language,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)

  const handleEditorDidMount = useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor, monaco: Monaco) => {
      editorRef.current = editor
      updateEditorOptions(editor, isFullscreen)
    },
    [isFullscreen]
  )

  const updateEditorOptions = (
    editor: monaco.editor.IStandaloneCodeEditor,
    fullscreen: boolean
  ) => {
    editor.updateOptions({
      minimap: { enabled: fullscreen },
      scrollbar: { vertical: 'auto', horizontal: 'auto' },
      lineNumbers: fullscreen ? 'on' : 'off',
      glyphMargin: fullscreen,
      folding: true,
      lineDecorationsWidth: fullscreen ? 10 : 0,
      lineNumbersMinChars: fullscreen ? 5 : 0,
      renderLineHighlight: fullscreen ? 'all' : 'none',
      overviewRulerLanes: fullscreen ? 2 : 0,
      hideCursorInOverviewRuler: !fullscreen,
      overviewRulerBorder: fullscreen,
    })
  }

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev)
    if (editorRef.current) {
      updateEditorOptions(editorRef.current, !isFullscreen)
    }
  }

  const renderEditor = (fullscreen: boolean) => (
    <Editor
      height={fullscreen ? 'calc(90vh - 100px)' : '300px'}
      language={language}
      value={value}
      onChange={onChange}
      onMount={handleEditorDidMount}
      options={{
        fontSize: fullscreen ? 14 : 12,
        lineHeight: fullscreen ? 24 : 20,
        fontFamily: "'Fira Code', monospace",
        wordWrap: 'on',
        scrollBeyondLastLine: false,
        renderWhitespace: fullscreen ? 'all' : 'none',
        contextmenu: true,
        quickSuggestions: {
          other: true,
          comments: true,
          strings: true,
        },
        parameterHints: { enabled: true },
      }}
    />
  )

  return (
    <div className="code-editor-container">
      <div className="toolbar">
        <Space>
          <Tooltip
            title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
          >
            <Button
              icon={
                isFullscreen ? (
                  <FullscreenExitOutlined />
                ) : (
                  <FullscreenOutlined />
                )
              }
              onClick={toggleFullscreen}
            />
          </Tooltip>
        </Space>
      </div>

      {!isFullscreen && renderEditor(false)}

      <Modal
        title="Code Editor"
        open={isFullscreen}
        onCancel={toggleFullscreen}
        width="90%"
        footer={null}
        styles={{ body: { height: 'calc(90vh - 110px)' } }}
      >
        {renderEditor(true)}
      </Modal>
    </div>
  )
}

export default CodeEditor
