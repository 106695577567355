import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useMemo,
} from 'react'
import { observer } from 'mobx-react'
import { Table, Input, Button, Space, Popconfirm, App, Flex } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileOutlined,
  SearchOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import type { InputRef, TableColumnType } from 'antd'
import documentStore, {
  DocumentStatusType,
  Document as DocumentType,
} from 'stores/document'
import { formatDate, formatFileSize } from 'utils/filter'
import { getMessageFromError } from 'utils/common'
import { useLocation } from 'react-router-dom'
import { KnowledgeCardProps } from '../knowledge-card'
import { useIsTabActive } from 'hooks/use-is-tab-active'
import { useExNavigate } from 'hooks/use-ex-navigate'

const getFileIcon = (filename: string) => {
  const extension = filename.split('.').pop()
  switch (extension) {
    case 'pdf':
      return <FilePdfOutlined style={{ marginRight: 5 }} />
    case 'txt':
      return <FileTextOutlined style={{ marginRight: 5 }} />
    case 'csv':
      return <FileExcelOutlined style={{ marginRight: 5 }} />
    case 'xlsx':
      return <FileExcelOutlined style={{ marginRight: 5 }} />
    case 'pptx':
      return <FilePptOutlined style={{ marginRight: 5 }} />
    case 'docx':
      return <FileWordOutlined style={{ marginRight: 5 }} />
    default:
      return <FileOutlined style={{ marginRight: 5 }} />
  }
}

const getDocumentStatusIcon = (status: DocumentType['status']) => {
  let icon = <></>
  switch (status) {
    case 'processed':
      icon = <CheckCircleOutlined style={{ color: 'green' }} />
      break
    case 'failed':
      icon = <ExclamationCircleOutlined style={{ color: 'red' }} />
      break
    case 'processing':
      icon = <LoadingOutlined style={{ color: 'blue' }} />
      break
    case 'pending':
      icon = <ClockCircleOutlined />
      break
  }
  return icon
}

const EditableCell: React.FC<{
  editing: boolean
  loading: boolean
  dataIndex: string
  title: any
  inputType: 'text' | 'number' | 'date'
  record: DocumentType
  children: React.ReactNode
  onSave: (value: string) => void
  onCancel: () => void
}> = ({
  editing,
  loading,
  dataIndex,
  title,
  inputType,
  record,
  children,
  onSave,
  onCancel,
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState(record.documentName)
  return (
    <div {...restProps}>
      {editing ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Input
              size="small"
              style={{ flexGrow: 1, flexBasis: 0 }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onPressEnter={() => onSave(inputValue)}
            />
            <div
              style={{
                flexShrink: 0,
                display: 'flex',
                gap: '0px',
                width: '140px',
              }}
            >
              <Button
                type="link"
                onClick={() => onSave(inputValue)}
                loading={loading}
              >
                Save
              </Button>
              <Button type="link" onClick={onCancel} loading={loading}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      ) : (
        children
      )}
    </div>
  )
}

const FileTable: ForwardRefRenderFunction<{ refresh: () => void }> = (
  props,
  ref
) => {
  const navigate = useExNavigate()
  const location = useLocation()
  const { message, modal } = App.useApp()
  const [filename, setFilename] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [updatingID, setUpdatingID] = useState(0)
  const [deletingID, setDeletingID] = useState(0)
  const isTabActive = useIsTabActive()

  useImperativeHandle(ref, () => ({
    refresh: () => load(1, size, ''),
  }))

  const load = async (
    page: number,
    size: number,
    filename: string,
    loading: boolean = true
  ) => {
    const knowledge = location.state.knowledge as KnowledgeCardProps['data']
    if (!knowledge) {
      message.error('Oops! No specific knowledge card.')
      return
    }
    if (loading) {
      setLoading(true)
    }
    try {
      await documentStore.getList(knowledge.id, page, size)
      setTotal(documentStore.documentList.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    if (loading) {
      setLoading(false)
    }
  }

  useEffect(() => {
    load(page, size, '')
  }, [page, size, filename])

  const needUpdateList = useMemo(() => {
    return (
      documentStore.documentList.documents.filter((document) =>
        [DocumentStatusType.Pending, DocumentStatusType.Processing].includes(
          document.status
        )
      ).length > 0
    )
  }, [documentStore.documentList.documents])

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined

    if (needUpdateList && isTabActive) {
      interval = setInterval(() => {
        load(page, size, '', false)
      }, 3000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [page, size, needUpdateList, isTabActive])

  const [editingKey, setEditingKey] = useState(0)
  const searchInput = useRef<InputRef>(null)
  const isEditing = (record: DocumentType) => record.id === editingKey

  const edit = (record: DocumentType) => {
    setEditingKey(record.id)
  }

  const handleEditButtonClick = (record: DocumentType) => {
    navigate('/portal/knowledge/document/chunk', {
      state: { document: record },
    })
  }

  const cancel = () => {
    setEditingKey(0)
  }

  const deleteFile = async (key: number) => {
    setDeletingID(key)
    try {
      await documentStore.delete(key)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingID(0)
    load(page, size, filename)
  }

  const save = async (key: number, name: string) => {
    setUpdatingID(key)
    try {
      await documentStore.update(key, name)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setUpdatingID(0)
    setEditingKey(0)
  }

  const getColumnSearchProps = (): TableColumnType<DocumentType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search Filename`}
          value={filename}
          onChange={(e) => setFilename(e.target.value)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            onClick={() => load(1, size, filename)}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={() => {
              setFilename('')
              load(1, size, '')
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    render: (text) => text,
  })

  const columns = [
    {
      title: 'Filename',
      dataIndex: 'filename',
      editable: true,
      ...getColumnSearchProps(),
      render: (text: string, record: DocumentType) => {
        const editable = isEditing(record)
        const icon = getFileIcon(record.file.path)
        const documentName = text ? text : record.documentName

        return editable ? (
          <EditableCell
            editing={editable}
            loading={record.id === updatingID}
            dataIndex="documentName"
            title="Filename"
            inputType="text"
            record={record}
            onSave={(newValue) => save(record.id, newValue)}
            onCancel={cancel}
            key={'cell_' + record.id}
          >
            {icon}
            {documentName}
          </EditableCell>
        ) : (
          <div key={'edit_' + record.id}>
            {icon}
            {documentName}
            <Button type="link" onClick={() => edit(record)}>
              Edit
            </Button>
          </div>
        )
      },
    },
    {
      title: 'File Size',
      render: (_: any, record: DocumentType) =>
        formatFileSize(record.file.size),
    },
    {
      title: 'Status',
      render: (_: any, record: DocumentType) => {
        return (
          <Space>
            {getDocumentStatusIcon(record.status)}
            <span style={{ textTransform: 'capitalize' }}>{record.status}</span>
          </Space>
        )
      },
    },
    {
      title: 'Created By',
      render: (_: any, record: DocumentType) => (
        <span>{record.file.username}</span>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (text: string) => formatDate(text),
    },
    {
      title: 'Actions',
      render: (_: any, record: DocumentType) => {
        return (
          <Flex gap="6px">
            <Button
              key={'edit_' + record.id}
              icon={<EditOutlined />}
              size="small"
              disabled={[
                DocumentStatusType.Pending,
                DocumentStatusType.Processing,
                DocumentStatusType.Failed,
              ].includes(record.status)}
              onClick={() => handleEditButtonClick(record)}
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the document?"
              onConfirm={() => deleteFile(record.id)}
            >
              <Button
                key={'delete_' + record.id}
                icon={<DeleteOutlined />}
                size="small"
                disabled={[
                  DocumentStatusType.Pending,
                  DocumentStatusType.Processing,
                ].includes(record.status)}
                loading={deletingID === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  return (
    <div className="file-table">
      <Table
        dataSource={documentStore.documentList.documents}
        columns={columns}
        loading={loading}
        pagination={{
          current: page,
          pageSize: size,
          total: total,
          position: ['bottomLeft'],
          onChange: (page, size) => {
            setPage(page)
            setSize(size)
          },
        }}
      />
    </div>
  )
}

export default observer(forwardRef(FileTable))
