/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { baseRequest } from '../utils/axios'
import {
  GenieCoreAppHandlersAgentResponsesPublishChannelResponse,
  GenieCoreAppHandlersChatRequestsSendRequest,
  GenieCoreAppHandlersChatResponsesChatConversationResponse,
  GenieCoreAppHandlersChatResponsesChatSessionResponse,
  GenieCoreAppHandlersDocumentResponsesDocumentResponse,
  GenieCoreAppHandlersFileResponsesFileResponse,
  RequestsAddDocumentChunkRequest,
  RequestsChangePasswordRequest,
  RequestsChangePasswordWithSessionIDRequest,
  RequestsCreateAPIRequest,
  RequestsCreateAgentRequest,
  RequestsCreateDocumentRequest,
  RequestsCreateIntentRequest,
  RequestsCreateKnowledgeRequest,
  RequestsCreateMetricsRequest,
  RequestsCreateOrganizationRequest,
  RequestsCreatePermissionRequest,
  RequestsCreatePublishChannelRequest,
  RequestsCreateQuestionRequest,
  RequestsCreateRoleRequest,
  RequestsCreateUserRequest,
  RequestsLoginRequest,
  RequestsResendEmailForUserCreationRequest,
  RequestsResetPasswordRequest,
  RequestsRestoreRequest,
  RequestsSaveRequest,
  RequestsSetup2FARequest,
  RequestsSetup2FAWithSessionRequest,
  RequestsUpdateAPIRequest,
  RequestsUpdateAgentRequest,
  RequestsUpdateAvatarRequest,
  RequestsUpdateDocumentChunkRequest,
  RequestsUpdateDocumentChunkStatusRequest,
  RequestsUpdateDocumentRequest,
  RequestsUpdateIntentRequest,
  RequestsUpdateKnowledgeRequest,
  RequestsUpdateMetricsRequest,
  RequestsUpdateOrganizationExpireDateRequest,
  RequestsUpdateOrganizationLanguageCodeRequest,
  RequestsUpdateOrganizationNameRequest,
  RequestsUpdateOrganizationRequest,
  RequestsUpdateOrganizationRequired2FARequest,
  RequestsUpdateOrganizationStatusRequest,
  RequestsUpdatePermissionRequest,
  RequestsUpdatePublishChannelRequest,
  RequestsUpdateQuestionRequest,
  RequestsUpdateRoleRequest,
  RequestsUpdateUserNameRequest,
  RequestsUpdateUserStatusRequest,
  RequestsValidate2FARequest,
  RequestsValidate2FASetupSessionRequest,
  ResponsesAPIListResponse,
  ResponsesAPIResponse,
  ResponsesAgentListResponse,
  ResponsesAgentResponse,
  ResponsesAudioResponse,
  ResponsesEmbeddingModelResponse,
  ResponsesIntentListResponse,
  ResponsesIntentResponse,
  ResponsesKnowledgeDocumentChunkListResponse,
  ResponsesKnowledgeDocumentResponse,
  ResponsesKnowledgeListResponse,
  ResponsesKnowledgeMappingListResponse,
  ResponsesKnowledgeResponse,
  ResponsesLLMResponse,
  ResponsesLoginResponse,
  ResponsesMetricsListResponse,
  ResponsesMetricsResponse,
  ResponsesOrganizationListResponse,
  ResponsesOrganizationResponse,
  ResponsesPaginatedFileResponse,
  ResponsesPaginatedWorkflowResponse,
  ResponsesPermissionResponse,
  ResponsesPermissionTreeResponse,
  ResponsesPublishChannelListResponse,
  ResponsesQuestionListResponse,
  ResponsesQuestionResponse,
  ResponsesRerankModelResponse,
  ResponsesRoleListResponse,
  ResponsesRoleResponse,
  ResponsesSettingResponse,
  ResponsesUpdateAvatarResponse,
  ResponsesUserListResponse,
  ResponsesUserResponse,
  ResponsesVersionResponse,
  ResponsesWorkflowResponse,
} from './data-contracts'
import { ContentType, RequestParams } from './http-client'

/**
 * @description Retrieves an agent by its ID using query parameter.
 *
 * @tags Agent
 * @name V1AgentList
 * @summary Get agent by UUID
 * @request GET:/api/v1/agent
 */
export const v1AgentList = (
  query: {
    /** Agent UUID */
    uuid: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAgentResponse>({
    url: `/api/v1/agent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new agent with provided details.
 *
 * @tags Agent
 * @name V1AgentCreateCreate
 * @summary Create agent
 * @request POST:/api/v1/agent/create
 */
export const v1AgentCreateCreate = (request: RequestsCreateAgentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAgentResponse>({
    url: `/api/v1/agent/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an agent based on the query parameter ID.
 *
 * @tags Agent
 * @name V1AgentDeleteDelete
 * @summary Delete agent
 * @request DELETE:/api/v1/agent/delete
 */
export const v1AgentDeleteDelete = (
  query: {
    /** Agent ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/agent/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of agents based on pagination parameters.
 *
 * @tags Agent
 * @name V1AgentListList
 * @summary List agents
 * @request GET:/api/v1/agent/list
 */
export const v1AgentListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAgentListResponse>({
    url: `/api/v1/agent/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing agent with provided details.
 *
 * @tags Agent
 * @name V1AgentUpdatePartialUpdate
 * @summary Update agent
 * @request PATCH:/api/v1/agent/update
 */
export const v1AgentUpdatePartialUpdate = (request: RequestsUpdateAgentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAgentResponse>({
    url: `/api/v1/agent/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new API with provided details.
 *
 * @tags API
 * @name V1ApiCreateCreate
 * @summary Create API
 * @request POST:/api/v1/api/create
 */
export const v1ApiCreateCreate = (request: RequestsCreateAPIRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAPIResponse>({
    url: `/api/v1/api/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an API by its ID.
 *
 * @tags API
 * @name V1ApiDeleteDelete
 * @summary Delete API
 * @request DELETE:/api/v1/api/delete
 */
export const v1ApiDeleteDelete = (
  query: {
    /** API ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/api/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of APIs based on pagination parameters.
 *
 * @tags API
 * @name V1ApiListList
 * @summary List APIs
 * @request GET:/api/v1/api/list
 */
export const v1ApiListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAPIListResponse>({
    url: `/api/v1/api/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing API.
 *
 * @tags API
 * @name V1ApiUpdatePartialUpdate
 * @summary Update API
 * @request PATCH:/api/v1/api/update
 */
export const v1ApiUpdatePartialUpdate = (request: RequestsUpdateAPIRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesAPIResponse>({
    url: `/api/v1/api/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Find Audio File by ID
 *
 * @tags Audio
 * @name V1AudiosList
 * @summary Find Audio File
 * @request GET:/api/v1/audios
 */
export const v1AudiosList = (
  query: {
    /** Audio ID */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAudioResponse>({
    url: `/api/v1/audios`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Audio File by ID
 *
 * @tags Audio
 * @name V1AudiosDelete
 * @summary Delete Audio File
 * @request DELETE:/api/v1/audios
 */
export const v1AudiosDelete = (
  query: {
    /** Audio File ID to delete */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/audios`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload Audio File
 *
 * @tags Audio
 * @name V1AudiosUploadCreate
 * @summary Upload Audio File
 * @request POST:/api/v1/audios/upload
 */
export const v1AudiosUploadCreate = (
  query: {
    /** Specifies the ownership of the files (audio) */
    fileBelongs: string
  },
  data: {
    /** Audio files to upload */
    file: File
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAudioResponse>({
    url: `/api/v1/audios/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Upload and Transcribe Audio File
 *
 * @tags Audio
 * @name V1AudiosUploadAndTranscribeCreate
 * @summary Upload and Transcribe Audio File
 * @request POST:/api/v1/audios/uploadAndTranscribe
 */
export const v1AudiosUploadAndTranscribeCreate = (
  query: {
    /** Specifies the ownership of the files (audio) */
    fileBelongs: string
    /** Agent identifier */
    agentUUID: string
  },
  data: {
    /** Document files to upload */
    file: File
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesAudioResponse>({
    url: `/api/v1/audios/uploadAndTranscribe`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Retrieves a list of the most recent conversations for a given session.
 *
 * @tags ChatConversations
 * @name V1ChatConversationsList
 * @summary Get recent conversations
 * @request GET:/api/v1/chat/conversations
 */
export const v1ChatConversationsList = (
  query: {
    /** Session UUID */
    session_uuid: string
    /** Last ID for pagination */
    last_id?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesChatConversationResponse[]>({
    url: `/api/v1/chat/conversations`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sends a message to a chat session and returns the response as a stream of Server-Sent Events (SSE).
 *
 * @tags ChatConversations
 * @name V1ChatConversationsSendCreate
 * @summary Send chat message (Streaming)
 * @request POST:/api/v1/chat/conversations/send
 */
export const v1ChatConversationsSendCreate = (
  request: GenieCoreAppHandlersChatRequestsSendRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/chat/conversations/send`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of the most recent chat sessions for a given agent.
 *
 * @tags ChatSessions
 * @name V1ChatSessionsRecentList
 * @summary Get recent chat sessions
 * @request GET:/api/v1/chat/sessions/recent
 */
export const v1ChatSessionsRecentList = (
  query: {
    /** Agent UUID */
    agent_uuid: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersChatResponsesChatSessionResponse[]>({
    url: `/api/v1/chat/sessions/recent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Find Document File by ID
 *
 * @tags Document
 * @name V1DocumentsList
 * @summary Find Document File
 * @request GET:/api/v1/documents
 */
export const v1DocumentsList = (
  query: {
    /** Document ID */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersDocumentResponsesDocumentResponse>({
    url: `/api/v1/documents`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete Document File by ID
 *
 * @tags Document
 * @name V1DocumentsDelete
 * @summary Delete Document File
 * @request DELETE:/api/v1/documents
 */
export const v1DocumentsDelete = (
  query: {
    /** Document File ID to delete */
    id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/documents`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload Document File
 *
 * @tags Document
 * @name V1DocumentsUploadCreate
 * @summary Upload Document File
 * @request POST:/api/v1/documents/upload
 */
export const v1DocumentsUploadCreate = (
  data: {
    /**
     * Document files to upload
     * @format binary
     */
    file: File
    /** Specifies the ownership of the files (document) */
    fileBelongs: string
    /** Agent UUID */
    agentUUID: string
    /** Session UUID */
    sessionUUID?: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersDocumentResponsesDocumentResponse>({
    url: `/api/v1/documents/upload`,
    method: 'POST',
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Get evaluation metrics by ID.
 *
 * @tags EvaluationMetrics
 * @name V1EvaluationMetricsList
 * @summary Get evaluation metrics
 * @request GET:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsList = (
  query: {
    /** Metrics ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesMetricsResponse>({
    url: `/api/v1/evaluation/metrics`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates new evaluation metrics with provided details.
 *
 * @tags EvaluationMetrics
 * @name V1EvaluationMetricsCreate
 * @summary Create evaluation metrics
 * @request POST:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsCreate = (request: RequestsCreateMetricsRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesMetricsResponse>({
    url: `/api/v1/evaluation/metrics`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes evaluation metrics by its ID.
 *
 * @tags EvaluationMetrics
 * @name V1EvaluationMetricsDelete
 * @summary Delete evaluation metrics
 * @request DELETE:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsDelete = (
  query: {
    /** Metrics ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/evaluation/metrics`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates existing evaluation metrics with provided details.
 *
 * @tags EvaluationMetrics
 * @name V1EvaluationMetricsPartialUpdate
 * @summary Update evaluation metrics
 * @request PATCH:/api/v1/evaluation/metrics
 */
export const v1EvaluationMetricsPartialUpdate = (request: RequestsUpdateMetricsRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesMetricsResponse>({
    url: `/api/v1/evaluation/metrics`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get a list of all evaluation metrics.
 *
 * @tags EvaluationMetrics
 * @name V1EvaluationMetricsListList
 * @summary List all evaluation metrics
 * @request GET:/api/v1/evaluation/metrics/list
 */
export const v1EvaluationMetricsListList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesMetricsListResponse>({
    url: `/api/v1/evaluation/metrics/list`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get an evaluation question by ID.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsList
 * @summary Get evaluation question
 * @request GET:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsList = (
  query: {
    /** Question ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesQuestionResponse>({
    url: `/api/v1/evaluation/questions`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new evaluation question with provided details.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsCreate
 * @summary Create evaluation question
 * @request POST:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsCreate = (request: RequestsCreateQuestionRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesQuestionResponse>({
    url: `/api/v1/evaluation/questions`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an evaluation question by its ID.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsDelete
 * @summary Delete evaluation question
 * @request DELETE:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsDelete = (
  query: {
    /** Question ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/evaluation/questions`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing evaluation question with provided details.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsPartialUpdate
 * @summary Update evaluation question
 * @request PATCH:/api/v1/evaluation/questions
 */
export const v1EvaluationQuestionsPartialUpdate = (
  request: RequestsUpdateQuestionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesQuestionResponse>({
    url: `/api/v1/evaluation/questions`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Create multiple evaluation questions from an uploaded CSV file
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsBulkCreate
 * @summary Bulk create evaluation questions from CSV
 * @request POST:/api/v1/evaluation/questions/bulk
 */
export const v1EvaluationQuestionsBulkCreate = (
  data: {
    /**
     * CSV file containing evaluation questions
     * @format binary
     */
    file: File
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/evaluation/questions/bulk`,
    method: 'POST',
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Get a paginated list of evaluation questions.
 *
 * @tags EvaluationQuestion
 * @name V1EvaluationQuestionsListList
 * @summary List evaluation questions
 * @request GET:/api/v1/evaluation/questions/list
 */
export const v1EvaluationQuestionsListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
    /** agent id */
    agentID: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesQuestionListResponse>({
    url: `/api/v1/evaluation/questions/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update File by ID
 *
 * @tags File
 * @name V1FilePartialUpdate
 * @summary Update File
 * @request PATCH:/api/v1/file
 */
export const v1FilePartialUpdate = (
  query: {
    /** File ID to update */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersFileResponsesFileResponse>({
    url: `/api/v1/file`,
    method: 'PATCH',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete File by ID
 *
 * @tags File
 * @name V1FileDeleteDelete
 * @summary Delete File
 * @request DELETE:/api/v1/file/delete
 */
export const v1FileDeleteDelete = (
  query: {
    /** File ID to delete */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/file/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Upload multiple documents
 *
 * @tags File
 * @name V1FileDocumentsUploadCreate
 * @summary Upload Documents
 * @request POST:/api/v1/file/documents/upload
 */
export const v1FileDocumentsUploadCreate = (
  query: {
    /** Specifies the ownership of the files (session, document, agent_icon, avatar) */
    fileBelongs: string
  },
  data: {
    /** Document files to upload */
    file: File[]
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersFileResponsesFileResponse[]>({
    url: `/api/v1/file/documents/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Upload multiple images
 *
 * @tags File
 * @name V1FileImagesUploadCreate
 * @summary Upload Images
 * @request POST:/api/v1/file/images/upload
 */
export const v1FileImagesUploadCreate = (
  query: {
    /** Specifies the ownership of the files (session, document, agent_icon, avatar) */
    fileBelongs: string
  },
  data: {
    /** Image files to upload */
    file: File[]
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersFileResponsesFileResponse[]>({
    url: `/api/v1/file/images/upload`,
    method: 'POST',
    params: query,
    data: data,
    headers: { 'Content-Type': ContentType.FormData },
    ...params,
  })
} /**
 * @description Paginate Files with filtering and sorting options
 *
 * @tags File
 * @name V1FilePaginateList
 * @summary Paginate Files
 * @request GET:/api/v1/file/paginate
 */
export const v1FilePaginateList = (
  query: {
    /** Page number */
    page?: number
    /** Number of items per page */
    pageSize?: number
    /** Filter by Organization ID */
    organizationID?: number
    /** Filter by User ID */
    userID?: number
    /** Filter by Index ID */
    indexID?: number
    /** Filter by File Types */
    fileTypes?: any[]
    /** Specifies the ownership of the files (session, document, agent_icon) */
    fileBelongs: string
    /** Filter by Filename (can be partial) */
    filename?: string
    /** Field to order by */
    orderBy?: string
    /** Sort in descending order */
    desc?: boolean
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedFileResponse>({
    url: `/api/v1/file/paginate`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Serve a static file from cloud storage
 *
 * @tags File
 * @name V1FileDetail
 * @summary Serve Static File
 * @request GET:/api/v1/file/{uuid}
 */
export const v1FileDetail = (uuid: string, params: RequestParams = {}) => {
  return baseRequest.request<File>({
    url: `/api/v1/file/${uuid}`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Creates a new intent with provided details.
 *
 * @tags Intent
 * @name V1IntentCreateCreate
 * @summary Create intent
 * @request POST:/api/v1/intent/create
 */
export const v1IntentCreateCreate = (request: RequestsCreateIntentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesIntentResponse>({
    url: `/api/v1/intent/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an intent by its ID.
 *
 * @tags Intent
 * @name V1IntentDeleteDelete
 * @summary Delete intent
 * @request DELETE:/api/v1/intent/delete
 */
export const v1IntentDeleteDelete = (
  query: {
    /** Intent ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/intent/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of intents based on pagination parameters.
 *
 * @tags Intent
 * @name V1IntentListList
 * @summary List intents
 * @request GET:/api/v1/intent/list
 */
export const v1IntentListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesIntentListResponse>({
    url: `/api/v1/intent/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing intent.
 *
 * @tags Intent
 * @name V1IntentUpdatePartialUpdate
 * @summary Update intent
 * @request PATCH:/api/v1/intent/update
 */
export const v1IntentUpdatePartialUpdate = (request: RequestsUpdateIntentRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesIntentResponse>({
    url: `/api/v1/intent/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new Knowledge with provided details.
 *
 * @tags Knowledge
 * @name V1KnowledgeCreateCreate
 * @summary Create Knowledge
 * @request POST:/api/v1/knowledge/create
 */
export const v1KnowledgeCreateCreate = (request: RequestsCreateKnowledgeRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesKnowledgeResponse>({
    url: `/api/v1/knowledge/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an Knowledge by its ID.
 *
 * @tags Knowledge
 * @name V1KnowledgeDeleteDelete
 * @summary Delete Knowledge
 * @request DELETE:/api/v1/knowledge/delete
 */
export const v1KnowledgeDeleteDelete = (
  query: {
    /** Knowledge ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/knowledge/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new document associated with a specific Knowledge and Organization.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentCreate
 * @summary Create a new document
 * @request POST:/api/v1/knowledge/document
 */
export const v1KnowledgeDocumentCreate = (
  CreateDocumentRequest: RequestsCreateDocumentRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeDocumentResponse>({
    url: `/api/v1/knowledge/document`,
    method: 'POST',
    data: CreateDocumentRequest,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an existing document associated with a specific Knowledge and Organization.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentDelete
 * @summary Delete a document
 * @request DELETE:/api/v1/knowledge/document
 */
export const v1KnowledgeDocumentDelete = (
  query: {
    /** Document ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing document associated with a specific Knowledge and Organization.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentPartialUpdate
 * @summary Update a document
 * @request PATCH:/api/v1/knowledge/document
 */
export const v1KnowledgeDocumentPartialUpdate = (
  UpdateDocumentRequest: RequestsUpdateDocumentRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeDocumentResponse>({
    url: `/api/v1/knowledge/document`,
    method: 'PATCH',
    data: UpdateDocumentRequest,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Add a new document chunk
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkCreate
 * @request POST:/api/v1/knowledge/document/chunk
 */
export const v1KnowledgeDocumentChunkCreate = (
  request: RequestsAddDocumentChunkRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Delete a document chunk
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkDelete
 * @request DELETE:/api/v1/knowledge/document/chunk
 */
export const v1KnowledgeDocumentChunkDelete = (
  query: {
    /** Knowledge ID */
    knowledgeID: number
    /** Document Chunk ID */
    _id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update a document chunk with new content
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkPartialUpdate
 * @request PATCH:/api/v1/knowledge/document/chunk
 */
export const v1KnowledgeDocumentChunkPartialUpdate = (
  request: RequestsUpdateDocumentChunkRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Update a document chunk status to enabled or disabled
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunkStatusPartialUpdate
 * @request PATCH:/api/v1/knowledge/document/chunk/status
 */
export const v1KnowledgeDocumentChunkStatusPartialUpdate = (
  request: RequestsUpdateDocumentChunkStatusRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/knowledge/document/chunk/status`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Knowledge document chunks based on pagination parameters and KnowledgeID.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentChunksList
 * @summary List Knowledge document chunks
 * @request GET:/api/v1/knowledge/document/chunks
 */
export const v1KnowledgeDocumentChunksList = (
  query: {
    /** Knowledge ID */
    knowledgeID: number
    /** document ID */
    documentID: number
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeDocumentChunkListResponse>({
    url: `/api/v1/knowledge/document/chunks`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Knowledge mappings based on pagination parameters and KnowledgeID.
 *
 * @tags Knowledge
 * @name V1KnowledgeDocumentListList
 * @summary List Knowledge mappings
 * @request GET:/api/v1/knowledge/document/list
 */
export const v1KnowledgeDocumentListList = (
  query: {
    /** Knowledge ID */
    knowledgeID: number
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeMappingListResponse>({
    url: `/api/v1/knowledge/document/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Knowledgees based on pagination parameters.
 *
 * @tags Knowledge
 * @name V1KnowledgeListList
 * @summary List Knowledgees
 * @request GET:/api/v1/knowledge/list
 */
export const v1KnowledgeListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesKnowledgeListResponse>({
    url: `/api/v1/knowledge/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing Knowledge.
 *
 * @tags Knowledge
 * @name V1KnowledgeUpdatePartialUpdate
 * @summary Update Knowledge
 * @request PATCH:/api/v1/knowledge/update
 */
export const v1KnowledgeUpdatePartialUpdate = (request: RequestsUpdateKnowledgeRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesKnowledgeResponse>({
    url: `/api/v1/knowledge/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of all Embedding models.
 *
 * @tags LLM
 * @name V1LlmEmbeddingModelsList
 * @summary Get all Embedding models
 * @request GET:/api/v1/llm/embedding-models
 */
export const v1LlmEmbeddingModelsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesEmbeddingModelResponse[]>({
    url: `/api/v1/llm/embedding-models`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of all LLM models.
 *
 * @tags LLM
 * @name V1LlmModelsList
 * @summary Get all LLM models
 * @request GET:/api/v1/llm/models
 */
export const v1LlmModelsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLLMResponse[]>({
    url: `/api/v1/llm/models`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of all Rerank models.
 *
 * @tags LLM
 * @name V1LlmRerankModelsList
 * @summary Get all Rerank models
 * @request GET:/api/v1/llm/rerank-models
 */
export const v1LlmRerankModelsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRerankModelResponse[]>({
    url: `/api/v1/llm/rerank-models`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new Organization with provided details.
 *
 * @tags Organization
 * @name V1OrganizationCreateCreate
 * @summary Create Organization
 * @request POST:/api/v1/organization/create
 */
export const v1OrganizationCreateCreate = (request: RequestsCreateOrganizationRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes an Organization by its ID.
 *
 * @tags Organization
 * @name V1OrganizationDeleteDelete
 * @summary Delete Organization
 * @request DELETE:/api/v1/organization/delete
 */
export const v1OrganizationDeleteDelete = (
  query: {
    /** Organization ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/organization/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of Organizations based on pagination parameters.
 *
 * @tags Organization
 * @name V1OrganizationListList
 * @summary List Organizations
 * @request GET:/api/v1/organization/list
 */
export const v1OrganizationListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationListResponse>({
    url: `/api/v1/organization/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates all fields of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateUpdate
 * @summary Update Organization
 * @request PUT:/api/v1/organization/update
 */
export const v1OrganizationUpdateUpdate = (request: RequestsUpdateOrganizationRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update`,
    method: 'PUT',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the expire date of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateExpireDatePartialUpdate
 * @summary Update Organization Expire Date
 * @request PATCH:/api/v1/organization/update-expire-date
 */
export const v1OrganizationUpdateExpireDatePartialUpdate = (
  request: RequestsUpdateOrganizationExpireDateRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-expire-date`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the language code of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateLanguageCodePartialUpdate
 * @summary Update Organization Language Code
 * @request PATCH:/api/v1/organization/update-language-code
 */
export const v1OrganizationUpdateLanguageCodePartialUpdate = (
  request: RequestsUpdateOrganizationLanguageCodeRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-language-code`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the name of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateNamePartialUpdate
 * @summary Update Organization Name
 * @request PATCH:/api/v1/organization/update-name
 */
export const v1OrganizationUpdateNamePartialUpdate = (
  request: RequestsUpdateOrganizationNameRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-name`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the required 2FA setting of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateRequired2FaPartialUpdate
 * @summary Update Organization Required 2FA
 * @request PATCH:/api/v1/organization/update-required-2fa
 */
export const v1OrganizationUpdateRequired2FaPartialUpdate = (
  request: RequestsUpdateOrganizationRequired2FARequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-required-2fa`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the status of an existing Organization.
 *
 * @tags Organization
 * @name V1OrganizationUpdateStatusPartialUpdate
 * @summary Update Organization Status
 * @request PATCH:/api/v1/organization/update-status
 */
export const v1OrganizationUpdateStatusPartialUpdate = (
  request: RequestsUpdateOrganizationStatusRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesOrganizationResponse>({
    url: `/api/v1/organization/update-status`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new permission with provided details.
 *
 * @tags Permission
 * @name V1PermissionCreateCreate
 * @summary Create Permission
 * @request POST:/api/v1/permission/create
 */
export const v1PermissionCreateCreate = (request: RequestsCreatePermissionRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesPermissionResponse>({
    url: `/api/v1/permission/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes a permission by its ID.
 *
 * @tags Permission
 * @name V1PermissionDeleteDelete
 * @summary Delete Permission
 * @request DELETE:/api/v1/permission/delete
 */
export const v1PermissionDeleteDelete = (
  query: {
    /** Permission ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/permission/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves the permission tree for a given organization type.
 *
 * @tags Permission
 * @name V1PermissionTreeList
 * @summary Get Permission Tree
 * @request GET:/api/v1/permission/tree
 */
export const v1PermissionTreeList = (
  query: {
    /** Organization Type */
    organizationType: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPermissionTreeResponse>({
    url: `/api/v1/permission/tree`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing permission.
 *
 * @tags Permission
 * @name V1PermissionUpdatePartialUpdate
 * @summary Update Permission
 * @request PATCH:/api/v1/permission/update
 */
export const v1PermissionUpdatePartialUpdate = (
  request: RequestsUpdatePermissionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPermissionResponse>({
    url: `/api/v1/permission/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a publish channel by its ID using query parameter.
 *
 * @tags PublishChannel
 * @name V1PublishchannelList
 * @summary Get publish channel by ID
 * @request GET:/api/v1/publishchannel
 */
export const v1PublishchannelList = (
  query: {
    /** Publish Channel ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new publish channel with provided details.
 *
 * @tags PublishChannel
 * @name V1PublishchannelCreateCreate
 * @summary Create publish channel
 * @request POST:/api/v1/publishchannel/create
 */
export const v1PublishchannelCreateCreate = (
  request: RequestsCreatePublishChannelRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes a publish channel based on the query parameter ID.
 *
 * @tags PublishChannel
 * @name V1PublishchannelDeleteDelete
 * @summary Delete publish channel
 * @request DELETE:/api/v1/publishchannel/delete
 */
export const v1PublishchannelDeleteDelete = (
  query: {
    /** Publish Channel ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<void>({
    url: `/api/v1/publishchannel/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of publish channels based on pagination parameters.
 *
 * @tags PublishChannel
 * @name V1PublishchannelListList
 * @summary List publish channels
 * @request GET:/api/v1/publishchannel/list
 */
export const v1PublishchannelListList = (
  query: {
    /** agent id */
    agent_id: number
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPublishChannelListResponse>({
    url: `/api/v1/publishchannel/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates an existing publish channel with provided details.
 *
 * @tags PublishChannel
 * @name V1PublishchannelUpdatePartialUpdate
 * @summary Update publish channel
 * @request PATCH:/api/v1/publishchannel/update
 */
export const v1PublishchannelUpdatePartialUpdate = (
  request: RequestsUpdatePublishChannelRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<GenieCoreAppHandlersAgentResponsesPublishChannelResponse>({
    url: `/api/v1/publishchannel/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new role with provided details.
 *
 * @tags Role
 * @name V1RoleCreateCreate
 * @summary Create Role
 * @request POST:/api/v1/role/create
 */
export const v1RoleCreateCreate = (request: RequestsCreateRoleRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRoleResponse>({
    url: `/api/v1/role/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Deletes a role by its ID.
 *
 * @tags Role
 * @name V1RoleDeleteDelete
 * @summary Delete Role
 * @request DELETE:/api/v1/role/delete
 */
export const v1RoleDeleteDelete = (
  query: {
    /** Role ID */
    id: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/role/delete`,
    method: 'DELETE',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves a list of roles based on pagination parameters.
 *
 * @tags Role
 * @name V1RoleListList
 * @summary List Roles
 * @request GET:/api/v1/role/list
 */
export const v1RoleListList = (
  query: {
    /** Page number */
    page: number
    /** Page size */
    size: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesRoleListResponse>({
    url: `/api/v1/role/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates details of an existing role.
 *
 * @tags Role
 * @name V1RoleUpdatePartialUpdate
 * @summary Update Role
 * @request PATCH:/api/v1/role/update
 */
export const v1RoleUpdatePartialUpdate = (request: RequestsUpdateRoleRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesRoleResponse>({
    url: `/api/v1/role/update`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Get Settings
 *
 * @tags System
 * @name V1SystemSettingsList
 * @summary Get Settings
 * @request GET:/api/v1/system/settings
 */
export const v1SystemSettingsList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesSettingResponse>({
    url: `/api/v1/system/settings`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Get Version
 *
 * @tags System
 * @name V1SystemVersionList
 * @summary Get Version
 * @request GET:/api/v1/system/version
 */
export const v1SystemVersionList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesVersionResponse>({
    url: `/api/v1/system/version`,
    method: 'GET',
    ...params,
  })
} /**
 * @description Generates a 2FA QR code URL for the authenticated user.
 *
 * @tags User
 * @name V1User2FaQrList
 * @summary Generate 2FA QR Code
 * @request GET:/api/v1/user/2fa/qr
 */
export const v1User2FaQrList = (params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/2fa/qr`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Generates a 2FA QR code URL for the user with the given session ID.
 *
 * @tags User
 * @name V1User2FaQrSessionList
 * @summary Generate 2FA QR Code with Session ID
 * @request GET:/api/v1/user/2fa/qr/session
 */
export const v1User2FaQrSessionList = (
  query: {
    /** Session ID */
    session_id: string
    /** User ID */
    user_id: string
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/2fa/qr/session`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets up 2FA for a user
 *
 * @tags User
 * @name V1User2FaSetupCreate
 * @summary Set up 2FA
 * @request POST:/api/v1/user/2fa/setup
 */
export const v1User2FaSetupCreate = (request: RequestsSetup2FARequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/setup`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Sets up 2FA for a user using a session ID
 *
 * @tags User
 * @name V1User2FaSetupSessionCreate
 * @summary Set up 2FA with Session
 * @request POST:/api/v1/user/2fa/setup/session
 */
export const v1User2FaSetupSessionCreate = (
  request: RequestsSetup2FAWithSessionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/setup/session`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Validates a 2FA code for a user
 *
 * @tags User
 * @name V1User2FaValidateCreate
 * @summary Validate 2FA Code
 * @request POST:/api/v1/user/2fa/validate
 */
export const v1User2FaValidateCreate = (request: RequestsValidate2FARequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/validate`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Validates a 2FA setup session
 *
 * @tags User
 * @name V1User2FaValidateSetupSessionCreate
 * @summary Validate 2FA Setup Session
 * @request POST:/api/v1/user/2fa/validate-setup-session
 */
export const v1User2FaValidateSetupSessionCreate = (
  request: RequestsValidate2FASetupSessionRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/2fa/validate-setup-session`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Changes the password of the authenticated user.
 *
 * @tags User
 * @name V1UserChangePasswordCreate
 * @summary Change User Password
 * @request POST:/api/v1/user/change-password
 */
export const v1UserChangePasswordCreate = (request: RequestsChangePasswordRequest, params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/change-password`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Changes the password of a user using a session ID.
 *
 * @tags User
 * @name V1UserChangePasswordSessionCreate
 * @summary Change User Password with Session ID
 * @request POST:/api/v1/user/change-password/session
 */
export const v1UserChangePasswordSessionCreate = (
  request: RequestsChangePasswordWithSessionIDRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/change-password/session`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new user (admin only).
 *
 * @tags User
 * @name V1UserCreateCreate
 * @summary Create New User
 * @request POST:/api/v1/user/create
 */
export const v1UserCreateCreate = (request: RequestsCreateUserRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/create`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Retrieves the information of the currently authenticated user.
 *
 * @tags User
 * @name V1UserCurrentList
 * @summary Get Current User
 * @request GET:/api/v1/user/current
 */
export const v1UserCurrentList = (params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/current`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Gets a paginated list of users for the authenticated user's organization.
 *
 * @tags User
 * @name V1UserListList
 * @summary List Users by Organization
 * @request GET:/api/v1/user/list
 */
export const v1UserListList = (
  query?: {
    /** Page number */
    page?: number
    /** Page size */
    page_size?: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserListResponse>({
    url: `/api/v1/user/list`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Logs in a user with email and password, optionally with a OTP for 2FA.
 *
 * @tags User
 * @name V1UserLoginCreate
 * @summary User Login
 * @request POST:/api/v1/user/login
 */
export const v1UserLoginCreate = (request: RequestsLoginRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesLoginResponse>({
    url: `/api/v1/user/login`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Logs out the currently authenticated user.
 *
 * @tags User
 * @name V1UserLogoutList
 * @summary User Logout
 * @request GET:/api/v1/user/logout
 */
export const v1UserLogoutList = (params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/logout`,
    method: 'GET',
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Resends the user creation email with a new generated password for users who haven't changed their password yet.
 *
 * @tags User
 * @name V1UserResendCreationEmailCreate
 * @summary Resend User Creation Email
 * @request POST:/api/v1/user/resend-creation-email
 */
export const v1UserResendCreationEmailCreate = (
  request: RequestsResendEmailForUserCreationRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/resend-creation-email`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Resets the password of a user (admin only).
 *
 * @tags User
 * @name V1UserResetPasswordCreate
 * @summary Reset User Password
 * @request POST:/api/v1/user/reset-password
 */
export const v1UserResetPasswordCreate = (request: RequestsResetPasswordRequest, params: RequestParams = {}) => {
  return baseRequest.request<string>({
    url: `/api/v1/user/reset-password`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the avatar of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateAvatarPartialUpdate
 * @summary Update User Avatar
 * @request PATCH:/api/v1/user/update-avatar
 */
export const v1UserUpdateAvatarPartialUpdate = (request: RequestsUpdateAvatarRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUpdateAvatarResponse>({
    url: `/api/v1/user/update-avatar`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the name of the authenticated user.
 *
 * @tags User
 * @name V1UserUpdateNamePartialUpdate
 * @summary Update User Name
 * @request PATCH:/api/v1/user/update-name
 */
export const v1UserUpdateNamePartialUpdate = (request: RequestsUpdateUserNameRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/update-name`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Updates the status of a user (admin only).
 *
 * @tags User
 * @name V1UserUpdateStatusPartialUpdate
 * @summary Update User Status
 * @request PATCH:/api/v1/user/update-status
 */
export const v1UserUpdateStatusPartialUpdate = (
  request: RequestsUpdateUserStatusRequest,
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesUserResponse>({
    url: `/api/v1/user/update-status`,
    method: 'PATCH',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description handles fetching latest workflow by agent ID.
 *
 * @tags Workflow
 * @name V1WorkflowGetLatestWorkflowByAgentList
 * @summary handles fetching latest workflow by agent ID.
 * @request GET:/api/v1/workflow/get-latest-workflow-by-agent
 */
export const v1WorkflowGetLatestWorkflowByAgentList = (
  query: {
    /** Agent ID */
    agentID: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/get-latest-workflow-by-agent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description handles fetching published workflow by agent ID.
 *
 * @tags Workflow
 * @name V1WorkflowGetPublishedWorkflowByAgentList
 * @summary handles fetching published workflow by agent ID.
 * @request GET:/api/v1/workflow/get-published-workflow-by-agent
 */
export const v1WorkflowGetPublishedWorkflowByAgentList = (
  query: {
    /** Agent ID */
    agentID: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/get-published-workflow-by-agent`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Fetches a paginated list of workflows for a specific agent.
 *
 * @tags Workflow
 * @name V1WorkflowPaginatedList
 * @summary Fetch paginated workflows by agent ID
 * @request GET:/api/v1/workflow/paginated
 */
export const v1WorkflowPaginatedList = (
  query: {
    /** Agent ID */
    agentID: number
    /** Page number */
    page: number
    /** Page size */
    pageSize: number
  },
  params: RequestParams = {}
) => {
  return baseRequest.request<ResponsesPaginatedWorkflowResponse>({
    url: `/api/v1/workflow/paginated`,
    method: 'GET',
    params: query,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new workflow or updates an existing one with provided details.
 *
 * @tags Workflow
 * @name V1WorkflowPublishCreate
 * @summary Create or update workflow
 * @request POST:/api/v1/workflow/publish
 */
export const v1WorkflowPublishCreate = (request: RequestsSaveRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/publish`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Restores a workflow to published status and deprecates other workflows for the same agent.
 *
 * @tags Workflow
 * @name V1WorkflowRestoreCreate
 * @summary Restore a workflow
 * @request POST:/api/v1/workflow/restore
 */
export const v1WorkflowRestoreCreate = (request: RequestsRestoreRequest, params: RequestParams = {}) => {
  return baseRequest.request<void>({
    url: `/api/v1/workflow/restore`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
} /**
 * @description Creates a new workflow or updates an existing one with provided details.
 *
 * @tags Workflow
 * @name V1WorkflowSaveCreate
 * @summary Create or update workflow
 * @request POST:/api/v1/workflow/save
 */
export const v1WorkflowSaveCreate = (request: RequestsSaveRequest, params: RequestParams = {}) => {
  return baseRequest.request<ResponsesWorkflowResponse>({
    url: `/api/v1/workflow/save`,
    method: 'POST',
    data: request,
    headers: { 'Content-Type': ContentType.Json },
    ...params,
  })
}
