import React, { useEffect, useMemo, useState } from 'react'
import { Tree, TreeProps } from 'antd'
import { DataNode } from 'antd/lib/tree'
import { observer } from 'mobx-react'
import { Permission, PermissionType } from 'stores/models/permission'

export interface PermissionTreeData {
  id: number
  organization_type: string
  name: string
  identity: string
  disabled: boolean
  disableCheckbox: boolean
  children?: PermissionTreeData[]
}

export const convertPermissionTreeData = (
  permissions: Permission[],
  options: {
    disabled?: boolean
    disableCheckbox?: boolean
    checkedKeys?: string[]
  }
): PermissionTreeData[] => {
  const { disabled = false, disableCheckbox = false } = options
  return permissions.map((permission) => {
    return {
      id: permission.id,
      organization_type: permission.organization_type,
      name: permission.name,
      identity: permission.identity,
      disabled: disabled,
      disableCheckbox: disableCheckbox,
      children: permission.subs
        ? convertPermissionTreeData(permission.subs, options)
        : [],
    }
  })
}

// Function to convert the permission data into the format required by the Ant Design Tree component
const convertPermissionsToTreeData = (
  permissions: PermissionTreeData[]
): DataNode[] => {
  return permissions.map((permission) => ({
    title: permission.name,
    key: permission.identity,
    disabled: permission.disabled,
    disableCheckbox: permission.disableCheckbox,
    children: permission.children
      ? convertPermissionsToTreeData(permission.children)
      : [],
  }))
}

interface PermissionTreeType {
  permissions: PermissionTreeData[]
  defaultExpandAll?: boolean
  checkedKeys?: string[]
  onChange?: (checkedKeys: string[]) => void
}

// React component that uses the Ant Design Tree component
const PermissionsTree: React.FC<PermissionTreeType> = ({
  permissions,
  defaultExpandAll = false,
  checkedKeys = [],
  onChange,
}) => {
  const [newCheckedKeys, setNewCheckedKeys] = useState<React.Key[]>(checkedKeys)
  const treeData = useMemo(
    () => convertPermissionsToTreeData(permissions),
    [permissions]
  )

  useEffect(() => {
    setNewCheckedKeys(checkedKeys)
  }, [JSON.stringify(checkedKeys)])

  const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
    setNewCheckedKeys(checkedKeysValue as React.Key[])
    onChange?.(checkedKeysValue as PermissionType[])
  }

  return (
    <Tree
      checkable
      selectable={false}
      defaultExpandAll={defaultExpandAll}
      checkedKeys={newCheckedKeys}
      treeData={treeData}
      onCheck={onCheck}
    />
  )
}

export default observer(PermissionsTree)
