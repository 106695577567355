import React, { memo } from 'react'
import cls from 'classnames'

import styles from './index.scss'

export type CustomModalType = Partial<{
  header: React.ReactNode
  children: React.ReactNode
  footer: React.ReactNode

  wrapperClassName: string
  contentWrapperClassName: string
}>

const CustomModal = (props: CustomModalType) => {
  const {
    children,
    header,
    footer,
    wrapperClassName,
    contentWrapperClassName,
  } = props

  return (
    <div className={cls(wrapperClassName, styles.customModalWrapper)}>
      {/* header */}
      {header}

      {/* content */}
      <div className={cls(contentWrapperClassName, styles.customModalContent)}>
        {children}
      </div>

      {/* footer */}
      {footer}
    </div>
  )
}

export default memo(CustomModal)
