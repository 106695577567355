import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button, Form, Input, Typography, Divider } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { IntentBranch, IntentSample, OutputField } from '../../model'
import DynamicForm from 'components/dynamic-form'
import DynamicFormRendering from 'components/dynamic-form-rendering'

const { TextArea } = Input
const { Text } = Typography

interface IntentSettingsModalProps {
  visible: boolean
  intent: IntentBranch | null
  onSave: (
    intent: IntentBranch,
    samples: IntentSample[],
    outputVariables: OutputField[]
  ) => void
  onCancel: () => void
}

const IntentSettingsModal = (props: IntentSettingsModalProps) => {
  const { visible, intent, onSave, onCancel } = props
  const [form] = Form.useForm()
  const dynamicFormRef = useRef<any>(null)
  const [outputVariables, setOutputVariables] = useState<OutputField[]>([])
  const [samples, setSamples] = useState<
    { key: string; question: string; variables: any[] }[]
  >([])
  const [error, setError] = useState<string | null>(null)
  const dynamicFormRefs = useRef<any[]>([])

  useEffect(() => {
    if (!intent) return
    setOutputVariables(intent.output || [])
    if (Array.isArray(intent.samples)) {
      setSamples(
        intent.samples.map((sample: IntentSample, index: number) => ({
          key: `${Date.now()}_${index}`,
          question: sample.question,
          variables: sample.variables,
        }))
      )
    } else {
      setSamples([])
    }
  }, [intent])

  const handleSave = async () => {
    try {
      await form.validateFields()

      // Handle output variables
      let newOutputVariables: OutputField[] = []
      if (dynamicFormRef.current) {
        const dynamicFields = dynamicFormRef.current.getFieldsValue()
        const validationErrors = dynamicFormRef.current.validateFields()
        if (validationErrors.length > 0) {
          console.error('Output Variables validation failed:', validationErrors)
          return
        }
        newOutputVariables = dynamicFields
      }

      // Handle samples
      const result: IntentSample[] = samples.map((sample) => {
        const sampleVariables = newOutputVariables.reduce((acc, field) => {
          acc[field.name] = sample.variables[0]?.[field.name] || ''
          return acc
        }, {} as Record<string, string>)

        return {
          question: sample.question,
          variables: [sampleVariables],
        }
      })

      // Validate samples
      if (result.length === 0) {
        setError('At least one sample is required.')
        return
      }

      if (result.some((sample) => !sample.question.trim())) {
        setError('All samples must have a question.')
        return
      }

      // Save the intent
      if (intent) {
        onSave(intent, result, newOutputVariables)

        // Update local state
        setSamples(
          result.map((sample, index) => ({
            key: `${Date.now()}_${index}`,
            question: sample.question,
            variables: sample.variables,
          }))
        )
        setOutputVariables(newOutputVariables)
      }

      setError(null)
    } catch (error) {
      console.error('Validation failed:', error)
      setError('Please check all fields and try again.')
    }
  }

  const handleAddSample = () => {
    if (samples.length < 20) {
      setSamples([
        ...samples,
        {
          key: `${Date.now()}_${samples.length}`,
          question: '',
          variables: [{}],
        },
      ])
      form.resetFields(['sampleValidator'])
      setError(null)
    } else {
      setError('You can add up to 20 samples only.')
    }
  }

  const handleSampleChange = (index: number, field: string, value: any) => {
    const updatedSamples = [...samples]
    if (field === 'question') {
      updatedSamples[index].question = value
    } else {
      updatedSamples[index].variables[0] = {
        ...updatedSamples[index].variables[0],
        ...value,
      }
    }
    setSamples(updatedSamples)
  }

  const handleDeleteSample = (index: number) => {
    const updatedSamples = samples.filter((_, i) => i !== index)
    setSamples(updatedSamples)
    dynamicFormRefs.current = dynamicFormRefs.current.filter(
      (_, i) => i !== index
    )
    setError(null)
  }

  const handleOutputVariablesChange = (fields: OutputField[]) => {
    setOutputVariables(fields)
    // Update samples to reflect changes in output variables
    const updatedSamples = samples.map((sample) => ({
      ...sample,
      variables: [
        fields.reduce(
          (acc, field) => ({
            ...acc,
            [field.name]: sample.variables[0][field.name] || '',
          }),
          {}
        ),
      ],
    }))
    setSamples(updatedSamples)
  }

  return (
    <Modal
      title="Intent Settings"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      width={800}
      key={intent?.intent}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Output Variables">
          <DynamicForm
            ref={dynamicFormRef}
            maxLevel={3}
            allowObject={false}
            allowArray={true}
            value={outputVariables}
            onChange={handleOutputVariablesChange}
          />
        </Form.Item>

        <Divider />

        {samples.map((sample, index) => (
          <div key={sample.key} style={{ marginBottom: 24 }}>
            <Form.Item
              label={`Sample ${index + 1}`}
              required
              validateStatus={sample.question ? '' : 'error'}
              help={sample.question ? '' : 'Question is required'}
            >
              <TextArea
                value={sample.question}
                onChange={(e) =>
                  handleSampleChange(index, 'question', e.target.value)
                }
                placeholder="Enter sample question"
                autoSize
              />
            </Form.Item>
            {outputVariables.length > 0 && (
              <DynamicFormRendering
                ref={(el) => (dynamicFormRefs.current[index] = el)}
                fields={outputVariables}
                initialValues={sample.variables[0]}
                onChange={(values) =>
                  handleSampleChange(index, 'variables', values)
                }
              />
            )}
            <Button
              onClick={() => handleDeleteSample(index)}
              style={{ marginTop: 8, width: '100%' }}
              danger
              icon={<MinusCircleOutlined />}
            >
              Delete Sample
            </Button>
          </div>
        ))}
        <Button
          type="dashed"
          onClick={handleAddSample}
          block
          style={{ marginBottom: 24 }}
          icon={<PlusOutlined />}
        >
          Add Sample
        </Button>
        {error && (
          <Form.Item>
            <Text type="danger">{error}</Text>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default IntentSettingsModal
