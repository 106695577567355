import React, { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import {
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'

export const MAX_OPENING_QUESTIONS_LEN = 10

const OpeningQuestions: React.FC<{
  value?: string[]
  onChange?: (value: string[]) => void
}> = (props) => {
  const [openingQuestions, setOpeningQuestions] = useState<string[]>([])

  useEffect(() => {
    setOpeningQuestions(props.value || [])
  }, [props.value])

  const onChange = (questions: string[]) => {
    props.onChange?.(questions)
  }

  const addOpeningQuestion = () => {
    if (openingQuestions.length < MAX_OPENING_QUESTIONS_LEN) {
      const newQuestions = [...openingQuestions, '']
      setOpeningQuestions(newQuestions)
      onChange(newQuestions)
    }
  }

  const removeOpeningQuestion = (index: number) => {
    const newQuestions = openingQuestions.filter((_, i) => i !== index)
    setOpeningQuestions(newQuestions)
    onChange(newQuestions)
  }

  const updateOpeningQuestion = (index: number, value: string) => {
    const newQuestions = [...openingQuestions]
    newQuestions[index] = value
    setOpeningQuestions(newQuestions)
    onChange(newQuestions)
  }

  return (
    <>
      {openingQuestions.map((question, index) => (
        <div key={index} className="question-input">
          <Input
            value={question}
            onChange={(e) => updateOpeningQuestion(index, e.target.value)}
            placeholder={`Question ${index + 1}`}
          />
          <Button
            type="text"
            icon={<MinusCircleOutlined />}
            onClick={() => removeOpeningQuestion(index)}
          />
        </div>
      ))}
      {openingQuestions.length < MAX_OPENING_QUESTIONS_LEN && (
        <Button
          type="dashed"
          onClick={addOpeningQuestion}
          block
          icon={<PlusOutlined />}
        >
          Add Question
        </Button>
      )}
    </>
  )
}

export default OpeningQuestions
