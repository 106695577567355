import React from 'react';
import { Tree, Space, Button } from 'antd';
import { MinusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { OutputField } from '../../model';

const { TreeNode } = Tree;

const renderTreeNodes = (
  data: OutputField[], 
  onRemove?: (field: OutputField) => void,
  onEdit?: (field: OutputField) => void
) => {
  return data.map((item) => {
    const title = (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Space>
          <span>{item.name}</span>
          <span style={{ color: '#888' }}>[{item.type}]</span>
        </Space>
        <Space>
          {onEdit && (
            <Button 
              type="text" 
              size="small" 
              icon={<EditOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(item);
              }}
            />
          )}
          {onRemove && (
            <Button 
              type="text" 
              size="small" 
              danger
              icon={<MinusCircleOutlined />} 
              onClick={(e) => {
                e.stopPropagation();
                onRemove(item);
              }}
            />
          )}
        </Space>
      </div>
    );

    if (item.children && item.children.length) {
      return (
        <TreeNode title={title} key={item.name}>
          {renderTreeNodes(item.children, onRemove, onEdit)}
        </TreeNode>
      );
    }
    return <TreeNode title={title} key={item.name} />;
  });
};

export default renderTreeNodes;