import { AxiosError } from 'axios'
import { notification } from 'components/antd-static-function'

const TIME_OUT: number = 10 * 1000

const GLOBAL_INTERCEPTORS = {
  REQUEST_INTERCEPTOR: (config: any) => {
    return config
  },

  REQUEST_INTERCEPTOR_CATCH: (err: any) => {
    return Promise.reject(err)
  },

  RESPONSE_INTERCEPTOR: (res: any) => {
    return res
  },

  RESPONSE_INTERCEPTOR_CATCH: (err: any) => {
    return Promise.reject(err)
  },
}

const INSTANCE_INTERCEPTORS = {
  requestInterceptor: (config: any) => {
    return config
  },

  requestInterceptorCatch: (err: any) => {
    return Promise.reject(err)
  },

  responseInterceptor: (res: any) => {
    return res
  },

  responseInterceptorCatch: (err: AxiosError<any>) => {
    const { name, code, config, status, response } = err
    const { data } = response ?? {}

    if (code !== `200`) {
      notification.error({
        message: `${name}: ${status}`,
        description: (
          <>
            <div>{code}</div>
            <div>URL: {config?.url}</div>
            <div>{data?.message}</div>
          </>
        ),
      })
    }

    return Promise.reject(err)
  },
}

export { TIME_OUT, INSTANCE_INTERCEPTORS, GLOBAL_INTERCEPTORS }
