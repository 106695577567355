import React, { useState } from 'react';
import { Button } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';

interface SectionProps {
  title: string;
  children: React.ReactNode;
  defaultCollapsed?: boolean;
}

const Section: React.FC<SectionProps> = ({ title, children, defaultCollapsed = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  return (
    <div className="custom-section">
      <div className="section-header" onClick={() => setIsCollapsed(!isCollapsed)}>
        <h3>{title}</h3>
        <Button 
          type="text" 
          icon={isCollapsed ? <RightOutlined /> : <DownOutlined />} 
        />
      </div>
      {!isCollapsed && <div className="section-content">{children}</div>}
    </div>
  );
};

export default Section;