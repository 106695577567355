import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from 'config'
import { ErrorCodes } from 'constants/error-codes'
import { ApiEndpoint } from 'services/api'
import { CodeMsgResponse } from 'stores/models/common'
import userStore from 'stores/user'
import {normalizePath} from 'utils/common'

export interface RequestData {
  body?: any
  queryParams?: any
}

interface SSEMessage {
  id?: string
  event?: string
  data: string
  retry?: number
}

class SSEParser {
  private buffer: string = ''
  private decoder: TextDecoder = new TextDecoder()

  parseChunk(chunk: Uint8Array): SSEMessage[] {
    this.buffer += this.decoder.decode(chunk, { stream: true })
    const messages: SSEMessage[] = []
    let messageStart = 0

    while (true) {
      const messageEnd = this.buffer.indexOf('\n\n', messageStart)
      if (messageEnd === -1) break

      const messageContent = this.buffer.slice(messageStart, messageEnd)
      const message = this.parseMessage(messageContent)
      if (message) messages.push(message)

      messageStart = messageEnd + 2
    }

    this.buffer = this.buffer.slice(messageStart)
    return messages
  }

  private parseMessage(content: string): SSEMessage | null {
    const lines = content.split('\n')
    const message: SSEMessage = { data: '' }

    for (const line of lines) {
      if (line.startsWith(':')) continue // Comment, ignore

      const colonIndex = line.indexOf(':')
      if (colonIndex === -1) continue // Invalid line, ignore

      const field = line.slice(0, colonIndex)
      let value = line.slice(colonIndex + 1).trim()

      if (colonIndex === 0) {
        value = line.slice(1).trim() // The line started with ':', so no field name
      }

      switch (field) {
        case 'event':
          message.event = value
          break
        case 'data':
          message.data += value ? (message.data ? '\n' + value : value) : ''
          break
        case 'id':
          message.id = value
          break
        case 'retry':
          const retry = parseInt(value, 10)
          if (!isNaN(retry)) message.retry = retry
          break
      }
    }

    return message.data ? message : null
  }

  flush(): SSEMessage | null {
    if (!this.buffer) return null
    const message = this.parseMessage(this.buffer)
    this.buffer = ''
    return message
  }
}

export interface StreamingRequestOptions {
  onMessage: (data: any) => void
  onError?: (error: Error | Event) => void
  onComplete?: () => void
}

export interface StreamingApiRequestConfig extends StreamingRequestOptions {
  body?: any
  queryParams?: Record<string, string | number | boolean>
}

export interface StreamingApiRequestController {
  abort: () => void
}

const createStreamingApiRequest = (
  api: ApiEndpoint,
  requestConfig: StreamingApiRequestConfig
): Promise<void> => {
  const { body, onMessage, onError, onComplete, queryParams } = requestConfig
  const url = new URL(normalizePath(config.API_GATEWAY, api.url))
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value.toString())
    })
  }

  const abortController = new AbortController()

  return fetch(url.toString(), {
    method: api.method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
    },
    body: body ? JSON.stringify(body) : undefined,
    credentials: 'include',
    signal: abortController.signal,
  })
    .then((response) => {
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`)
      if (!response.body) throw new Error('ReadableStream not supported')

      const reader = response.body.getReader()
      const parser = new SSEParser()

      return new Promise<void>((resolve, reject) => {
        function read() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                const lastMessage = parser.flush()
                if (lastMessage) processMessage(lastMessage)
                onComplete?.()
                resolve()
                return
              }

              const messages = parser.parseChunk(value)
              messages.forEach(processMessage)

              read()
            })
            .catch((error) => {
              if (error.name === 'AbortError') {
                console.log('Fetch aborted')
              } else {
                onError?.(error)
                reject(error)
              }
            })
        }

        function processMessage(message: SSEMessage) {
          if (message.data) {
            try {
              const data = JSON.parse(message.data)
              onMessage(data)
            } catch (e) {
              console.error('Error parsing JSON:', e, 'Raw data:', message.data)
            }
          }
        }

        read()
      })
    })
    .catch((error) => {
      if (error.name !== 'AbortError') {
        onError?.(error)
      }
      throw error
    })
}

const handleErrorResponse = (
  response: AxiosResponse & { data: CodeMsgResponse }
) => {
  if (response.status === 403) {
    return Promise.reject(new Error(response.data.message || 'Forbidden'))
  } else if (
    response.status === 401 &&
    (response.data.code === ErrorCodes.AuthenticationRequired ||
      response.data.code === ErrorCodes.InvalidToken)
  ) {
    userStore.setUser(null)
    window.location.pathname = '/login'
    return Promise.reject(new Error(response.data.message || 'Unauthorized'))
  }
}

const createApiRequest = async <T = any,>(
  api: ApiEndpoint,
  data?: RequestData
): Promise<AxiosResponse<T>> => {
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
  }
  const timeout = 200000
  const baseURL = config.API_GATEWAY

  const instance = axios.create({
    baseURL,
    headers,
    timeout,
    withCredentials: true,
  })

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error
      if (response) {
        const ret = handleErrorResponse(response)
        if (ret) return ret
      }
      return Promise.reject(error)
    }
  )

  switch (api.method.toLowerCase()) {
    case 'get':
    case 'delete':
      return instance({
        method: api.method,
        url: api.url,
        params: data?.queryParams,
      })
    case 'post':
    case 'patch':
    case 'put':
      return instance({
        method: api.method,
        url: api.url,
        data: data?.body,
        params: data?.queryParams,
      })
    default:
      throw new Error(`Unsupported method ${api.method}`)
  }
}

export { createApiRequest, createStreamingApiRequest }
