import React, { useRef } from 'react'
import { Button, Form, Input, message, Modal, Select, Switch } from 'antd'
import { MailOutlined, UserOutlined, LockOutlined } from '@ant-design/icons'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import userStore from 'stores/user'
import roleStore from 'stores/role'
import { getMessageFromError, isEmpty } from 'utils/common'
import { observer } from 'mobx-react'
import { User, UserStatus, UserStatusOptions } from 'stores/models/user'
import PasswordFormItem, {
  PasswordFormItemRefProps,
} from 'components/password-form-item'

export interface UserModalProps {
  data: {
    id?: number
    organizationId: number
    roleId?: number
    name: string
    email: string
    password?: string
    status: UserStatus
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (userId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const UserModal: React.FC<UserModalProps> = (props) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const passwordFormItemRef = useRef<PasswordFormItemRefProps>(null)
  const isEditUser = useMemo(
    () => props.data.password !== undefined,
    [props.data.password]
  )

  const isAdmin = useMemo(
    () => userStore.loginUser?.roleName.toLowerCase() === 'admin',
    [userStore.loginUser]
  )

  useEffect(() => {
    if (props.open) {
      form.setFieldsValue(props.data)
      passwordFormItemRef.current?.reset()
    }
  }, [props.open, form, props.data])

  const handleSave = async (values: UserModalProps['data']) => {
    setConfirmLoading(true)
    try {
      if (isEditUser && isEmpty(values.password)) {
        throw new Error('Password is required!')
      }
      const roleId = values.roleId ?? 0
      if (props.data.id == undefined) {
        const response = await userStore.createUser(
          values.name,
          values.email,
          roleId,
          props.data.organizationId
        )
        const data = response.data as User
        if (passwordFormItemRef.current?.isPasswordChanged()) {
          await userStore.resetPassword(data.id, values.password ?? '')
        }
        message.success('User created successfully!')
        return data.id
      } else {
        if (passwordFormItemRef.current?.isPasswordChanged()) {
          await userStore.resetPassword(props.data.id, values.password ?? '')
        }
        if (values.status !== props.data.status) {
          await userStore.updateStatus(props.data.id, values.status)
        }
        message.success('User updated successfully!')
      }
      return props.data.id
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: UserModalProps['data']) => {
    const userId = await handleSave(values)
    if (userId === undefined) {
      return
    }
    if (props.onFinish) {
      props.onFinish(userId)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  return (
    <Modal
      title={props.title ?? 'User Edit'}
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 620 }}
        initialValues={{
          roleId: props.data.roleId,
          name: props.data.name,
          email: props.data.email,
          status: props.data.status,
          password: props.data.password,
        }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input an email!' },
            {
              type: 'email',
              message: 'The input is not valid e-mail!',
            },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="roleId"
          label="Role"
          rules={[{ required: true, message: 'Please select a role!' }]}
        >
          <Select
            options={roleStore.roleList.roles.map((role) => {
              return { value: role.id, label: role.name }
            })}
            disabled={!isAdmin}
            style={{ textTransform: 'capitalize' }}
          />
        </Form.Item>
        {isEditUser && (
          <PasswordFormItem
            ref={passwordFormItemRef}
            form={form}
            password={props.data.password}
          />
        )}
        {isEditUser && (
        <Form.Item name="status" label="Status">
          <Select
            options={UserStatusOptions}
            style={{ textTransform: 'capitalize' }}
            disabled={!isAdmin}
          />
        </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            {props.okText || 'Ok'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(UserModal)
