import React, { useState, useEffect, MouseEventHandler } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Pagination,
  Skeleton,
  PaginationProps,
  App,
  Empty,
  Flex,
} from 'antd'
import userStore from 'stores/user'
import knowledgeStore from 'stores/knowledge'
import KnowledgeCard from './knowledge-card'
import { observer } from 'mobx-react'
import { getMessageFromError } from 'utils/common'
import KnowledgeModal from './knowledge-modal'
import { useExNavigate } from 'hooks/use-ex-navigate'
import './index.css'

const Knowledge: React.FC = () => {
  const navigate = useExNavigate()
  const { message, modal } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isKnowledgeModalOpen, setIsKnowledgeModalOpen] = useState(false)

  const load = async (page: number, size: number) => {
    setLoading(true)
    let organizationId: number = userStore.organizationId
    try {
      await knowledgeStore.getList(page, size)
      setTotal(knowledgeStore.knowledgeList.totalCount)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  const onChange: PaginationProps['onChange'] = (pageNumber: number) => {
    setPage(pageNumber)
    load(page, size)
  }

  const handleCreateFinish = (knowledgeId: number) => {
    setIsKnowledgeModalOpen(false)
    navigate('/portal/knowledge/document/upload', {
      state: { knowledge: { id: knowledgeId } },
    })
  }

  const handleCreateKnowledgeClick: MouseEventHandler<HTMLElement> = (e) => {
    setIsKnowledgeModalOpen(true)
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>Knowledge</h1>
            <p>
              Import your own text data or write data in real-time via Webhook
              for LLM context enhancement.
            </p>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreateKnowledgeClick}
            >
              Create Knowledge
            </Button>
          </div>
        </div>
        <div className="knowledge-list" style={{ gap: '20px' }}>
          <Skeleton loading={loading} active>
            {knowledgeStore.knowledgeList.knowledges.length > 0 ? (
              knowledgeStore.knowledgeList.knowledges.map((item, i) => (
                <KnowledgeCard
                  key={i}
                  data={{
                    id: item.id,
                    name: item.knowledgeName,
                    description: item.description,
                    documentCount: item.documentCount,
                    documentSize: item.documentSize,
                  }}
                />
              ))
            ) : (
              <Flex justify="start" style={{ width: '100%' }}>
                <Empty />
              </Flex>
            )}
          </Skeleton>
        </div>
        <div className="pagination">
          {!loading ? (
            <Pagination
              defaultCurrent={page}
              defaultPageSize={size}
              total={total}
              onChange={onChange}
            />
          ) : null}
        </div>
        <KnowledgeModal
          open={isKnowledgeModalOpen}
          okText="Next"
          data={{ name: '', description: '' }}
          onFinish={handleCreateFinish}
          onCancel={() => setIsKnowledgeModalOpen(false)}
        />
      </div>
    </>
  )
}

export default observer(Knowledge)
