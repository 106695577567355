import React, { useEffect, useState } from 'react'
import { Handle, Position, NodeProps, useReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { BranchesOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  OutputField,
  InputField,
  CustomNodeProps,
  NodeData,
} from 'views/portal/agent/studio/workflow/model'
import { defaultBranches } from './sidebar'
import DeleteButton from '../../components/delete-button'
import CustomHandle from '../../components/custom-handle'
import { getNewNodeLabel } from '../../utils'
import styles from './index.scss'

// Define default values
const defaultInputs: InputField[] = []

const defaultOutputs: OutputField[] = []

const getDefaultNodeData = (label: string): NodeData => ({
  label,
  description: 'Define the branches for differential processing',
  input: defaultInputs,
  output: defaultOutputs,
  intentBranch: [],
  conditionBranch: [...defaultBranches],
  branchOutput: {},
})

const ConditionNode: React.FC<CustomNodeProps> = (node) => {
  const { getNodes } = useReactFlow()
  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || getDefaultNodeData('Logic Condition')
  )

  useEffect(() => {
    if (!node.data.data) {
      const newLabel = getNewNodeLabel(getNodes(), 'Logic Condition')
      const newNodeData = getDefaultNodeData(newLabel)
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else if (node.data.data !== nodeData) {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? `custom-node ${styles.conditionNode} active`
          : `custom-node ${styles.conditionNode}`
      }
    >
      <div className="node-title">
        <div className="icon">
          <BranchesOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-conditions">
        {nodeData.conditionBranch.map((branch, index) => (
          <div key={`condition-${index}`} className="condition-row">
            <div className="condition-name">{branch.description}</div>
            <CustomHandle
              node={node}
              type="source"
              position={Position.Right}
              id={
                branch.priority === -1
                  ? 'branch_else'
                  : branch.priority === -2
                    ? 'branch_error'
                    : `branch_${branch.priority}`
              }
              style={{
                background: branch.isElse
                  ? '#ffa500'
                  : branch.isError
                    ? '#ff0000'
                    : '#1a192b',
              }}
              isConnectable={node.isConnectable}
            />
          </div>
        ))}
      </div>
      <DeleteButton nodeID={node.id} />
    </div>
  )
}

export default observer(ConditionNode)
