import React, { useEffect, useState } from 'react'
import { Handle, Position, useReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { ApiOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  OutputField,
  InputField,
  CustomNodeProps,
  NodeData,
  APIData,
  HTTPMethod,
  HTTPRequestType,
} from 'views/portal/agent/studio/workflow/model'
import DeleteButton from '../../components/delete-button'
import CustomHandle from '../../components/custom-handle'
import { getNewNodeLabel } from '../../utils'

// Define default values
const defaultInputs: InputField[] = []

const defaultOutputs: OutputField[] = []

const defaultAPIData: APIData = {
  timeout: 10,
  url: 'https://',
  htmlFilter: false,
  cache: false,
  cacheTimeout: 10,
  method: 'GET' as HTTPMethod,
  type: 'restapi' as HTTPRequestType,
  requestHeader: [],
  requestBody: [],
  queryParameters: [],
  pathParameters: [],
}

const getDefaultNodeData = (label: string): NodeData => ({
  label,
  description: 'Make an HTTP request',
  input: defaultInputs,
  output: defaultOutputs,
  intentBranch: [],
  conditionBranch: [],
  branchOutput: {},
  extends: defaultAPIData,
})

const HTTPRequestNode: React.FC<CustomNodeProps> = observer((node) => {
  const { getNodes } = useReactFlow()

  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || getDefaultNodeData('HTTP Request')
  )

  useEffect(() => {
    if (!node.data.data) {
      const newLabel = getNewNodeLabel(getNodes(), 'HTTPRequest')
      const newNodeData = getDefaultNodeData(newLabel)
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else if (node.data.data.label !== nodeData.label) {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  useEffect(() => {
    // Update output based on the request type
    if (nodeData.extends.type === 'webpage') {
      const webpageOutput: OutputField[] = [
        {
          name: 'text',
          type: 'String',
          description: 'Webpage content',
          required: true,
          children: [],
        },
      ]
      setNodeData((prevData) => ({
        ...prevData,
        output: webpageOutput,
      }))
    } else {
      // For 'restapi', output remains empty to allow user customization
      setNodeData((prevData) => ({
        ...prevData,
        output: [],
      }))
    }
  }, [nodeData.extends.type])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node http-request-node active'
          : 'custom-node http-request-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <ApiOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <DeleteButton nodeID={node.id} />
      <CustomHandle
        node={node}
        type="source"
        position={Position.Right}
        isConnectable={node.isConnectable}
      />
    </div>
  )
})

export default HTTPRequestNode
