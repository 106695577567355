import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import {
  IntentList,
  IntentParameter,
  IntentExample,
  Intent,
} from './models/intent'
import { CodeMsgResponse } from './models/common'

class IntentStore {
  intentList: IntentList = {
    totalCount: 0,
    intents: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  convertExample(example: IntentExample) {
    return {
      example: example.example,
      parameters: JSON.stringify(example.parameters),
    }
  }

  async create(
    intent: string,
    description: string,
    examples: IntentExample[],
    parameters: IntentParameter[]
  ) {
    const params: RequestData = {
      body: {
        intent,
        description,
        examples: examples.map(this.convertExample),
        parameters,
      },
    }
    const response = await createApiRequest<Intent | CodeMsgResponse>(
      apiConfig.intentCreate,
      params
    )
    runInAction(() => {
      this.intentList.intents.push(response.data as Intent)
    })
    return response
  }

  async update(
    id: number,
    intent: string,
    description: string,
    examples: IntentExample[],
    parameters: IntentParameter[]
  ) {
    const params: RequestData = {
      body: {
        id,
        intent,
        description,
        examples: examples.map(this.convertExample),
        parameters,
      },
    }

    const response = await createApiRequest<Intent | CodeMsgResponse>(
      apiConfig.intentUpdate,
      params
    )

    const index = this.intentList.intents.findIndex(
      (intent) => intent.id === id
    )
    if (index !== -1) {
      this.intentList.intents[index] = {
        ...this.intentList.intents[index],
        ...params.body,
      }
    }

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest<CodeMsgResponse>(
      apiConfig.intentDelete,
      {
        queryParams: { id },
      }
    )
    runInAction(() => {
      this.intentList = {
        totalCount: this.intentList.totalCount - 1,
        intents: this.intentList.intents.filter((intent) => intent.id !== id),
      }
    })
    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        size,
      },
    }

    const response = await createApiRequest<IntentList | CodeMsgResponse>(
      apiConfig.intentList,
      params
    )

    runInAction(() => {
      const data = response.data as IntentList
      if (data) {
        this.intentList = {
          totalCount: data.totalCount,
          intents: data.intents,
        }
      }
    })

    return response
  }
}

export default new IntentStore()
