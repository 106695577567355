import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useEffect,
} from 'react'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Space,
  Tooltip,
  Row,
  Col,
  Typography,
  Select,
  InputProps,
} from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  DataTypes,
  OutputField,
} from 'views/portal/agent/studio/workflow/model'
import { observer } from 'mobx-react'

export type Field = OutputField

const { Text } = Typography
const { Option } = Select

export interface DynamicFormRefProps {
  resetFields: () => void
  getFieldsValue: () => Field[]
  validateFields: () => string[]
}

interface DynamicFormProps {
  maxLevel?: number
  allowObject?: boolean
  allowArray?: boolean
  value?: Field[]
  onChange?: (fields: Field[]) => void

  size?: 'default' | 'small'
  warningLabels?: any[]
}

const DynamicForm: ForwardRefRenderFunction<
  DynamicFormRefProps,
  DynamicFormProps
> = (
  {
    maxLevel = 3,
    allowObject = true,
    allowArray = true,
    value = [],
    onChange,
    size = 'default',
    warningLabels = [],
  },
  ref
) => {
  const [fields, setFields] = useState<Field[]>(value)
  useEffect(() => {
    setFields(value)
  }, [value])
  useImperativeHandle(ref, () => ({
    resetFields: () => setFields(value),
    getFieldsValue: () => fields,
    validateFields: () => validateFields(fields),
  }))

  const updateFieldRecursively = (
    fields: Field[],
    indices: number[],
    key: keyof Field,
    value: string | boolean
  ): Field[] => {
    const [currentIndex, ...restIndices] = indices

    if (restIndices.length === 0) {
      fields[currentIndex][key] = value as never
    } else {
      fields[currentIndex].children = updateFieldRecursively(
        fields[currentIndex].children,
        restIndices,
        key,
        value
      )
    }

    return fields
  }

  const handleInputChange = (
    value: string | boolean,
    indices: number[],
    key: keyof Field
  ) => {
    const newFields = updateFieldRecursively([...fields], indices, key, value)
    if (key === 'type') {
      const [currentIndex, ...restIndices] = indices
      let currentField = fields[currentIndex]
      restIndices.forEach((i) => {
        currentField = currentField.children[i]
      })

      if (value !== 'Object' && value !== 'Array<Object>') {
        currentField.children = []
      }
    }

    setFields(newFields)
    if (onChange) {
      onChange(newFields)
    }
  }

  const addFieldRecursively = (fields: Field[], indices: number[]): Field[] => {
    const [currentIndex, ...restIndices] = indices

    if (restIndices.length === 0) {
      fields[currentIndex].children.push({
        name: '',
        type: 'String',
        description: '',
        required: false,
        children: [],
        expanded: true,
      })
    } else {
      fields[currentIndex].children = addFieldRecursively(
        fields[currentIndex].children,
        restIndices
      )
    }

    return fields
  }

  const addField = (indices: number[]) => {
    const newField: Field = {
      name: '',
      type: 'String',
      description: '',
      required: false,
      children: [],
      expanded: true,
    }

    const newFields =
      indices.length === 0
        ? [...fields, newField]
        : addFieldRecursively([...fields], indices)

    setFields(newFields)
    if (onChange) {
      onChange(newFields)
    }
  }

  const removeFieldRecursively = (
    fields: Field[],
    indices: number[]
  ): Field[] => {
    const [currentIndex, ...restIndices] = indices

    if (restIndices.length === 0) {
      fields.splice(currentIndex, 1)
    } else {
      fields[currentIndex].children = removeFieldRecursively(
        fields[currentIndex].children,
        restIndices
      )
    }

    return fields
  }

  const removeField = (indices: number[]) => {
    const newFields = removeFieldRecursively([...fields], indices)
    setFields(newFields)
    if (onChange) {
      onChange(newFields)
    }
  }

  const toggleExpandRecursively = (
    fields: Field[],
    indices: number[]
  ): Field[] => {
    const [currentIndex, ...restIndices] = indices

    if (restIndices.length === 0) {
      fields[currentIndex].expanded = !fields[currentIndex].expanded
    } else {
      fields[currentIndex].children = toggleExpandRecursively(
        fields[currentIndex].children,
        restIndices
      )
    }

    return fields
  }

  const toggleExpand = (indices: number[]) => {
    const newFields = toggleExpandRecursively([...fields], indices)
    setFields(newFields)
    if (onChange) {
      onChange(newFields)
    }
  }

  const renderFields = (fields: Field[], indices: number[] = [], level = 0) => {
    return fields.map((field, index) => {
      const currentIndices = [...indices, index]
      const filteredDataTypes = DataTypes.filter((type) => {
        if (!allowObject && (type === 'Object' || type === 'Array<Object>'))
          return false
        if (!allowArray && type.startsWith('Array')) return false
        if (level >= maxLevel && type === 'Array<Object>') return false
        return true
      })

      return (
        <div key={index} style={{ marginBottom: 8 }} className="dynamic-form">
          <Row gutter={24} align="middle" style={{ marginBottom: 8 }}>
            <Col span={7}>
              <Row>
                <Col>
                  <div
                    style={{
                      paddingLeft:
                        level * 30 +
                        (field.children.length === 0 && level !== 0 ? 30 : 0),
                    }}
                  >
                    {field.children.length > 0 && (
                      <Button
                        type="link"
                        icon={
                          field.expanded ? <DownOutlined /> : <RightOutlined />
                        }
                        onClick={() => toggleExpand(currentIndices)}
                      />
                    )}
                    <Input
                      placeholder="Enter name"
                      value={field.name}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          currentIndices,
                          'name'
                        )
                      }
                      style={{
                        width:
                          size === 'small'
                            ? '100%'
                            : 200 -
                              level * 30 -
                              (field.children.length > 0 ? 0 : 0),
                      }}
                      status={
                        warningLabels.includes(field.name) ? 'error' : undefined
                      }
                    />
                  </div>
                </Col>
              </Row>
              {field.error && <Text type="danger">{field.error}</Text>}
            </Col>
            <Col span={size === 'small' ? 7 : 4}>
              <Select
                value={field.type}
                onChange={(value) =>
                  handleInputChange(value, currentIndices, 'type')
                }
                style={{ width: '100%' }}
                dropdownStyle={{ width: '180px' }}
                className="nodrag nopan"
              >
                {filteredDataTypes.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={7}>
              <Input
                placeholder="Describe what the variable is used for"
                value={field.description}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    currentIndices,
                    'description'
                  )
                }
              />
            </Col>
            {size !== 'small' && (
              <Col span={3}>
                <Checkbox
                  checked={field.required}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.checked,
                      currentIndices,
                      'required'
                    )
                  }
                />
              </Col>
            )}
            <Col span={2}>
              <Space style={{ display: 'flex' }}>
                {(field.type === 'Array<Object>' || field.type === 'Object') &&
                  level < maxLevel && (
                    <Tooltip title="Add nested field">
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => addField(currentIndices)}
                      />
                    </Tooltip>
                  )}
                <Tooltip title="Delete field">
                  <Button
                    icon={<MinusCircleOutlined />}
                    onClick={() => removeField(currentIndices)}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>
          {field.children.length > 0 &&
            field.expanded &&
            renderFields(field.children, currentIndices, level + 1)}
        </div>
      )
    })
  }

  const validateFields = (fields: Field[]): string[] => {
    const errors: string[] = []

    const validateField = (field: Field, names: Set<string>, level: number) => {
      field.error = undefined

      if (!field.name) {
        const error = `Field name is required.`
        errors.push(error)
        field.error = error
      } else if (names.has(field.name)) {
        const error = `Field name is duplicated at level ${level}.`
        errors.push(error)
        field.error = error
      } else if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(field.name)) {
        const error = `Field name is invalid. Must start with a letter or underscore and contain only letters, numbers, and underscores.`
        errors.push(error)
        field.error = error
      } else {
        names.add(field.name)
      }

      const childNames = new Set<string>()
      field.children.forEach((child) =>
        validateField(child, childNames, level + 1)
      )
    }

    const rootNames = new Set<string>()
    fields.forEach((field) => validateField(field, rootNames, 1))
    setFields([...fields])
    return errors
  }

  return (
    <>
      {renderFields(fields)}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => addField([])}
          block
          icon={<PlusOutlined />}
        >
          Add
        </Button>
      </Form.Item>
    </>
  )
}

export default observer(forwardRef(DynamicForm))
