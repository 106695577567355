import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { Button, Space, Input, List, Typography, MenuProps } from 'antd'
import { PlusCircleOutlined, FunctionOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import { DataType, OutputField, CustomNodeProps, NodeData } from '../../model'
import { ConditionBranch } from '../../model'
import Branch from './branch'
import NodeSidebar from '../../components/node-sidebar'
import styles from './sidebar.scss'

const { TextArea } = Input
const { Text } = Typography

const MAX_BRANCH = 10

export const defaultBranches = [
  {
    priority: 1,
    description: 'Branch 1',
    conditions: [
      { variable: '', condition: '', comparison: '', type: 'input' },
    ],
    logicOperator: 'AND',
  },
  {
    priority: -1,
    description: 'Branch Else',
    conditions: [
      { variable: '', condition: '', comparison: '', type: 'input' },
    ],
    logicOperator: 'AND',
    isElse: true,
  },
]

const ConditionNodeSidebar: React.FC = () => {
  const node = WorkflowStore.selectedNode
  const nodeData = { ...node?.data }

  const [branches, setBranches] = useState<ConditionBranch[]>(
    nodeData.data?.conditionBranch?.length
      ? nodeData.data.conditionBranch
      : defaultBranches
  )

  useEffect(() => {
    if (node?.data.data?.conditionBranch) {
      setBranches(node.data.data.conditionBranch)
    }
  }, [node?.data.data?.conditionBranch])

  const handleBranchChange = (branches: ConditionBranch[]) => {
    setBranches(branches)

    if (!node) {
      return
    }

    const updatedNodeData = {
      ...node.data,
      data: {
        ...node.data.data,
        conditionBranch: branches,
      },
    }

    const updatedNode = {
      ...node,
      data: updatedNodeData,
    }

    WorkflowStore.selectNode(updatedNode as CustomNodeProps)
    node.data.onChange?.(node.id, updatedNodeData)
  }

  if (node?.type !== 'Condition') {
    return null
  }

  const handleDescriptionChange = (
    branchIndex: number,
    description: string
  ) => {
    const updatedBranches = branches.map((branch, bIndex) =>
      bIndex === branchIndex
        ? {
            ...branch,
            description,
          }
        : branch
    )
    handleBranchChange(updatedBranches)
  }

  const handleConditionChange = (
    branchIndex: number,
    conditionIndex: number,
    name: string,
    value: string
  ) => {
    const updatedBranches = branches.map((branch, bIndex) =>
      bIndex === branchIndex
        ? {
            ...branch,
            conditions: branch.conditions.map((condition, cIndex) =>
              cIndex === conditionIndex
                ? { ...condition, [name]: value }
                : condition
            ),
          }
        : branch
    )
    handleBranchChange(updatedBranches)
  }

  const addCondition = (branchIndex: number) => {
    const updatedBranches = branches.map((branch, index) =>
      index === branchIndex
        ? {
            ...branch,
            conditions: [
              ...branch.conditions,
              { variable: '', condition: '', comparison: '', type: 'input' },
            ],
          }
        : branch
    )
    handleBranchChange(updatedBranches)
  }

  const removeCondition = (branchIndex: number, conditionIndex: number) => {
    if (branches[branchIndex].conditions.length <= 1) return
    const updatedBranches = branches.map((branch, index) =>
      index === branchIndex
        ? {
            ...branch,
            conditions: branch.conditions.filter(
              (_, cIndex) => cIndex !== conditionIndex
            ),
          }
        : branch
    )
    handleBranchChange(updatedBranches)
  }

  const addBranch = () => {
    const priority = branches[branches.length - 2]?.priority + 1
    const updatedBranches = [
      ...branches.filter((branch) => !branch.isElse),
      {
        priority,
        description: `Branch ${priority}`,
        conditions: [
          { variable: '', condition: '', comparison: '', type: 'input' },
        ],
        logicOperator: 'AND',
      },
      defaultBranches[1],
    ]
    handleBranchChange(updatedBranches)
  }

  const removeBranch = (branchIndex: number) => {
    const updatedBranches = [
      ...branches.filter(
        (_, index) => index !== branchIndex && !branches[index].isElse
      ),
      defaultBranches[1],
    ]
    handleBranchChange(updatedBranches)
  }

  const handleMenuClick = (branchIndex: number, key: string) => {
    const updatedBranches = branches.map((branch, bIndex) =>
      bIndex === branchIndex ? { ...branch, logicOperator: key } : branch
    )
    handleBranchChange(updatedBranches)
  }

  const getLogicMenu = (branchIndex: number): MenuProps => ({
    items: [
      {
        key: 'AND',
        label: 'AND',
      },
      {
        key: 'OR',
        label: 'OR',
      },
    ],
    onClick: ({ key }: { key: string }) => handleMenuClick(branchIndex, key),
  })

  const handleNodeDataChange = (
    field: string,
    value: any,
    dataType: DataType = 'String'
  ) => {
    if (WorkflowStore.selectedNode?.data?.data) {
      const currentNodeData = { ...nodeData?.data }
      let updatedInput = [...(currentNodeData.input || [])]
      let updatedOutput = [...(currentNodeData.output || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      }

      const updatedNodeData = {
        ...(WorkflowStore.selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          input: updatedInput,
          output: updatedOutput,
        },
      }
      const updatedNode = {
        ...WorkflowStore.selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      if (WorkflowStore.selectedNode.data.onChange) {
        WorkflowStore.selectedNode.data.onChange(
          WorkflowStore.selectedNode.id,
          updatedNodeData
        )
      }
    }
  }

  const handleClose = () => {
    WorkflowStore.selectNode(null)
  }

  return (
    <NodeSidebar
      nodeType={'condition'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={nodeData?.data?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>
      <Section title="Condition Branches" defaultCollapsed={false}>
        <div className={styles.conditionBranches}>
          {branches
            .filter((branch) => !branch.isElse)
            .map((branch, branchIndex) => (
              <Branch
                key={branchIndex}
                branch={branch}
                branchIndex={branchIndex}
                handleDescriptionChange={handleDescriptionChange}
                handleConditionChange={handleConditionChange}
                removeCondition={removeCondition}
                addCondition={addCondition}
                removeBranch={removeBranch}
                getLogicMenu={getLogicMenu}
              />
            ))}
          <div className="branch-section branch-else">
            <label>ELSE</label>
          </div>
          <Button
            type="dashed"
            onClick={addBranch}
            icon={<PlusCircleOutlined />}
            style={{ width: '100%' }}
            disabled={branches.length >= MAX_BRANCH}
          >
            Add Branch
          </Button>
        </div>
      </Section>
      <Section title="Output Variables" defaultCollapsed={true}>
        <List
          dataSource={nodeData?.data?.output || []}
          renderItem={(item: OutputField) => (
            <List.Item>
              <Space>
                <FunctionOutlined />
                <Text>{item.name}</Text>
              </Space>
              <Text type="secondary">{item.type}</Text>
            </List.Item>
          )}
        />
      </Section>
    </NodeSidebar>
  )
}

export default observer(ConditionNodeSidebar)
