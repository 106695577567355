import React, {
  useCallback,
  useRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import cls from 'classnames'
import {
  ReactFlow,
  Background,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesState,
  Connection,
  Node,
  Edge,
  NodeTypes,
  useReactFlow,
  ReactFlowProvider,
  EdgeTypes,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  NodeChange,
  EdgeChange,
  FitViewOptions,
} from '@xyflow/react'
import { observer } from 'mobx-react'
import WorkflowStore from 'stores/workflow'
import StartNode from './nodes/start'
import StartNodeSidebar from './nodes/start/sidebar'
import EndNode from './nodes/end'
import EndNodeSidebar from './nodes/end/sidebar'
import LLMNode from './nodes/llm'
import LLMNodeSidebar from './nodes/llm/sidebar'
import PIINode from './nodes/pii'
import PIINodeSidebar from './nodes/pii/sidebar'
import IntentNode from './nodes/intent'
import IntentNodeSidebar from './nodes/intent/sidebar'
import KnowledgeNode from './nodes/knowledge'
import KnowledgeNodeSidebar from './nodes/knowledge/sidebar'
import DocumentReaderNode from './nodes/document-reader'
import DocumentReaderNodeSidebar from './nodes/document-reader/sidebar'
import HTTPRequestNode from './nodes/http'
import HTTPRequestNodeSidebar from './nodes/http/sidebar'
import CodeNode from './nodes/code'
import CodeNodeSidebar from './nodes/code/sidebar'
import ConditionNode from './nodes/condition'
import ConditionNodeSidebar from './nodes/condition/sidebar'
import ToolsNode from './nodes/tools'
import { FlowContext } from './context'
import { initialNodes, initialEdges, defaultEdgeOptions } from './constants'
import { useNodeOperations } from './hooks'
import CustomEdge from './components/custom-edge'
import { getNewEdgeID } from './utils'
import PublishChannelPanel from '../workflow/panels/publish-channel'
import '@xyflow/react/dist/style.css'
import { CustomNodeProps } from './model'
import { useStudioOutletContext } from '..'
import { getQuery, safeJsonParse } from 'utils/common'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'
import { notification, Spin } from 'antd'

import styles from './index.scss'

// Define custom node types
const nodeTypes: NodeTypes = {
  Start: StartNode,
  End: EndNode,
  LLM: LLMNode,
  PII: PIINode,
  Intent: IntentNode,
  Tools: ToolsNode,
  Knowledge: KnowledgeNode,
  DocumentReader: DocumentReaderNode,
  HTTPRequest: HTTPRequestNode,
  Code: CodeNode,
  Condition: ConditionNode,
}

const edgeTypes: EdgeTypes = { custom: CustomEdge }

export interface WorkflowRef {
  getNodes: () => Node[]
  getEdges: () => Edge[]
  setNodes: (nodes: Node[]) => void
  setEdges: (edges: Edge[]) => void
  fitView: (options?: FitViewOptions) => void
  zoomIn: () => void
  zoomOut: () => void
}

// Main Flow component
const Flow = () => {
  const { workflowRef } = useStudioOutletContext()
  const { id } = getQuery<StudioPageQueryType>(location.search)
  const [canvasLoading, setCanvasLoading] = useState(true)
  // Ref for the ReactFlow wrapper div
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null)

  // State for nodes and edges using ReactFlow hooks
  const [nodes, setNodes, onNodesChangeInternal] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChangeInternal] = useEdgesState(initialEdges)

  // Get ReactFlow instance
  // Only child components of this component can access the state
  // https://reactflow.dev/learn/troubleshooting#001
  const reactFlowInstance = useReactFlow()

  // Custom hooks for workflow data and node operations
  const { handleNodeChange, handleNodeDelete } = useNodeOperations(
    nodes,
    setNodes,
    setEdges
  )

  const fetchWorkflowData = async () => {
    setCanvasLoading(true)
    if (!Number(id)) return
    const workflowRes = await WorkflowStore.getLatestWorkflowsByAgentID(
      Number(id)
    ).catch((error) => {
      console.error('Failed to fetch workflows:', error)
      notification.error({
        message: 'Error',
        description: 'Failed to fetch workflows',
      })
    })

    if (!workflowRes?.data) return
    const parsedNodes = safeJsonParse<Node[]>(
      workflowRes.data.nodes,
      initialNodes
    )

    const parsedEdges = safeJsonParse<Edge[]>(
      workflowRes.data.edges,
      initialEdges
    )

    setNodes(parsedNodes.length > 0 ? parsedNodes : initialNodes)
    setEdges(parsedEdges.length > 0 ? parsedEdges : initialEdges)
    setCanvasLoading(false)
  }

  const handleManualConnection = useCallback(
    (params: Edge | Connection) => {
      setEdges((currentEdges) => {
        const existingEdgeIndex = currentEdges.findIndex(
          (edge) =>
            edge.source === params.source &&
            edge.sourceHandle === params.sourceHandle
        )
        if (existingEdgeIndex !== -1) {
          return currentEdges.map((edge, index) =>
            index === existingEdgeIndex
              ? {
                  ...edge,
                  target: params.target,
                  targetHandle: params.targetHandle,
                }
              : edge
          )
        } else {
          const newEdge: Edge = {
            id: 'id' in params ? params.id : getNewEdgeID(currentEdges, 1)[0],
            source: params.source,
            target: params.target,
            sourceHandle: params.sourceHandle,
            targetHandle: params.targetHandle,
            ...defaultEdgeOptions,
          }
          return [...currentEdges, newEdge]
        }
      })
    },
    [setEdges]
  )

  const onConnect: OnConnect = useCallback(
    (params) => {
      handleManualConnection(params)
    },
    [handleManualConnection]
  )

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const filteredChanges = changes.filter((change) => {
        if (change.type === 'remove') {
          const edge = edges.find((edge) => edge.id === change.id)
          const sourceNodeSelected = nodes.find(
            (node) => node.id === edge?.source
          )?.selected
          const targetNodeSelected = nodes.find(
            (node) => node.id === edge?.target
          )?.selected
          return !sourceNodeSelected && !targetNodeSelected
        }
        return true
      })
      onEdgesChangeInternal(filteredChanges)
    },
    [onEdgesChangeInternal, nodes]
  )

  // Prevent nodes from being deleted
  const onNodesChange: OnNodesChange = useCallback(
    (changes: NodeChange[]) => {
      const filteredChanges = changes.filter(
        (change) => change.type !== 'remove'
      )
      onNodesChangeInternal(filteredChanges)
    },
    [onNodesChangeInternal]
  )

  // canvas click
  const onPaneClick = useCallback((event: React.MouseEvent) => {
    // console.error('canvas pane click')
  }, [])

  // current selected node change
  const onSelectionChange = useCallback(() => {
    // console.error('current selected node change')
  }, [])

  const handleNodeDrag = () => {
    // console.error('node drag')
  }

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    WorkflowStore.selectNode(node as unknown as CustomNodeProps)
    // console.error('node click ', node)
  }

  useImperativeHandle(
    workflowRef,
    () => ({
      getNodes: () => reactFlowInstance.getNodes(),
      getEdges: () => reactFlowInstance.getEdges(),
      setNodes: (newNodes: Node[]) => reactFlowInstance.setNodes(newNodes),
      setEdges: (newEdges: Edge[]) => reactFlowInstance.setEdges(newEdges),
      fitView: (options?: FitViewOptions) => reactFlowInstance.fitView(options),
      zoomIn: () => reactFlowInstance.zoomIn(),
      zoomOut: () => reactFlowInstance.zoomOut(),
    }),
    [reactFlowInstance]
  )

  useEffect(() => {
    fetchWorkflowData()

    return () => {
      WorkflowStore.selectNode(null)
    }
  }, [])

  // Memoized nodes with change and delete handlers
  const nodesWithHandlers = useMemo<Node[]>(() => {
    return nodes.map((n) => {
      return {
        ...n,
        data: {
          ...n.data,
          onChange: handleNodeChange,
          onDelete: handleNodeDelete,
        },
      }
    })
  }, [nodes, handleNodeChange, handleNodeDelete])

  return (
    <div className="workflow-canvas" ref={reactFlowWrapper}>
      {canvasLoading ? (
        <Spin className={styles.workflowSpin} />
      ) : (
        <FlowContext.Provider value={{ nodes, edges, setNodes, setEdges }}>
          <ReactFlow
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={nodesWithHandlers}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onPaneClick={onPaneClick}
            onSelectionChange={onSelectionChange}
            onNodeClick={handleNodeClick}
            onNodeDrag={handleNodeDrag}
            minZoom={0.2}
            fitView
          >
            <MiniMap />
            <Controls />
            <Background />
          </ReactFlow>
          <StartNodeSidebar />
          <ConditionNodeSidebar />
          <EndNodeSidebar />
          <LLMNodeSidebar />
          <PIINodeSidebar />
          <IntentNodeSidebar />
          <KnowledgeNodeSidebar />
          <DocumentReaderNodeSidebar />
          <HTTPRequestNodeSidebar />
          <CodeNodeSidebar />
          <PublishChannelPanel />
        </FlowContext.Provider>
      )}
    </div>
  )
}

// Wrapper component to provide ReactFlow context
// Only child component can use hook, so need a wrapper here.
// https://reactflow.dev/learn/troubleshooting#001
const Workflow = () => {
  return (
    <ReactFlowProvider>
      <div className="workflow">
        <Flow />
      </div>
    </ReactFlowProvider>
  )
}

export default observer(Workflow)
