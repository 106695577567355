import React, { useState, useRef, ReactNode, useEffect } from 'react'
import { App, Button, Modal, Steps } from 'antd'
import { Intent } from 'stores/models/intent'
import IntentGeneralForm, { IntentGeneralFormRefProps } from './parameters-form'
import IntentExamplesForm, { IntentExamplesFormRefProps } from './examples-form'
import intentStore from 'stores/intent'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import SubmissionResult, { SubmissionResultStatus } from './submission-result'
import './index.css'
import { convertFieldToIntentParameter } from './converter'

const { Step } = Steps

enum StepType {
  General = 0,
  Examples = 1,
  Completed = 2,
}

interface IntentEditorProps {
  data: Intent
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: () => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const IntentEditor: React.FC<IntentEditorProps> = (props) => {
  const { message } = App.useApp()
  const generalFormRef = useRef<IntentGeneralFormRefProps>(null)
  const examplesFormRef = useRef<IntentExamplesFormRefProps>(null)
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionResultStatus>('loading')
  const [data, setData] = useState<Intent>(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const handleGeneralStep = async () => {
    if (!generalFormRef.current) {
      return false
    }
    try {
      await generalFormRef.current?.validateFields()
      const values = await generalFormRef.current?.getFieldsValue()
      const updatedIntent: Intent = {
        id: data?.id || 0,
        intent: values.intent || '',
        description: values.description || '',
        examples: data.examples || [],
        parameters: convertFieldToIntentParameter(values.parameters, 2),
        organizationID: data?.organizationID || 0,
      }
      setData(updatedIntent)
      setStep(step + 1)
      return true
    } catch (error) {
      message.error('Failed to validate general form')
    }
    return false
  }

  const handleExamplesStep = async () => {
    if (!examplesFormRef.current || !props.data) {
      return false
    }

    try {
      await examplesFormRef.current?.validateFields()
      const examples = examplesFormRef.current?.getFieldsValue()
      const updatedIntent: Intent = {
        ...data,
        examples: [...examples],
      }
      setData(updatedIntent)
      setStep(step + 1)
      setSubmissionStatus('loading')
      return true
    } catch (error) {
      message.error('Failed to validate examples')
    }
    return false
  }

  const handleSubmit = async () => {
    setLoading(true)

    try {
      if (data.id === 0) {
        await intentStore.create(
          data.intent,
          data.description,
          data.examples,
          data.parameters
        )
      } else {
        await intentStore.update(
          data.id,
          data.intent,
          data.description,
          data.examples,
          data.parameters
        )
      }

      setSubmissionStatus('success')
      return true
    } catch (error) {
      console.error('Failed to save intent:', error)
      message.error('Failed to save intent')
      setSubmissionStatus('error')
    } finally {
      setLoading(false)
    }
    return true
  }

  useEffect(() => {
    if (step === StepType.Completed) {
      handleSubmit()
    }
  }, [step])

  const resetForms = () => {
    try {
      setStep(StepType.General)
      setData(props.data)
      generalFormRef.current?.resetFields()
      examplesFormRef.current?.resetFields()
    } catch (error) {
      console.error('Failed to reset form:', error)
    }
  }

  const handleNext = async () => {
    if (step === StepType.General) {
      await handleGeneralStep()
    } else if (step === StepType.Examples) {
      await handleExamplesStep()
    }
  }

  const handlePrevious = () => {
    if (step === StepType.General) {
      return
    }
    setStep(step - 1)
  }

  const handleFinish = () => {
    if (props.onFinish) {
      props.onFinish()
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    resetForms()
    if (step === StepType.Completed) {
      handleFinish()
      return
    }
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const displayCalc = (currentStep: number, condition = true) => {
    return { display: step === currentStep && condition ? 'unset' : 'none' }
  }

  return (
    <Modal
      title={props.title || 'Intent Editor'}
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      width={1000}
      footer={[]}
      style={{ width: 'max-content' }}
    >
      <div className="intent-editor">
        <Steps current={step} style={{ padding: '20px 0 40px 0' }} progressDot>
          <Step title="General" />
          <Step title="Examples" />
          <Step title="Completed" />
        </Steps>

        <IntentGeneralForm
          ref={generalFormRef}
          data={data}
          style={displayCalc(StepType.General)}
        />
        <IntentExamplesForm
          ref={examplesFormRef}
          data={data}
          style={displayCalc(StepType.Examples, !!data)}
        />
        <SubmissionResult
          status={submissionStatus}
          onOk={handleFinish}
          style={displayCalc(StepType.Completed)}
        />
        <div style={{ marginTop: '20px' }}>
          {step < StepType.Completed && step > StepType.General ? (
            <Button
              htmlType="button"
              onClick={handlePrevious}
              style={{ marginRight: 8 }}
              loading={loading}
              icon={<LeftOutlined />}
            >
              Back
            </Button>
          ) : null}
          {step < StepType.Completed ? (
            <Button
              type="primary"
              onClick={handleNext}
              loading={loading}
              icon={<RightOutlined />}
            >
              Next
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default IntentEditor
