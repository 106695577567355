import React, { useState } from 'react'
import { Button, Row, Col, Avatar, Tag, Dropdown, Spin, App } from 'antd'
import type { MenuProps } from 'antd'
import {
  QuestionCircleOutlined,
  FormOutlined,
  DeleteOutlined,
  BranchesOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import agentStore from 'stores/agent'
import { useExNavigate } from 'hooks/use-ex-navigate'
import './agent-card.css'
import {
  getFileUrl,
  getMessageFromError,
  isEmpty,
  setQuery,
} from 'utils/common'
import { Agent, AgentType } from 'stores/models/agent'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface AgentCardProps {
  data: {
    id: number
    uuid: string
    name: string
    description: string
    agentType: AgentType
    iconId?: number
    iconUuid?: string
    organizationId?: number
  }
  onAction?: (type: ActionType, agent: Agent) => void
}

export type StudioPageQueryType = {
  id: string
  agentType: 'smart_api' | 'chat_bot'
  uuid: string
}

const AgentCard: React.FC<AgentCardProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const { message, modal } = App.useApp()
  const navigate = useExNavigate()

  const handleModifyButtonClick = () => {
    const params = setQuery({
      id: props.data.id,
      uuid: props.data.uuid,
      agentType: props.data.agentType,
    })

    navigate(`/portal/agent/studio/workflow?${params}`, {
      state: { agent: props.data },
    })
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'delete') {
      modal.confirm({
        title: 'Are you sure you want to delete this agent?',
        content:
          'This action cannot be undone and will permanently delete the agent from the system.',
        okText: 'Yes, Delete it',
        okType: 'danger',
        cancelText: 'No, Cancel',
        async onOk() {
          setLoading(true)
          try {
            await agentStore.delete(props.data.id)
            message.success('The agent has been successfully deleted.')
          } catch (error) {
            message.error(getMessageFromError(error))
          }
          setLoading(false)
        },
      })
    } else if (e.key === 'edit') {
      props.onAction?.(ActionType.Edit, {
        id: props.data.id,
        uuid: props.data.uuid,
        organizationId: props.data.organizationId ?? 0,
        description: props.data.description,
        agentName: props.data.name,
        agentType: props.data.agentType,
        agentIconID: props.data.iconId ?? 0,
        agentIconUUID: props.data.iconUuid ?? '',
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      label: 'Edit Info',
      key: 'edit',
      icon: <FormOutlined />,
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <DeleteOutlined />,
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className="agent-card">
        <Row gutter={24}>
          <Col span={12}>
            <div className="agent-icon">
              {isEmpty(props.data.iconUuid) ? (
                <Avatar
                  shape="square"
                  size={60}
                  icon={<QuestionCircleOutlined />}
                />
              ) : (
                <Avatar
                  shape="square"
                  size={60}
                  src={getFileUrl(props.data.iconUuid ?? '')}
                />
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="agent-type">
              <Tag color="#3d3956" style={{ marginRight: 0 }}>
                {props.data.agentType}
              </Tag>
            </div>
          </Col>
        </Row>
        <div className="agent-name">{props.data.name}</div>
        <div className="agent-description">{props.data.description}</div>
        <div className="agent-button">
          <Row gutter={24}>
            <Col span={12}>
              <Link to={'/portal/agent/chat?agent_uuid=' + props.data.uuid}>
                <Button type="primary">Start</Button>
              </Link>
            </Col>
            <Col span={12}>
              <Dropdown.Button
                menu={menuProps}
                onClick={handleModifyButtonClick}
              >
                <BranchesOutlined />
                Studio
              </Dropdown.Button>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  )
}

export default observer(AgentCard)
