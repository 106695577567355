import dayjs from 'dayjs'
import { isEmpty } from './common'
import { GLOBAL_DATE_TIME_FORMAT } from 'constants/common'

export const formatDate = (
  text: string,
  format = GLOBAL_DATE_TIME_FORMAT
): string => {
  if (isEmpty(text)) return ''
  return dayjs(text).format(format)
}

export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(k))
  return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}
