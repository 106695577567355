import { useCallback } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const useExNavigate = () => {
  const navigate = useNavigate()
  const fn: NavigateFunction = (to, options?) => {
    if (typeof to === 'number') {
      navigate(to)
    } else {
      const opt =
        options === undefined ? undefined : JSON.parse(JSON.stringify(options))
      navigate(to, opt)
    }
  }
  const navigator = useCallback(fn, [navigate])
  return navigator
}
