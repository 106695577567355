import React, { useEffect, useState } from 'react'
import { Position, useReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { BookOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  OutputField,
  InputField,
  DataType,
  InputType,
  CustomNodeProps,
  NodeData,
} from 'views/portal/agent/studio/workflow/model'
import DeleteButton from '../../components/delete-button'
import { getNewNodeLabel } from '../../utils'
import CustomHandle from '../../components/custom-handle'

// Define default values
const defaultInputs: InputField[] = [
  {
    name: 'document',
    type: 'reference' as InputType,
    dataType: 'Document' as DataType,
    value: '',
    reference: '',
  },
]

const defaultOutputs: OutputField[] = [
  {
    name: 'text',
    type: 'String' as DataType,
    description: 'Document Text',
    required: true,
    children: [],
  },
]

const getDefaultNodeData = (label: string): NodeData => ({
  label,
  description: 'Document reader node',
  input: defaultInputs,
  output: defaultOutputs,
  intentBranch: [],
  conditionBranch: [],
  branchOutput: {},
})

const DocumentReaderNode: React.FC<CustomNodeProps> = (node) => {
  const { getNodes } = useReactFlow()
  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || getDefaultNodeData('Document Reader')
  )

  useEffect(() => {
    if (!node.data.data) {
      const newLabel = getNewNodeLabel(getNodes(), 'Document Reader')
      const newNodeData = getDefaultNodeData(newLabel)
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else if (node.data.data.label !== nodeData.label) {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node document-reader-node active'
          : 'custom-node document-reader-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <BookOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <DeleteButton nodeID={node.id} />
      <CustomHandle
        node={node}
        type="source"
        position={Position.Right}
        isConnectable={node.isConnectable}
      />
    </div>
  )
}

export default observer(DocumentReaderNode)
