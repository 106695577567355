import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { AxiosResponse } from 'axios'
import { APIList, API } from './models/api'
import { IntentList, Intent } from './models/intent'
import { KnowledgeList } from './models/knowledge'
import { Model, RerankModel } from './models/model'
import { Workflow } from './models/workflow'
import { CustomNodeProps } from '../views/portal/agent/studio/workflow/model'
import { ResponsesLLMResponse } from 'api/data-contracts'

class WorkflowStore {
  selectedNode: CustomNodeProps | null = null
  selectedPanel: string = ''
  toolNodeVisible: boolean = false
  toolNodeSource: string = ''
  toolNodeTarget: string = ''
  toolNodeSourceHandleID: string | null | undefined = ''
  toolNodeTargetHandleID: string | null | undefined = ''

  apiList: APIList = {
    totalCount: 0,
    apis: [],
  }

  intentList: IntentList = {
    totalCount: 0,
    intents: [],
  }

  knowledgeList: KnowledgeList = {
    totalCount: 0,
    knowledges: [],
  }

  models: Model[] = []
  rerankModels: RerankModel[] = []

  workflow: Workflow | null = null

  constructor() {
    makeAutoObservable(this)
  }

  selectNode(node: CustomNodeProps | null = null) {
    this.selectedNode = node
  }

  selectPanel(panel: string = '') {
    this.selectedPanel = panel
  }

  setWorkflow(data: Workflow) {
    runInAction(() => {
      this.workflow = data
    })
  }

  setModels(data: ResponsesLLMResponse[]) {
    runInAction(() => {
      this.models = data
    })
  }

  toggleToolNode(
    visible: boolean,
    toolNodeSource: string,
    toolNodeTarget: string,
    toolNodeSourceHandleID: string | null | undefined,
    toolNodeTargetHandleID: string | null | undefined
  ) {
    this.toolNodeVisible = visible
    this.toolNodeSource = toolNodeSource
    this.toolNodeTarget = toolNodeTarget
    this.toolNodeSourceHandleID = toolNodeSourceHandleID
    this.toolNodeTargetHandleID = toolNodeTargetHandleID
  }

  getAPIsByAgentID(agentID: number): Promise<AxiosResponse<any>> {
    const params: RequestData = {
      queryParams: {
        agentID,
      },
    }

    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.apiAllList, params)
        .then(
          (response: AxiosResponse<{ totalCount: number; apis: API[] }>) => {
            this.apiList = {
              totalCount: response.data.totalCount,
              apis: response.data.apis,
            }
            resolve(response)
          }
        )
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  getIntentsByAgentID(agentID: number): Promise<AxiosResponse<any>> {
    const params: RequestData = {
      queryParams: {
        agentID,
      },
    }

    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.intentAllList, params)
        .then(
          (
            response: AxiosResponse<{ totalCount: number; intents: Intent[] }>
          ) => {
            this.intentList = {
              totalCount: response.data.totalCount,
              intents: response.data.intents,
            }
            resolve(response)
          }
        )
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  getKnowledges(page: number, size: number): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const params: RequestData = {
        queryParams: {
          page,
          size,
        },
      }

      createApiRequest(apiConfig.knowledgeList, params)
        .then((response: AxiosResponse<KnowledgeList>) => {
          this.knowledgeList = {
            totalCount: response.data.totalCount,
            knowledges: response.data.knowledges,
          }
          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  // getLLMModels(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     createApiRequest(apiConfig.llmModels)
  //       .then((response) => {
  //         const formattedModels: Model[] = response.data.map((item: any) => ({
  //           id: item.id,
  //           name: `${item.name} - Context: ${item.contextWindow} tokens`,
  //           developer: item.developer,
  //         }))
  //         this.setModels(formattedModels)
  //         resolve()
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // }

  getRerankModels(): Promise<void> {
    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.rerankModels)
        .then((response) => {
          const formattedModels: RerankModel[] = response.data.map(
            (item: any) => ({
              id: item.id,
              name: `${item.name}`,
              developer: item.developer,
            })
          )
          this.rerankModels = formattedModels
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getLatestWorkflowsByAgentID(
    agentID: number
  ): Promise<AxiosResponse<Workflow>> {
    const params: RequestData = {
      queryParams: {
        agentID,
      },
    }

    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.workflowGetLatest, params)
        .then((response: AxiosResponse<Workflow>) => {
          this.setWorkflow(response.data)
          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  save(id: number, edges: string, nodes: string): Promise<AxiosResponse<any>> {
    const params: RequestData = {
      body: {
        id,
        edges,
        nodes,
      },
    }

    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.workflowSave, params)
        .then((response: AxiosResponse<Workflow>) => {
          this.workflow = response.data
          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  publish(
    id: number,
    edges: string,
    nodes: string
  ): Promise<AxiosResponse<any>> {
    const params: RequestData = {
      body: {
        id,
        edges,
        nodes,
      },
    }

    return new Promise((resolve, reject) => {
      createApiRequest(apiConfig.workflowPublish, params)
        .then((response: AxiosResponse<Workflow>) => {
          this.workflow = response.data
          resolve(response)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }
}

export default new WorkflowStore()
