import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { observer } from 'mobx-react'
import RoleTable, { ActionType, RoleTableRefProps } from './role-table'
import RoleModal, { RoleModalProps } from './role-modal'
import './index.css'

const DEFAULT_ROLE_DATA = {
  name: '',
  email: '',
  owner: false,
  permissions: [],
}

const RolePage: React.FC = () => {
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false)
  const roleTableRef = React.createRef<RoleTableRefProps>()
  const [role, setRole] = useState<RoleModalProps['data']>(DEFAULT_ROLE_DATA)

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>Role</h1>
            <p></p>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setRole(DEFAULT_ROLE_DATA)
                setIsRoleModalOpen(true)
              }}
            >
              Create Role
            </Button>
          </div>
        </div>
        <div className="role-table">
          <RoleTable
            ref={roleTableRef}
            onAction={(type, role) => {
              if (type === ActionType.Edit) {
                setRole({
                  id: role.id,
                  organizationId: role.organization_id,
                  name: role.name,
                  owner: role.owner,
                  permissions: role.permissions,
                })
                setIsRoleModalOpen(true)
              }
            }}
          />
        </div>
        <RoleModal
          open={isRoleModalOpen}
          title={!!role.id ? 'Edit Role' : 'Create Role'}
          okText="Save"
          data={role}
          onFinish={() => {
            roleTableRef.current?.refresh()
            setIsRoleModalOpen(false)
          }}
          onCancel={() => setIsRoleModalOpen(false)}
        />
      </div>
    </>
  )
}

export default observer(RolePage)
