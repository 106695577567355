import { useEffect, useMemo } from 'react'
import { Menu } from 'antd'
import { observer } from 'mobx-react'
import {
  BankFilled,
  RobotFilled,
  DatabaseFilled,
  DashboardOutlined,
} from '@ant-design/icons'
import globalStore from 'stores/global'
import { useExNavigate } from 'hooks/use-ex-navigate'
import './menu.css'

const MenuItems = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    route: '/portal/dashboard',
    icon: <DashboardOutlined />,
  },
  {
    key: 'organization',
    label: 'Organization',
    route: '/portal/organization',
    icon: <BankFilled />,
  },
  {
    key: 'agent',
    label: 'Agent',
    route: '/portal/agent',
    icon: <RobotFilled />,
  },
  // {
  //   key: 'intent',
  //   label: 'Intent',
  //   route: '/portal/intent',
  //   icon: <MergeOutlined />,
  // },
  {
    key: 'knowledge',
    label: 'Knowledge',
    route: '/portal/knowledge',
    icon: <DatabaseFilled />,
  },
  // {
  //   key: 'integration',
  //   label: 'Integration',
  //   route: '/portal/integration',
  //   icon: <ApiFilled />,
  // },
] as const

const Sidebar = () => {
  const navigate = useExNavigate()

  const curPath = useMemo(() => {
    if (!location.pathname.startsWith('/portal')) return ''
    return location.pathname.split('/').slice(2).join('/')
  }, [location.pathname])

  const handleMenuClick = (path: string) => {
    globalStore.selectMenu(path)

    const route = MenuItems.find((item) => item.key === path)?.route
    if (!route) return
    navigate(route)
  }

  useEffect(() => {
    // init the path while page refresh
    handleMenuClick(curPath)
  }, [])

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[globalStore.globalSettings.selectedMenu]}
      items={MenuItems.map((item) => ({
        key: item.key,
        icon: item.icon,
        label: item.label,
      }))}
      onClick={(info) => handleMenuClick(info.key)}
    />
  )
}

export default observer(Sidebar)
