import { observer } from 'mobx-react'
import { useContext, useState, useRef } from 'react'
import { Button, Space, Input, Tree } from 'antd'
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import Section from '../../components/section'
import {
  InputField as InputFieldType,
  OutputField,
  CustomNodeProps,
  NodeData,
  CodeData,
  DataType,
  InputType,
} from '../../model'
import InputField from '../../components/input-field'
import { FlowContext } from '../../context'
import CodeEditor from '../../components/code-editor'
import OutputModal, { OutputModalRef } from './output'
import renderTreeNodes from './tree-node'
import NodeSidebar from '../../components/node-sidebar'

const { TextArea } = Input

const CodeNodeSidebar = () => {
  const context = useContext(FlowContext)
  const outputModalRef = useRef<OutputModalRef>(null)
  const [isOutputModalVisible, setIsOutputModalVisible] = useState(false)

  const selectedNode = WorkflowStore.selectedNode
  const nodeData = selectedNode?.data?.data

  const getExtendsValue = (name: string) => nodeData?.extends?.[name]

  const handleNodeDataChange = (field: string, value: any) => {
    if (selectedNode?.data?.data) {
      const currentNodeData = { ...selectedNode.data.data }
      let updatedExtends = { ...currentNodeData.extends } as CodeData
      let updatedInput = [...(currentNodeData.input || [])]
      let updatedOutput = [...(currentNodeData.output || [])]

      if (field === 'label' || field === 'description') {
        currentNodeData[field] = value
      } else if (field === 'extends') {
        updatedExtends = value
      } else if (field === 'code') {
        updatedExtends.code = value
      } else if (field === 'input') {
        updatedInput = value
      } else if (field === 'output') {
        updatedOutput = value
      }

      const updatedNodeData = {
        ...(selectedNode.data as NodeData),
        data: {
          ...currentNodeData,
          extends: updatedExtends,
          input: updatedInput,
          output: updatedOutput,
        },
      }

      const updatedNode = {
        ...selectedNode,
        data: updatedNodeData,
      }
      WorkflowStore.selectNode(updatedNode as CustomNodeProps)
      selectedNode.data.onChange?.(selectedNode.id, updatedNodeData)
    }
  }

  const handleClose = () => WorkflowStore.selectNode(null)

  const handleAddField = (fieldType: 'input' | 'output') => {
    const currentFields = nodeData?.[fieldType] || []
    const newField: InputFieldType | OutputField = {
      name: '',
      type: 'input' as InputType,
      dataType: 'String' as DataType,
      value: '',
      reference: '',
    }

    handleNodeDataChange(fieldType, [...currentFields, newField])
  }

  const handleRemoveField = (fieldType: 'input' | 'output', index: number) => {
    const currentFields = nodeData?.[fieldType] || []
    if (fieldType === 'input') {
      const inputFields = currentFields as InputFieldType[]
      const updatedFields = inputFields.filter((_, i: number) => i !== index)
      handleNodeDataChange(fieldType, updatedFields)
    } else {
      const outputFields = currentFields as OutputField[]
      const updatedFields = outputFields.filter((_, i: number) => i !== index)
      handleNodeDataChange(fieldType, updatedFields)
    }
  }

  const handleFieldChange = (
    fieldType: 'input' | 'output',
    index: number,
    key: string,
    value: any
  ) => {
    const currentFields = nodeData?.[fieldType] || []
    const updatedFields = currentFields.map(
      (field: InputFieldType | OutputField, i: number) =>
        i === index ? { ...field, [key]: value } : field
    )
    handleNodeDataChange(fieldType, updatedFields)
  }

  const showOutputModal = () => setIsOutputModalVisible(true)
  const handleOutputModalOk = (fields: OutputField[]) => {
    handleNodeDataChange('output', fields)
    setIsOutputModalVisible(false)
  }
  const handleOutputModalCancel = () => setIsOutputModalVisible(false)

  if (selectedNode?.type !== 'Code') {
    return null
  }

  return (
    <NodeSidebar
      nodeType={'code'}
      onClose={handleClose}
      nodeData={WorkflowStore.selectedNode?.data?.data}
      onChangeNodeName={(e) => handleNodeDataChange('label', e.target.value)}
    >
      <div className="custom-node-sidebar-desc">
        <TextArea
          className="editable-description"
          value={nodeData?.description}
          onChange={(e) => handleNodeDataChange('description', e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </div>

      <Section title="Code">
        <CodeEditor
          value={getExtendsValue('code') || ''}
          onChange={(value) => handleNodeDataChange('code', value)}
          language={'python'}
        />
      </Section>

      <Section title="Input">
        {(nodeData?.input || []).map((field: InputFieldType, index: number) => (
          <div
            key={index}
            style={{
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Space>
              <InputField
                index={index}
                item={field}
                currentNodeId={selectedNode?.id || ''}
                nodes={context?.nodes || []}
                edges={context?.edges || []}
                allowEditFieldName={true}
                allowEditDataType={true}
                supportArray={true}
                supportObject={true}
                displayDataType={true}
                displayFieldName={true}
                handleFormChange={(_, key, value) =>
                  handleFieldChange('input', index, key, value)
                }
              />
              <Button
                icon={<MinusCircleOutlined />}
                onClick={() => handleRemoveField('input', index)}
              />
            </Space>
          </div>
        ))}
        <Button
          icon={<PlusOutlined />}
          onClick={() => handleAddField('input')}
          type="dashed"
          style={{ width: '100%' }}
        >
          Add Input Field
        </Button>
      </Section>

      <Section title="Output">
        <Tree defaultExpandAll showLine switcherIcon={<DownOutlined />}>
          {renderTreeNodes(nodeData?.output || [])}
        </Tree>
        <Button
          icon={<SettingOutlined />}
          onClick={showOutputModal}
          style={{ width: '100%', marginTop: '10px' }}
        >
          Output Structure Settings
        </Button>
      </Section>

      <OutputModal
        ref={outputModalRef}
        isVisible={isOutputModalVisible}
        onOk={handleOutputModalOk}
        onCancel={handleOutputModalCancel}
        initialValue={nodeData?.output || []}
      />
    </NodeSidebar>
  )
}

export default observer(CodeNodeSidebar)
