import React, { useState, useRef } from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import ApiTable, { ActionType, ApiTableRefProps } from './api-table' // 假设 APITable 在这个路径下
import Header from 'layouts/portal/header'
import './index.css'
import { API } from 'stores/models/api'
import ApiEditor from './api-editor'

const INITIAL_API_DATA: API = {
  id: 0,
  apiName: '',
  description: '',
  uri: '',
  method: 'GET',
  timeout: 0,
  requestHeaders: '',
  requestParams: '',
  queryParams: '',
  pathParams: '',
  responseParams: '',
  organizationID: 0,
}

const ApiIntegration: React.FC = () => {
  const [apiEditorData, setApiEditorData] = useState<API>(INITIAL_API_DATA)
  const [isApiEditorOpen, setIsApiEditorOpen] = useState(false)
  const tableRef = useRef<ApiTableRefProps>(null)

  const handleCreateAPIClick = () => {
    setApiEditorData(INITIAL_API_DATA)
    setIsApiEditorOpen(true)
  }

  return (
    <div>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>API Integration</h1>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreateAPIClick}
            >
              Create API
            </Button>
          </div>
        </div>
        <div className="api-list" style={{ gap: '20px' }}>
          <ApiTable
            ref={tableRef}
            onAction={(type, data) => {
              if (type === ActionType.Edit) {
                setApiEditorData(data)
                setIsApiEditorOpen(true)
              }
            }}
          />
          <ApiEditor
            open={isApiEditorOpen}
            data={apiEditorData}
            onFinish={() => {
              tableRef.current?.refresh()
              setIsApiEditorOpen(false)
            }}
            onCancel={() => setIsApiEditorOpen(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(ApiIntegration)
