import React, { useContext, useState } from 'react'
import { Button, Popconfirm } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { FlowContext } from '../context'
import WorkflowStore from 'stores/workflow'

interface DeleteButtonProps {
  nodeID: string
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ nodeID }) => {
  const context = useContext(FlowContext)

  const handleDelete = () => {
    context?.setNodes(context?.nodes.filter((node) => node.id !== nodeID))
    context?.setEdges(
      context?.edges.filter(
        (edge) => ![edge.source, edge.target].includes(nodeID)
      )
    )
    if (
      WorkflowStore.selectedNode &&
      WorkflowStore.selectedNode.id === nodeID
    ) {
      WorkflowStore.selectNode(null)
    }
  }

  return (
    <div className="custom-node-delete">
      <Popconfirm
        title="Are you sure you want to delete this node?"
        onConfirm={handleDelete}
        okText="Yes"
        cancelText="No"
      >
        <Button icon={<CloseOutlined />} size="small" shape="circle" />
      </Popconfirm>
    </div>
  )
}

export default DeleteButton
