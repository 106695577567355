import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react'
import { observer } from 'mobx-react'
import { Table, Button, Tooltip, message, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import apiStore from 'stores/api'
import { API } from 'stores/models/api'
import { formatDate } from 'utils/filter'
import { getMessageFromError } from 'utils/common'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

export interface ApiTableRefProps {
  refresh: () => void
}

interface ApiTableProps {
  onAction?: (type: ActionType, api: API) => void
}

const ApiTable: ForwardRefRenderFunction<ApiTableRefProps, ApiTableProps> = (
  props,
  ref
) => {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [deletingID, setDeletingID] = useState(0)

  useImperativeHandle(ref, () => ({
    refresh: () => load(1),
  }))

  const load = async (page: number) => {
    setLoading(true)
    try {
      await apiStore.getList(page, size)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    load(1)
  }, [])

  const deleteAPI = async (key: number) => {
    setDeletingID(key)
    try {
      await apiStore.delete(key)
      message.success('API deleted successfully!')
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingID(0)
    load(page)
  }

  const columns = [
    {
      title: 'API Name',
      dataIndex: 'apiName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'URI',
      dataIndex: 'uri',
    },
    {
      title: 'Method',
      dataIndex: 'method',
    },
    {
      title: 'Operation',
      width: 120,
      render: (text: any, record: API) => {
        return (
          <div key={'action_' + record.id}>
            <Tooltip placement="top" title="Edit API">
              <Button
                key={'edit_' + record.id}
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  props.onAction && props.onAction(ActionType.Edit, record)
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete API">
              <Popconfirm
                key={'popconfirm_' + record.id}
                title="Are you sure to delete the API?"
                onConfirm={() => deleteAPI(record.id)}
              >
                <Button
                  key={'delete_' + record.id}
                  icon={<DeleteOutlined />}
                  size="small"
                  loading={deletingID === record.id}
                  style={{ marginLeft: 4 }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={apiStore.apiList.apis}
      columns={columns}
      loading={loading}
      rowKey="id"
      pagination={{
        current: page,
        pageSize: size,
        total: apiStore.apiList.totalCount,
        position: ['bottomLeft'],
        onChange: (page, pageSize) => {
          setPage(page)
          load(page)
        },
      }}
    />
  )
}

export default observer(forwardRef(ApiTable))
