import React, { ReactNode, useEffect, useState } from 'react'
import {
  Steps,
  Button,
  Form,
  Input,
  Typography,
  Modal,
  Flex,
  QRCode,
  QRCodeProps,
  App,
} from 'antd'
import { observer } from 'mobx-react'
import { getMessageFromError } from 'utils/common'
import userStore from 'stores/user'
import './index.css'

const { Step } = Steps
const { Link } = Typography

interface TwoFactorAuthProps {
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: () => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

enum TwoFactorAuthStepType {
  DOWNLOAD_AUTHENTICATOR,
  SCAN_QR_CODE,
  SECURITY_AUTHENTICATION,
}

const QR_CODE_EXPIRED_TIME = 10 * 60000
const QR_CODE_MAX_RETRY = 3

const TwoFactorAuth: React.FC<TwoFactorAuthProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [qrUrl, setQrUrl] = useState<string>(' ')
  const [qrStatus, setQrStatus] = useState<QRCodeProps['status']>('expired')
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    if (props.open) {
      setCurrent(0)
    }
  }, [props.open])

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleVerifyAuthCode = async () => {
    setConfirmLoading(true)
    try {
      const authCode = form.getFieldValue('authCode')
      await userStore.twoFactorAuthSetup(authCode)
      message.success('2FA Enabled Successfully!')
      return true
    } catch (error) {
      message.error(getMessageFromError(error))
      return false
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async () => {
    const result = await handleVerifyAuthCode()
    if (result && props.onFinish) {
      props.onFinish()
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  const isStepDescriptionDisplay = (step: TwoFactorAuthStepType) => {
    return { display: current === step ? undefined : 'none' }
  }

  const refreshQR = (action: 'auto' | 'manual' = 'auto') => {
    setQrStatus('loading')
    userStore
      .getTwoFactorAuthQRCode()
      .then((response) => {
        if (typeof response.data === 'string') {
          setQrUrl(response.data)
          setQrStatus('active')
          if (action === 'manual') {
            setTimeout(() => {
              setQrStatus('expired')
            }, QR_CODE_EXPIRED_TIME)
          }
        }
      })
      .catch((error) => {
        message.error(getMessageFromError(error))
      })
  }

  useEffect(() => {
    if (current !== TwoFactorAuthStepType.SCAN_QR_CODE) {
      return
    }

    refreshQR()

    let time = QR_CODE_MAX_RETRY
    const intervalId = setInterval(() => {
      if (--time === 0) {
        setQrStatus('expired')
        clearInterval(intervalId)
        return
      }
      refreshQR()
    }, QR_CODE_EXPIRED_TIME)

    return () => {
      clearInterval(intervalId)
    }
  }, [current])

  const steps = [
    {
      title: 'Download Authenticator',
      subTitle: (
        <Link
          href="https://www.google.com/authenticator"
          target="_blank"
          style={{ textDecoration: 'underline', display: 'none' }}
        >
          Show details
        </Link>
      ),
      description: (
        <Flex
          vertical
          style={isStepDescriptionDisplay(
            TwoFactorAuthStepType.DOWNLOAD_AUTHENTICATOR
          )}
          gap={12}
        >
          <div>
            Download <u>Google Authenticator App</u> from your mobile device's
            app store.
          </div>
          <Flex gap={12}>
            <Flex gap={12} align="center" vertical>
              <QRCode value="https://apps.apple.com/sg/app/google-authenticator/id388497605" />
              IOS
            </Flex>
            <Flex gap={12} align="center" vertical>
              <QRCode value="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator" />
              Android
            </Flex>
          </Flex>
          <span>
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          </span>
        </Flex>
      ),
    },
    {
      title: 'Scan QR Code',
      description: (
        <Flex
          vertical
          style={isStepDescriptionDisplay(TwoFactorAuthStepType.SCAN_QR_CODE)}
          gap={12}
        >
          Scan the QR code:
          <QRCode
            value={qrUrl}
            status={qrStatus}
            onRefresh={() => refreshQR('manual')}
          />
          or input the code manually into your authenticator app.
          <span>
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          </span>
        </Flex>
      ),
    },
    {
      title: 'Security Authentication',
      description: (
        <Flex
          vertical
          style={isStepDescriptionDisplay(
            TwoFactorAuthStepType.SECURITY_AUTHENTICATION
          )}
        >
          <Form form={form} onFinish={handleFinish} layout="vertical">
            <Form.Item
              name="authCode"
              label={<b>Authentication Code</b>}
              rules={[
                {
                  required: true,
                  message: 'Please input your authentication code!',
                },
              ]}
            >
              <Input placeholder="Current N-digit generated code from your app" />
            </Form.Item>
          </Form>
          <span>
            <Button type="primary" onClick={handleFinish}>
              Next
            </Button>
          </span>
        </Flex>
      ),
    },
  ]

  return (
    <Modal
      className="two-factor-auth"
      title="2FA Setup"
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <h2>Link authenticator app</h2>
      <Steps
        current={current}
        direction="vertical"
        onChange={(value: number) => {
          setCurrent(value)
        }}
      >
        {steps.map((item, index) => (
          <Step key={index} {...item} />
        ))}
      </Steps>
    </Modal>
  )
}

export default observer(TwoFactorAuth)
