import React, { useEffect, useState } from 'react'
import { Handle, Position, useReactFlow } from '@xyflow/react'
import { CodeOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  OutputField,
  InputField,
  CustomNodeProps,
  NodeData,
  DataType,
  InputType,
  CodeData,
} from 'views/portal/agent/studio/workflow/model'
import DeleteButton from '../../components/delete-button'
import CustomHandle from '../../components/custom-handle'
import { getNewNodeLabel } from '../../utils'

// Define default values
const defaultInputs: InputField[] = [
  {
    name: 'message',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
]

const defaultOutputs: OutputField[] = [
  {
    name: 'message',
    type: 'String' as DataType,
    description: 'Message',
    required: true,
    children: [],
  },
]

const defaultCodeData: CodeData = {
  code: `# The function name must be 'main'
# The function must accept parameters that match the input fields defined in the form
# The function must return a value that matches the output fields defined in the form
def main(message: str) -> str:
    return {"message": message + " world"}
`,
}

const getDefaultNodeData = (label: string): NodeData => ({
  label,
  description: 'Execute custom code',
  input: defaultInputs,
  output: defaultOutputs,
  intentBranch: [],
  conditionBranch: [],
  branchOutput: {},
  extends: defaultCodeData,
})

const CodeNode: React.FC<CustomNodeProps> = observer((node) => {
  const { getNodes } = useReactFlow()

  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || getDefaultNodeData('Code Interpreter')
  )

  useEffect(() => {
    if (!node.data.data) {
      const newLabel = getNewNodeLabel(getNodes(), 'Code Interpreter')
      const newNodeData = getDefaultNodeData(newLabel)
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else if (node.data.data.label !== nodeData.label) {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node code-node active'
          : 'custom-node code-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <CodeOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <DeleteButton nodeID={node.id} />
      <CustomHandle
        node={node}
        type="source"
        position={Position.Right}
        isConnectable={node.isConnectable}
      />
    </div>
  )
})

export default CodeNode
