export enum OrganizationType {
  Owner = 'owner',
  Tenant = 'tenant',
}

export enum OrganizationStatusType {
  Active = 'active',
  Inactive = 'inactive',
}

export interface Organization {
  id: number
  name: string
  organizationType: OrganizationType
  status: OrganizationStatusType
  languageCode: string
  required2FA: boolean
  createdAt: string
  updatedAt: string
  expireDate: string
}

export interface OrganizationList {
  totalCount: number
  organizations: Organization[]
}

