import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Row, Col, Tag, MenuProps, Input, Flex } from 'antd'
import {
  DownOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import ConditionRow from './condition-row'
import { ConditionBranch } from '../../model'
import styles from './branch.scss'

interface BranchProps {
  branch: ConditionBranch
  branchIndex: number
  handleDescriptionChange: (branchIndex: number, description: string) => void
  handleConditionChange: (
    branchIndex: number,
    conditionIndex: number,
    name: string,
    value: string
  ) => void
  removeCondition: (branchIndex: number, conditionIndex: number) => void
  addCondition: (branchIndex: number) => void
  removeBranch: (branchIndex: number) => void
  getLogicMenu: (branchIndex: number) => MenuProps
}

const Branch: React.FC<BranchProps> = ({
  branch,
  branchIndex,
  handleDescriptionChange,
  handleConditionChange,
  removeCondition,
  addCondition,
  removeBranch,
  getLogicMenu,
}) => {
  const [description, setDescription] = useState(branch.description)

  useEffect(() => {
    setDescription(branch.description)
  }, [branch.description])

  return (
    <div className="branch-section">
      <Flex gap={12} align="center" style={{ margin: '12px 0' }}>
        <span>Description</span>
        <Input
          placeholder="Your branch description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
            handleDescriptionChange(branchIndex, e.target.value)
          }}
          required
        />
      </Flex>
      <div className={styles.branchTitle}>
        <span>{branch.priority === 1 ? 'IF' : 'IF ELSE'}</span>{' '}
        <Tag>Priority {branch.priority}</Tag>
      </div>
      <Flex>
        <div className={styles.conditionSelect}>
          {branch.conditions.length > 1 ? (
            <Dropdown
              menu={getLogicMenu(branchIndex)}
              trigger={['click']}
              placement="bottomLeft"
            >
              <Button
                className="nodrag nopan logic-select"
                icon={<DownOutlined />}
              >
                {branch.logicOperator}
              </Button>
            </Dropdown>
          ) : null}
        </div>
        <div className={styles.formList}>
          {branch.conditions.map((condition, conditionIndex) => (
            <ConditionRow
              key={conditionIndex}
              condition={condition}
              branch={branch}
              branchIndex={branchIndex}
              conditionIndex={conditionIndex}
              handleConditionChange={handleConditionChange}
              removeCondition={removeCondition}
            />
          ))}
        </div>
      </Flex>
      <Button
        onClick={() => addCondition(branchIndex)}
        icon={<PlusCircleOutlined />}
        type="link"
      >
        Add Condition
      </Button>
      {branchIndex > 0 && (
        <Button
          onClick={() => removeBranch(branchIndex)}
          icon={<MinusCircleOutlined />}
          type="link"
        >
          Remove Branch
        </Button>
      )}
    </div>
  )
}

export default Branch
