import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { HeaderTabsProps } from 'layouts/portal/header'

interface GlobalSettings {
  collapsed: boolean
  selectedMenu: string
}

interface HeaderBarProps {
  menuItems: NonNullable<HeaderTabsProps>
  selectedKey: string
}

class GlobalStore {
  globalSettings: GlobalSettings = {
    collapsed: false,
    selectedMenu: '',
  }

  header: HeaderBarProps = {
    menuItems: [],
    selectedKey: '',
  }

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'GlobalStore',
      properties: ['globalSettings'],
      storage: window.localStorage,
    })
  }

  setHeaderMenuItems(menuItems: NonNullable<HeaderTabsProps>) {
    runInAction(() => {
      this.header.menuItems = menuItems
    })
  }

  setHeaderSelectedKey(selectedKey: string): void {
    runInAction(() => {
      this.header.selectedKey = selectedKey
    })
  }

  toggle(): void {
    runInAction(() => {
      this.globalSettings.collapsed = !this.globalSettings.collapsed
    })
  }

  selectMenu(key: string): void {
    runInAction(() => {
      this.globalSettings.selectedMenu = key
    })
  }
}

export default new GlobalStore()
