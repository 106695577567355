import React, { ReactNode, useEffect } from 'react'
import { observer } from 'mobx-react'
import { App, Layout, theme } from 'antd'
import Logo from 'assets/images/ste-mini.png'
import Menu from 'layouts/portal/menu'
import roleStore from 'stores/role'
import userStore from 'stores/user'
import globalStore from 'stores/global'
import { getMessageFromError } from 'utils/common'
import { Outlet } from 'react-router-dom'
import HeaderNavbar from './header'

import './index.css'

const { Sider, Content, Header } = Layout

interface MainLayoutProps {
  children?: ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { message } = App.useApp()
  const {
    token: { borderRadiusLG },
  } = theme.useToken()

  useEffect(() => {
    const refreshCurrentUser = async () => {
      try {
        await roleStore.getList(1, 100)
        await userStore.getCurrentUser()
      } catch (error) {
        message.error(getMessageFromError(error))
      }
    }
    refreshCurrentUser()
  }, [])

  return (
    <Layout className="main-layout">
      <Sider
        collapsible
        collapsed={globalStore.globalSettings.collapsed}
        onCollapse={() => globalStore.toggle()}
        style={{ backgroundColor: '#fff' }}
      >
        <div className="mini-logo">
          <img src={Logo} />
        </div>
        <div />
        <Menu />
      </Sider>

      <Layout>
        <Header style={{ padding: 'unset' }}>
          <HeaderNavbar />
        </Header>

        <Content
          className="main-layout-content"
          style={{ borderRadius: borderRadiusLG }}
        >
          {children ?? <Outlet />}
        </Content>
      </Layout>
    </Layout>
  )
}

export default observer(MainLayout)
