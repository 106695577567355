import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { Modal, Button } from 'antd';
import DynamicForm from 'components/dynamic-form';
import { OutputField } from '../../model';

interface ResponseModalProps {
  isVisible: boolean;
  onOk: (fields: OutputField[]) => void;
  onCancel: () => void;
  initialValue: OutputField[];
}

export interface ResponseModalRef {
  getFields: () => OutputField[];
}

const ResponseModal = forwardRef<ResponseModalRef, ResponseModalProps>(
  ({ isVisible, onOk, onCancel, initialValue }, ref) => {
    const dynamicFormRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      getFields: () => {
        if (dynamicFormRef.current) {
          return dynamicFormRef.current.getFieldsValue();
        }
        return [];
      },
    }));

    const handleOk = () => {
      if (dynamicFormRef.current) {
        const fields = dynamicFormRef.current.getFieldsValue();
        onOk(fields);
      }
    };

    return (
      <Modal
        title="Response Structure Settings"
        open={isVisible}
        onOk={handleOk}
        onCancel={onCancel}
        width={1000}
      >
        <DynamicForm
          ref={dynamicFormRef}
          maxLevel={3}
          allowObject={true}
          allowArray={true}
          value={initialValue}
        />
      </Modal>
    );
  }
);

export default ResponseModal;
