import React, { useRef } from 'react'
import {
  App,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Switch,
} from 'antd'
import { ApartmentOutlined } from '@ant-design/icons'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import userStore from 'stores/user'
import organizationStore from 'stores/organization'
import { getMessageFromError, isEmpty } from 'utils/common'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import {
  Organization,
  OrganizationStatusType,
  OrganizationType,
} from 'stores/models/organization'
import { GLOBAL_DATE_FORMAT } from 'constants/common'
import { formatDate } from 'utils/filter'

export interface OrganizationModalProps {
  data: {
    id?: number
    name: string
    type: OrganizationType
    languageCode: string
    required2FA: boolean
    status: OrganizationStatusType
    expireDate: string
    createdAt?: string
    updatedAt?: string
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (userId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const OrganizationModal: React.FC<OrganizationModalProps> = (props) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const isAdmin = useMemo(
    () => userStore.loginUser?.roleName.toLowerCase() === 'admin',
    [userStore.loginUser]
  )

  useEffect(() => {
    if (props.open) {
      form.setFieldsValue({
        ...props.data,
        expireDate: props.data.expireDate ? dayjs(props.data.expireDate) : null,
      })
    }
  }, [props.open, form, props.data])

  const handleSave = async (values: OrganizationModalProps['data']) => {
    setConfirmLoading(true)
    try {
      if (isEmpty(values.expireDate)) {
        message.error('Expire date is required!')
        return
      }
      if (props.data.id == undefined) {
        const response = await organizationStore.create({
          name: values.name,
          organizationType: values.type,
          languageCode: values.languageCode,
          required2FA: values.required2FA,
          expireDate: values.expireDate,
        })
        const data = response.data as Organization
        await organizationStore.updateStatus(data.id, values.status)
        message.success('Organization created successfully!')
        return data.id
      } else {
        if (props.data.name !== values.name) {
          await organizationStore.updateName(props.data.id, values.name)
        }
        if (props.data.languageCode !== values.languageCode) {
          await organizationStore.updateLanguageCode(
            props.data.id,
            values.languageCode
          )
        }
        if (props.data.required2FA !== values.required2FA) {
          await organizationStore.updateRequired2FA(
            props.data.id,
            values.required2FA
          )
        }
        if (props.data.status !== values.status) {
          await organizationStore.updateStatus(props.data.id, values.status)
        }
        if (
          formatDate(props.data.expireDate) !== formatDate(values.expireDate)
        ) {
          await organizationStore.updateExpireDate(
            props.data.id,
            values.expireDate
          )
        }
        message.success('Organization updated successfully!')
      }
      return props.data.id
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: OrganizationModalProps['data']) => {
    const userId = await handleSave(values)
    if (userId === undefined) {
      return
    }
    if (props.onFinish) {
      props.onFinish(userId)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  return (
    <Modal
      title={props.title ?? 'Organization Edit'}
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 620 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input prefix={<ApartmentOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Please select one type!' }]}
        >
          <Select
            options={[
              { value: 'owner', label: 'Owner' },
              { value: 'tenant', label: 'Tenant' },
            ]}
            style={{ textTransform: 'capitalize' }}
            disabled={!isAdmin}
          />
        </Form.Item>
        <Form.Item
          name="languageCode"
          label="Language Code"
          rules={[{ required: true, message: 'Please input a language code!' }]}
        >
          <Input placeholder="Language code" />
        </Form.Item>
        <Form.Item name="required2FA" label="Required 2FA">
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select a status!' }]}
        >
          <Select
            options={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ]}
            style={{ textTransform: 'capitalize' }}
            disabled={!isAdmin}
          />
        </Form.Item>
        <Form.Item
          name="expireDate"
          label="Expire Date"
          rules={[{ required: true, message: 'Please select a date!' }]}
        >
          <DatePicker format={[GLOBAL_DATE_FORMAT]} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            {props.okText || 'Ok'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(OrganizationModal)
