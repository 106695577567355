import { Node, NodeProps } from '@xyflow/react'
import InputField from './components/input-field'

export const DataTypes = [
  'String',
  'Integer',
  'Boolean',
  'Number',
  'Date',
  'Datetime',
  'Object',
  'Document',
  'Image',
  'Audio',
  'Video',
  'Array<String>',
  'Array<Integer>',
  'Array<Boolean>',
  'Array<Number>',
  'Array<Date>',
  'Array<Datetime>',
  'Array<Object>',
  'Array<Document>',
  'Array<Image>',
  'Array<Audio>',
  'Array<Video>',
] as const

export type DataType = (typeof DataTypes)[number]

export type InputType = 'input' | 'reference'
export type HTTPMethod = 'POST' | 'GET' | 'PATCH' | 'PUT' | 'DELETE'
export type HTTPRequestType = 'webpage' | 'restapi'

// Define your custom node props
export interface NodeData extends Record<string, unknown> {
  label: string
  description: string
  input: InputField[]
  output: OutputField[]
  intentBranch: IntentBranch[]
  conditionBranch: ConditionBranch[]
  branchOutput: { [key: string]: BranchOutput }
  extends?: any
}

export interface APIData {
  timeout: number
  url: string
  method: HTTPMethod
  type: HTTPRequestType
  htmlFilter: boolean
  cache: boolean
  cacheTimeout: number
  requestHeader: InputField[]
  requestBody: InputField[]
  pathParameters: InputField[]
  queryParameters: InputField[]
}

// BranchOutput represents the output of a branch
export interface BranchOutput {
  branchID: string
  output: OutputField[]
}

// OutputField represents an input or output field in a node
export interface OutputField {
  name: string
  type: DataType
  description: string
  required: boolean
  children: OutputField[]
  expanded?: boolean
  error?: string
}

// InputField represents a form field in a node
export interface InputField {
  name: string
  type: InputType
  dataType: DataType
  value: any
  reference: string
}
// IntentSample
export interface IntentSample {
  question: string
  variables: [{ [key: string]: any }]
}
// IntentBranch represents a branch in a node
export interface IntentBranch {
  priority: number
  intent: string
  description: string
  isElse?: boolean
  isError?: boolean
  samples: IntentSample[]
  output: OutputField[]
}

// Condition represents a single condition in a branch
export interface Condition {
  variable: string
  condition: string
  comparison: string
  type: string
  value?: string
  reference?: string
}

// ConditionBranch represents a branch with conditions and logic operator
export interface ConditionBranch {
  id?: number
  priority: number
  description: string
  conditions: Condition[]
  logicOperator: string
  isElse?: boolean
  isError?: boolean
}

export interface CustomNodeProps extends NodeProps {
  data: {
    data?: NodeData
    onChange?: (id: string, data: any) => void
  }
}

export interface CodeData {
  code: string
}
