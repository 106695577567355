import {
  BankOutlined,
  RobotOutlined,
  MessageOutlined,
  BranchesOutlined,
  BookOutlined,
  ApiOutlined,
  CodeOutlined,
  FilterOutlined,
  FileOutlined,
  AimOutlined,
} from '@ant-design/icons'

export type NodeTypeEnum =
  | 'start'
  | 'llm'
  | 'end'
  | 'condition'
  | 'knowledge'
  | 'document_reader'
  | 'http'
  | 'code'
  | 'pii'
  | 'intent'

type NodeConfig = {
  icon: React.ReactNode
}

export const NODE_CONFIG: Record<NodeTypeEnum, NodeConfig> = {
  start: { icon: <BankOutlined /> },
  pii: { icon: <FilterOutlined /> },
  intent: { icon: <AimOutlined /> },
  code: { icon: <CodeOutlined /> },
  condition: { icon: <BranchesOutlined /> },
  http: { icon: <ApiOutlined /> },
  knowledge: { icon: <BookOutlined /> },
  document_reader: { icon: <FileOutlined /> },
  llm: { icon: <RobotOutlined /> },
  end: { icon: <MessageOutlined /> },
}
