import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useEffect,
  useMemo,
} from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Intent, IntentExample } from 'stores/models/intent'
import DynamicFormRendering, {
  DynamicFormRenderingRefProps,
} from 'components/dynamic-form-rendering'
import { convertIntentParameterToField } from './converter'
import { observer } from 'mobx-react'

const { TextArea } = Input
const { Text } = Typography

interface IntentExamplesFormProps {
  data: Intent
  style?: React.CSSProperties
}

export interface IntentExamplesFormRefProps {
  resetFields: () => void
  validateFields: () => Promise<void>
  getFieldsValue: () => IntentExample[]
}

interface FormExampleType {
  example: string
  parameters: any
}

const convertExamples = (examples: IntentExample[]): FormExampleType[] => {
  return examples.map((example) => ({
    example: example.example,
    parameters:
      typeof example.parameters === 'string'
        ? JSON.parse(example.parameters)
        : example.parameters,
  }))
}

const IntentExamplesForm: ForwardRefRenderFunction<
  IntentExamplesFormRefProps,
  IntentExamplesFormProps
> = (props: IntentExamplesFormProps, ref) => {
  const [form] = Form.useForm()
  const [examples, setExamples] = React.useState<FormExampleType[]>(
    convertExamples(props.data.examples)
  )
  const [error, setError] = React.useState<string | null>(null)
  const dynamicFormRenderingRefs = useRef<
    (DynamicFormRenderingRefProps | null)[]
  >([])

  useEffect(() => {
    const examples = convertExamples(props.data.examples)
    setExamples(examples)
  }, [props.data.examples])

  useImperativeHandle(ref, () => ({
    resetFields: () => {
      form.resetFields()
      dynamicFormRenderingRefs.current?.forEach((ref) => {
        ref?.resetFields()
      })
      const examples = convertExamples(props.data.examples)
      setExamples(examples)
    },
    validateFields: async () => {
      await form.validateFields()
      dynamicFormRenderingRefs.current?.forEach((ref) => {
        const isValid = ref?.validateFields()
        if (!isValid) {
          throw new Error('Validation failed')
        }
      })
    },
    getFieldsValue: () => {
      try {
        const result: IntentExample[] = []
        const values = form.getFieldsValue()
        dynamicFormRenderingRefs.current.forEach((ref, index) => {
          if (ref) {
            const isValid = ref.validateFields()
            if (!isValid) {
              throw new Error('Validation failed')
            }
            const parameters = ref.getFieldsValue()
            const example: IntentExample = {
              example: values.examples[index].example,
              parameters,
            }
            result.push(example)
          }
        })
        return result
      } catch (error) {
        console.error('Validation failed:', error)
        throw error
      }
    },
  }))

  const handleAddExample = () => {
    if (examples.length < 20) {
      setExamples([...examples, { example: '', parameters: {} }])
      form.resetFields(['exampleValidator'])
      setError(null)
    } else {
      setError('You can add up to 20 examples only.')
    }
  }

  const handleExampleChange = (index: number, values: any) => {
    const updatedExamples = examples.map((example, i) =>
      i === index ? { ...example, parameters: values } : example
    )
    setExamples(updatedExamples)
  }

  const handleDeleteExample = (index: number) => {
    const updatedExamples = examples.filter((_, i) => i !== index)
    setExamples(updatedExamples)
    dynamicFormRenderingRefs.current = dynamicFormRenderingRefs.current.filter(
      (_, i) => i !== index
    )
    setError(null)

    form.setFieldsValue({
      examples: updatedExamples.map((example) => ({
        example: example.example,
        parameters: example.parameters,
      })),
    })
  }

  const convertedFields = useMemo(
    () => convertIntentParameterToField(props.data.parameters),
    [props.data.parameters]
  )

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        indexName: props.data.intent,
        description: props.data.description,
      }}
      style={{ ...props.style }}
    >
      <Form.Item
        name="exampleValidator"
        rules={[
          {
            validator: (_, value) => {
              if (examples.length === 0) {
                return Promise.reject(
                  new Error('At least one example is required.')
                )
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input type="hidden" />
      </Form.Item>
      {examples.map((example, index) => {
        return (
          <div key={index} style={{ marginBottom: 24 }}>
            <Form.Item
              label={`Example ${index + 1}`}
              name={['examples', index, 'example']}
              rules={[{ required: true, message: 'Please enter a example' }]}
              initialValue={example.example}
            >
              <TextArea
                onChange={(e) => {
                  const newExamples = [...examples]
                  newExamples[index].example = e.target.value
                  setExamples(newExamples)
                }}
                placeholder="Enter example"
                autoSize
              />
            </Form.Item>
            <DynamicFormRendering
              ref={(el) => (dynamicFormRenderingRefs.current[index] = el)}
              fields={convertedFields}
              initialValues={example.parameters}
              onChange={(values) => handleExampleChange(index, values)}
            />
            <Button
              onClick={() => handleDeleteExample(index)}
              style={{ marginTop: 8, width: '100%' }}
              danger
              icon={<MinusCircleOutlined />}
            >
              Delete Example
            </Button>
          </div>
        )
      })}
      <Button
        type="dashed"
        onClick={handleAddExample}
        block
        style={{ marginBottom: 24 }}
        icon={<PlusOutlined />}
      >
        Add Example
      </Button>
      {error && (
        <Form.Item>
          <Text type="danger">{error}</Text>
        </Form.Item>
      )}
    </Form>
  )
}

export default observer(forwardRef(IntentExamplesForm))
