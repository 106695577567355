import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import useHeaderSubMenu from 'hooks/use-header-sub-menu'
import { HeaderTabsProps } from 'layouts/portal/header'

import './index.css'

interface OrganizationProps {}

const menuItems: HeaderTabsProps = [
  {
    key: 'management',
    label: 'Management',
    link: `/portal/organization/management`,
  },
  {
    key: 'members',
    label: 'Members',
    link: `/portal/organization/members`,
  },
  {
    key: 'roles',
    label: 'Roles',
    link: `/portal/organization/roles`,
  },
]

const Organization: React.FC<OrganizationProps> = () => {
  useHeaderSubMenu(menuItems)

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>Organization</h1>
            <p>Manage your organization settings, roles, members, and other.</p>
          </div>
        </div>
        <div className="organization-page">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default Organization
