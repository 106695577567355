import { useEffect, useState } from 'react'
import { Select, Form, Button, Space, Input, InputNumber, Col, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { assignIn } from 'lodash-es'
import WorkflowStore from 'stores/workflow'
import { ChannelType, Channel } from 'stores/models/channel'
import ChannelStore from 'stores/channel'
import CodeEditor from 'views/portal/agent/studio/workflow/components/code-editor'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'
import OpeningQuestions, {
  MAX_OPENING_QUESTIONS_LEN,
} from '../../opening-questions'
import CustomModal from 'components/custom-modal'
import CustomCompactInput, {
  CustomCompactInputValueItemType,
} from 'components/custom-compact-input'

import { getQuery } from 'utils/common'

import {
  DISCLAIMER_TEXT,
  TERMS_CONDITIONS,
  USER_GUIDE,
  CONVERSATION_OPENER,
  CSS_CHAT_PAGE,
  CSS_CHAT_BUBBLE,
} from '../../constant'

import styles from './index.scss'
import { v1PublishchannelCreateCreate } from 'api/Api'
import { message } from 'components/antd-static-function'

const { Option } = Select

type CreateEditChannelProps = {
  onCancel: () => void
  onSubmit: () => void
  channel?: Channel
}

const CreateEditChannel = (props: CreateEditChannelProps) => {
  const { id } = getQuery<StudioPageQueryType>(location.search)
  const { onCancel, onSubmit, channel } = props
  const [form] = Form.useForm()
  const inputOpeningQuestions = Form.useWatch<string[]>(
    'openingQuestions',
    form
  )
  const isEditMode = !!channel
  const isChannelTypeChange = (
    pre: Record<string, any>,
    cur: Record<string, any>
  ) => pre?.channelType !== cur?.channelType

  const [feedbackOperationLikeOptions, setFeedbackOperationLikeOptions] =
    useState<string>('')
  const [feedbackOperationUnlikeOptions, setFeedbackOperationUnlikeOptions] =
    useState<string>('')

  const formatCustomCompactInputValueInput = (
    str: string
  ): CustomCompactInputValueItemType[][] => {
    if (!str) return []
    return str.split(',').map((r) => {
      const [label, value] = r.split('|')
      return [
        { label: 'Label', value: label },
        { label: 'Value', value },
      ]
    })
  }

  const formatCustomCompactInputValueOutput = (
    val: CustomCompactInputValueItemType[][]
  ) => {
    if (!val) return ''
    return val
      .map((r) => {
        const [labelOption, valueOption] = r
        return `${labelOption.value}|${valueOption.value}`
      })
      .join(',')
  }

  const handleSubmit = async (values: any) => {
    try {
      const extraData = JSON.stringify(
        assignIn(values, {
          feedbackOperationUnlikeOptions,
          feedbackOperationLikeOptions,
        })
      )

      if (isEditMode && channel) {
        await ChannelStore.update(
          channel.id,
          Number(id) ?? 0,
          values.channelType,
          channel.enabled,
          extraData,
          values.channelName
        )
        // message.success('Channel updated successfully!')
      } else {
        await v1PublishchannelCreateCreate({
          agentID: Number(id) ?? 0,
          channelType: values.channelType,
          enabled: true,
          extraData: extraData,
          title: values.channelName,
        })
        message.success('Channel created successfully!')
      }

      form.resetFields()
      onSubmit()
    } catch (error) {
      // message.error(
      //   `Failed to ${
      //     isEditMode ? 'update' : 'create'
      //   } channel. Please try again.`
      // )
      console.error('Channel operation error:', error)
    }
  }

  useEffect(() => {
    if (!isEditMode || !channel) return
    const extraData = JSON.parse(channel.extraData || '{}')
    form.setFieldsValue(
      assignIn(
        {
          channelName: channel.title,
          channelType: channel.channelType,
        },
        extraData,
        {
          css: extraData.css || '',
          favicon: extraData.favicon || '',
          termsAndConditions: extraData.termsAndConditions || '',
          userGuide: extraData.userGuide || '',
          conversationOpener: extraData.conversationOpener || '',
          openingQuestions: extraData.openingQuestions || [],
        }
      )
    )

    setFeedbackOperationLikeOptions(
      form.getFieldValue('feedbackOperationLikeOptions')
    )
    setFeedbackOperationUnlikeOptions(
      form.getFieldValue('feedbackOperationUnlikeOptions')
    )
  }, [isEditMode, channel, form])

  useEffect(() => {
    if (isEditMode) return
    form.setFieldsValue({
      // common
      headerTitle: `ST Engineering Advisor`,
      subheader: `AI Advisor, solve all your problems`,
      inputPlaceholder: `Ask Anything...`,
      maxCharLength: 0,
      newChatButtonText: `New Chat`,
      faqButtonText: `Help`,
      conversationOpener: 'Welcome to AI Advisor!',
      openingQuestions: CONVERSATION_OPENER,
      disclaimerText: DISCLAIMER_TEXT,
      favicon: '',
      userGuide: USER_GUIDE,

      // chat-page
      termsAndConditions: TERMS_CONDITIONS,
      css: CSS_CHAT_PAGE,

      // chat-bubble
      cssChatBubble: CSS_CHAT_BUBBLE,
      iframeWidth: `390`,
      iframeHeight: `540`,
      iframeOrigin: location.origin,
    })
  }, [])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      className={styles.createEditChannelWrapper}
    >
      <CustomModal
        wrapperClassName={styles.createEditChannelModalWrapper}
        contentWrapperClassName={styles.createEditChannelModalContent}
        header={
          <div
            className={styles.createEditChannelNavbarWrapper}
            onClick={() => form.getFieldsValue()}
          >
            <div className={styles.createEditChannelNavbarLeft}>
              <Button type="text" icon={<LeftOutlined />} onClick={onCancel} />

              <span>{isEditMode ? 'Edit Channel' : 'Create Channel'}</span>
            </div>

            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => WorkflowStore.selectPanel('')}
            />
          </div>
        }
        footer={
          <Space>
            <Button type="primary" htmlType="submit">
              {isEditMode ? 'Update' : 'Submit'}
            </Button>

            <Button icon={<LeftOutlined />} onClick={onCancel}>
              Return
            </Button>
          </Space>
        }
      >
        <Form.Item
          name="channelName"
          label="Channel Name"
          rules={[
            { required: true, message: 'Please input the channel name!' },
          ]}
        >
          <Input placeholder="Enter channel name" />
        </Form.Item>
        <Form.Item
          name="channelType"
          label="Channel Type"
          rules={[
            { required: true, message: 'Please select the channel type!' },
          ]}
          initialValue={ChannelType.ChatPage}
        >
          <Select placeholder="Select channel type" disabled={isEditMode}>
            <Option value={ChannelType.ChatPage}>Chat Page</Option>
            <Option value={ChannelType.ChatBubble}>Chat Bubble</Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={isChannelTypeChange}>
          {(formProps) => {
            const channelType = formProps.getFieldValue('channelType')

            return (
              channelType === ChannelType.ChatBubble && (
                <Form.Item name="bubbleConfig" label="Bubble Config">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name={`iframeOrigin`}
                        label={`Iframe Origin`}
                        required
                      >
                        <Input placeholder="placeholder" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name={`iframeWidth`}
                        label={`Iframe Width`}
                        required
                      >
                        <Input placeholder="placeholder" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name={`iframeHeight`}
                        label={`Iframe Height`}
                        required
                      >
                        <Input placeholder="placeholder" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              )
            )
          }}
        </Form.Item>
        <Form.Item name="headerTitle" label="Header Title">
          <Input placeholder="Enter Header Title" />
        </Form.Item>
        <Form.Item name="subheader" label="Sub Header">
          <Input placeholder="Enter Sub Header" />
        </Form.Item>
        <Form.Item name="inputPlaceholder" label="Input Placeholder">
          <Input placeholder="Enter Input Placeholder" />
        </Form.Item>
        <Form.Item name="maxCharLength" label="Maximum Input Characters">
          <InputNumber
            placeholder="Enter Maximum Input Characters"
            min={0}
            precision={0}
            step={1}
            parser={(value) => {
              const parsed = parseInt(value?.replace(/[^0-9]/g, '') || '0', 10)
              return isNaN(parsed) ? 0 : parsed
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item name="newChatButtonText" label="New Chat Button Text">
          <Input placeholder="Enter New Chat Button Text" />
        </Form.Item>
        <Form.Item name="faqButtonText" label="FAQ Button Text">
          <Input placeholder="Enter FAQ Button Text" />
        </Form.Item>
        <Form.Item name="conversationOpener" label="Conversation Opener">
          <TextArea
            placeholder="Enter conversation opener"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        <Form.Item
          name="feedbackOperationLike"
          label={'Like Button (Tooltip text)'}
        >
          <Input placeholder="Enter Like Button Text" />
        </Form.Item>

        <Form.Item
          name="feedbackOperationCancelLike"
          label={'Cancel Like Button (Tooltip text)'}
        >
          <Input placeholder="Enter Like Button Text" />
        </Form.Item>

        <Form.Item
          name="feedbackOperationUnlike"
          label={'Unlike Button (Tooltip text)'}
        >
          <Input placeholder="Enter Unlike Button Text" />
        </Form.Item>

        <Form.Item
          name="feedbackOperationCancelUnlike"
          label={'Cancel Unlike Button (Tooltip text)'}
        >
          <Input placeholder="Enter Unlike Button Text" />
        </Form.Item>

        <div className={styles.createEditChannelLabelWrapper}>
          Feedback Like Options
        </div>
        <CustomCompactInput
          value={formatCustomCompactInputValueInput(
            feedbackOperationLikeOptions
          )}
          defaultValue={[
            { label: 'Label', value: '' },
            { label: 'Value', value: '' },
          ]}
          onChange={(v) =>
            setFeedbackOperationLikeOptions(
              formatCustomCompactInputValueOutput(v)
            )
          }
          wrapperClassName={styles.createEditChannelItemWrapper}
        />

        <div className={styles.createEditChannelLabelWrapper}>
          Feedback Unlike Options
        </div>
        <CustomCompactInput
          value={formatCustomCompactInputValueInput(
            feedbackOperationUnlikeOptions
          )}
          defaultValue={[
            { label: 'Label', value: '' },
            { label: 'Value', value: '' },
          ]}
          onChange={(v) =>
            setFeedbackOperationUnlikeOptions(
              formatCustomCompactInputValueOutput(v)
            )
          }
          wrapperClassName={styles.createEditChannelItemWrapper}
        />

        <Form.Item
          name="feedbackOperationPlaceholder"
          label={'Feedback Input Placeholder'}
        >
          <Input placeholder="Enter Operation Input Placeholder" />
        </Form.Item>
        <Form.Item
          name="openingQuestions"
          label={`Opening Questions (${
            inputOpeningQuestions?.length ?? 0
          }/${MAX_OPENING_QUESTIONS_LEN})`}
        >
          <OpeningQuestions />
        </Form.Item>
        <Form.Item name="disclaimerText" label="Disclaimer Text">
          <CodeEditor
            value={form.getFieldValue('disclaimerText') || ''}
            onChange={(value: string | undefined) =>
              form.setFieldsValue({ disclaimerText: value ?? '' })
            }
            language="html"
          />
        </Form.Item>
        <Form.Item name="termsAndConditions" label="Terms & Conditions">
          <CodeEditor
            value={form.getFieldValue('termsAndConditions') || ''}
            onChange={(value: string | undefined) =>
              form.setFieldsValue({ termsAndConditions: value ?? '' })
            }
            language="html"
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={isChannelTypeChange}>
          {(formProps) => {
            const channelType = formProps.getFieldValue('channelType')

            return (
              channelType === ChannelType.ChatPage && (
                <Form.Item name="favicon" label="Favicon(.ico Base64)">
                  <Input.TextArea placeholder="Favicon Base64" />
                </Form.Item>
              )
            )
          }}
        </Form.Item>
        <Form.Item name="userGuide" label="User Guide">
          <CodeEditor
            value={form.getFieldValue('userGuide') || ''}
            onChange={(value: string | undefined) =>
              form.setFieldsValue({ userGuide: value ?? '' })
            }
            language="html"
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={isChannelTypeChange}>
          {(formProps) => {
            const channelType = formProps.getFieldValue('channelType')

            return channelType === ChannelType.ChatPage ? (
              <Form.Item name="css" label="CSS">
                <CodeEditor
                  value={form.getFieldValue('css') || ''}
                  onChange={(value: string | undefined) =>
                    form.setFieldsValue({ css: value ?? '' })
                  }
                  language="css"
                />
              </Form.Item>
            ) : (
              <Form.Item name="cssChatBubble" label="CSS">
                <CodeEditor
                  value={form.getFieldValue('cssChatBubble') || ''}
                  onChange={(value: string | undefined) =>
                    form.setFieldsValue({ css: value ?? '' })
                  }
                  language="css"
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </CustomModal>
    </Form>
  )
}

export default CreateEditChannel
