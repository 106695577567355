import React, { useEffect, useState, useRef, ReactNode } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  Space,
  message,
  Row,
  Col,
  InputNumber,
  Modal,
} from 'antd'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  SaveOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import DynamicForm, { Field } from 'components/dynamic-form'
import apiStore from 'stores/api'
import { API } from 'stores/models/api'
import { observer } from 'mobx-react'
import { getMessageFromError } from 'utils/common'

const { TextArea } = Input
const { Option } = Select

const convertToField = (data: string): Field[] => {
  if (!data) return []
  try {
    const parsedData = JSON.parse(data)
    if (Array.isArray(parsedData)) {
      return parsedData
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}

interface ApiEditorProps {
  data: API
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: () => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ApiEditor: React.FC<ApiEditorProps> = (props) => {
  const [form] = Form.useForm()

  const dynamicFormRefs = {
    requestHeaders: useRef<any>(null),
    requestParams: useRef<any>(null),
    queryParams: useRef<any>(null),
    responseParams: useRef<any>(null),
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const extractPathParams = (uri: string): Field[] => {
    const regex = /\{(\w+)\}/g
    let match
    const params: Field[] = []
    while ((match = regex.exec(uri)) !== null) {
      params.push({
        name: match[1],
        type: 'String',
        description: '',
        required: true,
        expanded: true,
        children: [],
      })
    }
    return params
  }

  const handleSave = async (values: any) => {
    try {
      const requestHeadersErrors =
        (await dynamicFormRefs.requestHeaders.current?.validateFields()) || []
      const requestParamsErrors =
        (await dynamicFormRefs.requestParams.current?.validateFields()) || []
      const queryParamsErrors =
        (await dynamicFormRefs.queryParams.current?.validateFields()) || []
      const responseParamsErrors =
        (await dynamicFormRefs.responseParams.current?.validateFields()) || []

      const validationErrors = [
        ...requestHeadersErrors,
        ...requestParamsErrors,
        ...queryParamsErrors,
        ...responseParamsErrors,
      ]

      if (validationErrors.length > 0) {
        console.error('Validation failed:', validationErrors)
        return false
      }

      setIsSubmitting(true)
      const pathParams = extractPathParams(values.uri)
      const commonParams = {
        ...values,
        requestHeaders: JSON.stringify(
          dynamicFormRefs.requestHeaders.current?.getFields()
        ),
        requestParams: JSON.stringify(
          dynamicFormRefs.requestParams.current?.getFields()
        ),
        queryParams: JSON.stringify(
          dynamicFormRefs.queryParams.current?.getFields()
        ),
        pathParams: JSON.stringify(pathParams),
        responseParams: JSON.stringify(
          dynamicFormRefs.responseParams.current?.getFields()
        ),
      }

      if (props.data.id === 0) {
        await apiStore.create(
          values.apiName,
          values.description,
          values.uri,
          values.method,
          values.timeout,
          commonParams.requestHeaders,
          commonParams.requestParams,
          commonParams.queryParams,
          commonParams.pathParams,
          commonParams.responseParams
        )
      } else {
        await apiStore.update(
          props.data.id,
          values.apiName,
          values.description,
          values.uri,
          values.method,
          values.timeout,
          commonParams.requestHeaders,
          commonParams.requestParams,
          commonParams.queryParams,
          commonParams.pathParams,
          commonParams.responseParams
        )
      }

      const successMessage =
        props.data.id !== 0
          ? 'API updated successfully!'
          : 'API created successfully!'
      message.success(successMessage)

      return true
    } catch (error) {
      console.error('Unexpected error:', error)
      message.error(getMessageFromError(error))
    } finally {
      setIsSubmitting(false)
    }

    return false
  }

  const handleBack = () => {}

  const handleFinish = async (values: any) => {
    if (!(await handleSave(values))) {
      return
    }
    if (props.onFinish) {
      props.onFinish()
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  return (
    <Modal
      title={props.title || 'API Editor'}
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      width={1000}
      footer={[]}
      style={{ width: 'max-content' }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          apiName: props.data.apiName,
          description: props.data.description,
          uri: props.data.uri,
          method: props.data.method,
          timeout: props.data.timeout === 0 ? undefined : props.data.timeout,
        }}
        name="edit-api-form"
        onFinish={handleFinish}
      >
        <div className="basic">
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="apiName"
                label="API Name"
                rules={[
                  { required: true, message: 'Please input the API name!' },
                ]}
              >
                <Input placeholder="API Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="timeout" label="Timeout">
                <InputNumber min={0} max={100000} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="description" label="Description">
                <TextArea
                  placeholder="Description"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={16}>
              <Form.Item
                name="uri"
                label="URI"
                rules={[{ required: true, message: 'Please input the URI!' }]}
                tooltip={
                  <>
                    <span>
                      Use <code>{'{variable}'}</code> to make the URL support
                      variables.
                      <br />
                      For example: https://xxx.com/xxxx/
                      <code>{'{userID}'}</code>/<code>{'{pageNumber}'}</code>
                    </span>
                  </>
                }
              >
                <Input placeholder="https://" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="method"
                label="Method"
                rules={[
                  { required: true, message: 'Please select the method!' },
                ]}
              >
                <Select placeholder="Select a method">
                  <Option value="GET">GET</Option>
                  <Option value="POST">POST</Option>
                  <Option value="PUT">PUT</Option>
                  <Option value="DELETE">DELETE</Option>
                  <Option value="PATCH">PATCH</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="dynamic">
          <Form.Item label="Request Headers">
            <DynamicForm
              ref={dynamicFormRefs.requestHeaders}
              allowObject={false}
              allowArray={false}
              value={convertToField(props.data.requestHeaders || '[]')}
            />
          </Form.Item>
        </div>
        {['POST', 'PUT', 'PATCH'].includes(props.data.method) && (
          <div className="dynamic">
            <Form.Item label="Request Body">
              <DynamicForm
                ref={dynamicFormRefs.requestParams}
                allowObject={true}
                allowArray={true}
                value={convertToField(props.data.requestParams || '[]')}
              />
            </Form.Item>
          </div>
        )}
        <div className="dynamic">
          <Form.Item label="Query Parameters">
            <DynamicForm
              ref={dynamicFormRefs.queryParams}
              allowObject={false}
              allowArray={false}
              value={convertToField(props.data.queryParams || '[]')}
            />
          </Form.Item>
        </div>
        <div className="dynamic">
          <Form.Item label="Response Parameters">
            <DynamicForm
              ref={dynamicFormRefs.responseParams}
              allowObject={true}
              allowArray={true}
              value={convertToField(props.data.responseParams || '[]')}
            />
          </Form.Item>
        </div>
        <Space>
          <Button
            type="default"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            icon={props.data.id !== 0 ? <SaveOutlined /> : <PlusOutlined />}
          >
            {props.data.id !== 0 ? 'Save' : 'Create API'}
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}

export default observer(ApiEditor)
