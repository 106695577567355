import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { observer } from 'mobx-react'
import OrganizationTable, {
  ActionType,
  OrganizationTableRef,
} from './organization-table'
import OrganizationModal, { OrganizationModalProps } from './organization-modal'
import {
  OrganizationStatusType,
  OrganizationType,
} from 'stores/models/organization'
import { deepCopy } from 'utils/common'

const DEFAULT_ORGANIZATION_DATA: OrganizationModalProps['data'] = {
  name: '',
  type: OrganizationType.Owner,
  status: OrganizationStatusType.Active,
  languageCode: 'en',
  required2FA: true,
  expireDate: '',
}

const OrganizationPage: React.FC = () => {
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false)
  const [organization, setOrganization] = useState<
    OrganizationModalProps['data']
  >(DEFAULT_ORGANIZATION_DATA)
  const organizationTableRef = React.createRef<OrganizationTableRef>()

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1></h1>
            <p></p>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setOrganization(deepCopy(DEFAULT_ORGANIZATION_DATA))
                setIsOrganizationModalOpen(true)
              }}
            >
              Create Organization
            </Button>
          </div>
        </div>
        <OrganizationTable
          ref={organizationTableRef}
          onAction={(type, organization) => {
            if (type === ActionType.Edit) {
              setOrganization({
                id: organization.id,
                name: organization.name,
                type: organization.organizationType,
                languageCode: organization.languageCode,
                required2FA: organization.required2FA,
                status: organization.status,
                expireDate: organization.expireDate,
              })
              setIsOrganizationModalOpen(true)
            }
          }}
        />
        <OrganizationModal
          open={isOrganizationModalOpen}
          title={
            !!organization.id ? 'Edit Organization' : 'Create Organization'
          }
          okText="Save"
          data={{
            ...organization,
          }}
          onFinish={() => {
            organizationTableRef.current?.refresh()
            setIsOrganizationModalOpen(false)
          }}
          onCancel={() => setIsOrganizationModalOpen(false)}
        />
      </div>
    </>
  )
}

export default observer(OrganizationPage)
