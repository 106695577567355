import React, { useState } from 'react'
import {
  App,
  Avatar,
  Col,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Spin,
  Tooltip,
} from 'antd'
import {
  FolderOutlined,
  FormOutlined,
  DeleteOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { observer } from 'mobx-react'
import knowledgeStore from 'stores/knowledge'
import './knowledge-card.css'
import { getMessageFromError } from 'utils/common'
import KnowledgeModal from './knowledge-modal'
import { formatFileSize } from 'utils/filter'
import { useExNavigate } from 'hooks/use-ex-navigate'

export interface KnowledgeCardProps {
  data: {
    id: number
    name: string
    description: string
    documentCount: number
    documentSize: number
  }
}

const KnowledgeCard: React.FC<KnowledgeCardProps> = ({ data }) => {
  const [isKnowledgeModalOpen, setIsKnowledgeModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { message, modal } = App.useApp()
  const navigate = useExNavigate()

  const handleOpenClick = () => {
    navigate('/portal/knowledge/document', {
      state: { knowledge: data },
    })
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'profile') {
      setIsKnowledgeModalOpen(true)
    } else if (e.key === 'delete') {
      modal.confirm({
        title: 'Are you sure you want to delete this knowledge?',
        content:
          'This action cannot be undone and will permanently delete the knowledge from the system.',
        okText: 'Yes, Delete it',
        okType: 'danger',
        cancelText: 'No, Cancel',
        async onOk() {
          setLoading(true)
          try {
            await knowledgeStore.delete(data.id)
            message.success('The knowledge has been successfully deleted.')
          } catch (error) {
            message.error(getMessageFromError(error))
          }
          setLoading(false)
        },
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      label: 'Edit Info',
      key: 'profile',
      icon: <SettingOutlined />,
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <DeleteOutlined />,
    },
  ]

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className="knowledge-card">
        <Row gutter={24}>
          <Col className="knowledge-icon" span={6}>
            <Avatar shape="square" size={60} icon={<FolderOutlined />} />
          </Col>
          <Col className="knowledge-icon" span={18}>
            <div className="knowledge-name">
              <Tooltip title={data.name.length > 20 ? data.name : undefined}>
                {data.name}
              </Tooltip>
            </div>
            <Space className="knowledge-info" size="small">
              <span>{data.documentCount} docs</span>·
              <span>{formatFileSize(data.documentSize)}</span>
            </Space>
          </Col>
        </Row>
        <Row>
          <div className="knowledge-description">{data.description}</div>
        </Row>
        <Row gutter={24}>
          <Col span={10}></Col>
          <Col span={14}>
            <Dropdown.Button menu={menuProps} onClick={handleOpenClick}>
              <FormOutlined /> Manage
            </Dropdown.Button>
          </Col>
        </Row>
      </div>
      <KnowledgeModal
        open={isKnowledgeModalOpen}
        data={{ id: data.id, name: data.name, description: data.description }}
        onFinish={() => setIsKnowledgeModalOpen(false)}
        onCancel={() => setIsKnowledgeModalOpen(false)}
      />
    </Spin>
  )
}

export default observer(KnowledgeCard)
