import { IntentParameter } from 'stores/models/intent'
import { OutputField as Field  } from 'views/portal/agent/studio/workflow/model'

export const convertIntentParameterToField = (
  params?: IntentParameter[]
): Field[] => {
  if (!params) {
    return []
  }
  return params.map((param) => ({
    name: param.name,
    type: param.type,
    description: param.description,
    required: param.required,
    children: convertIntentParameterToField(param.children),
    expanded: true,
  }))
}

export const convertFieldToIntentParameter = (
  fields: Field[],
  deep: number = 99
): IntentParameter[] => {
  return fields.map((field) => ({
    name: field.name,
    type: field.type,
    description: field.description,
    required: field.required,
    children:
      deep === 1 ? [] : convertFieldToIntentParameter(field.children, deep - 1),
  }))
}
