import React from 'react'

const Dashboard: React.FC = () => {
  return (
    <>
      <div className="main-layout-main">
        <div>Welcome Dashboard</div>
      </div>
    </>
  )
}

export default Dashboard
