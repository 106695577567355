import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react'
import { observer } from 'mobx-react'
import { Table, Button, Tooltip, Popconfirm, App } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import intentStore from 'stores/intent'
import { Intent, IntentExample } from 'stores/models/intent'
import { getMessageFromError } from 'utils/common'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

interface IntentTableProps {
  onAction?: (type: ActionType, intent: Intent) => void
}

const IntentTable: ForwardRefRenderFunction<any, IntentTableProps> = (
  props,
  ref
) => {
  const { message } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [deletingId, setDeletingId] = useState(0)

  useImperativeHandle(ref, () => ({
    refresh: () => load(1),
  }))

  const load = async (page: number) => {
    setLoading(true)
    try {
      await intentStore.getList(page, size)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  const deleteIntent = async (key: number) => {
    setDeletingId(key)
    try {
      await intentStore.delete(key)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingId(0)
    load(page)
  }

  useEffect(() => {
    load(1)
  }, [])

  const columns = [
    {
      title: 'Intent',
      dataIndex: 'intent',
    },
    {
      title: 'Examples',
      dataIndex: 'examples',
      render: (text: string, record: Intent) => {
        return (
          <div key={'examples_' + record.id}>
            {record.examples.map((example: IntentExample, index: number) => (
              <div key={index}>{example.example}</div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Parameters',
      dataIndex: 'parameters',
      render: (text: string, record: Intent) => {
        return (
          <div key={'parameters_' + record.id}>
            {record.parameters.map((param, index: number) => (
              <div key={index}>{param.name}</div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Operation',
      width: 120,
      render: (text: any, record: Intent) => {
        return (
          <div key={'action_' + record.id}>
            <Tooltip placement="top" title="Edit Intent">
              <Button
                key={'edit_' + record.id}
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  props.onAction && props.onAction(ActionType.Edit, record)
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete Intent">
              <Popconfirm
                key={'popconfirm_' + record.id}
                title="Are you sure to delete the intent?"
                onConfirm={() => deleteIntent(record.id)}
              >
                <Button
                  style={{ marginLeft: '8px' }}
                  key={'delete_' + record.id}
                  icon={<DeleteOutlined />}
                  size="small"
                  loading={deletingId === record.id}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={intentStore.intentList.intents}
      columns={columns}
      loading={loading}
      rowKey="id"
      pagination={{
        current: page,
        pageSize: size,
        total: intentStore.intentList.totalCount,
        position: ['bottomLeft'],
        onChange: (page, pageSize) => {
          setPage(page)
          load(page)
        },
      }}
    />
  )
}

export default observer(forwardRef(IntentTable))
