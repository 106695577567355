import { makeAutoObservable } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { Channel, ChannelList, ChannelType } from './models/channel'
import { CodeMsgResponse } from './models/common'

class ChannelStore {
  channelList: ChannelList = {
    totalCount: 0,
    publishChannels: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  // async create(
  //   agentID: number,
  //   channelType: ChannelType,
  //   enabled: boolean,
  //   extraData: string,
  //   title: string
  // ) {
  //   const params: RequestData = {
  //     body: {
  //       agentID,
  //       channelType,
  //       enabled,
  //       extraData,
  //       title,
  //     },
  //   }

  //   const response = await createApiRequest<Channel | CodeMsgResponse>(
  //     apiConfig.channelCreate,
  //     params
  //   )
  //   return response
  // }

  async update(
    id: number,
    agentID: number,
    channelType: ChannelType,
    enabled: boolean,
    extraData: string,
    title: string
  ) {
    const params: RequestData = {
      body: {
        id,
        agentID,
        channelType,
        enabled,
        extraData,
        title,
      },
    }

    const response = await createApiRequest<Channel | CodeMsgResponse>(
      apiConfig.channelUpdate,
      params
    )

    const index = this.channelList.publishChannels.findIndex(
      (channel) => channel.id === id
    )
    if (index !== -1) {
      this.channelList.publishChannels[index] = {
        ...this.channelList.publishChannels[index],
        ...params.body,
      }
    }

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest(apiConfig.channelDelete, {
      queryParams: { id },
    })
    this.channelList = {
      totalCount: this.channelList.totalCount - 1,
      publishChannels: this.channelList.publishChannels.filter(
        (channel) => channel.id !== id
      ),
    }
    return response
  }

  async getList(agentID: number, page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        agent_id: agentID,
        page,
        size,
      },
    }

    const response = await createApiRequest<ChannelList>(
      apiConfig.channelList,
      params
    )
    this.channelList = {
      totalCount: response.data.totalCount,
      publishChannels: response.data.publishChannels,
    }
    return response
  }
}

export default new ChannelStore()
