import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  RouteObject,
} from 'react-router-dom'
import Agent from 'views/portal/agent'
import Chat from 'views/portal/agent/chat'
import Studio from 'views/portal/agent/studio'
import Workflow from 'views/portal/agent/studio/workflow'
import Permission from 'views/portal/permission'
import OrganizationManagement from 'views/portal/organization/management'
import Role from 'views/portal/organization/role'
import User from 'views/portal/organization/user'
import Knowledge from 'views/portal/knowledge'
import Document from 'views/portal/knowledge/document'
import DocumentUpload from 'views/portal/knowledge/document/upload'
import Chunk from 'views/portal/knowledge/chunk'
import Integration from 'views/portal/integration'
import Intent from 'views/portal/intent'
import Organization from 'views/portal/organization'
import Dashboard from 'views/portal/dashboard'
import NotFound from 'views/not-found'
import MainLayout from 'layouts/portal'

import { lazy } from 'react'

const routes: RouteObject[] = [
  // Extra Pages
  {
    path: 'chat-bubble/:uuid',
    Component: lazy(() => import('views/chat-bubble')),
  },
  {
    path: 'chat/:uuid',
    Component: lazy(() => import('views/new-chat')),
    // Component: lazy(() => import('views/chat')),
  },
  {
    path: 'login',
    Component: lazy(() => import('views/login')),
  },

  // Not found
  {
    path: '*',
    Component: lazy(() => import('views/not-found')),
  },

  // Portal Pages
  { path: '', element: <Navigate to="portal" /> },
  {
    path: 'portal',
    element: <MainLayout />,
    children: [
      { index: true, element: <Navigate to="dashboard" replace /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'permission', element: <Permission /> },
      {
        path: 'organization',
        element: <Organization />,
        children: [
          { index: true, element: <Navigate to="management" replace /> },
          { path: 'management', element: <OrganizationManagement /> },
          { path: 'members', element: <User /> },
          { path: 'roles', element: <Role /> },
        ],
      },

      // agent
      { path: 'agent', element: <Agent /> },
      { path: 'agent/chat', element: <Chat /> },
      {
        path: 'agent/studio',
        element: <Studio />,
        children: [
          { index: true, element: <Navigate to="workflow" replace /> },
          { path: 'workflow', element: <Workflow /> },
          { path: 'evaluation', element: <NotFound /> },
        ],
      },

      { path: 'intent', element: <Intent /> },
      { path: 'knowledge', element: <Knowledge /> },
      { path: 'knowledge/document', element: <Document /> },
      { path: 'knowledge/document/upload', element: <DocumentUpload /> },
      { path: 'knowledge/document/chunk', element: <Chunk /> },
      { path: 'integration', element: <Integration /> },
    ],
  },
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/portal/test',
    Component: lazy(() => import('views/test')),
  })

  console.groupCollapsed('routes')
  console.log(routes)
  console.groupEnd()
}

const router = createBrowserRouter(routes)

const DefaultRouter = () => {
  return <RouterProvider router={router} />
}

export default DefaultRouter
