import { GetProp, UploadProps } from 'antd'
import config from 'config'
import apiConfig from 'services/api'

export const getMessageFromError = (error: any) => {
  let message = 'Oops! We encountered an issue. Please retry in a few moments.'
  if (error?.response?.data) {
    message = error.response?.data?.message ?? message
  } else if (error?.message) {
    message = error.message
  }
  return message
}

export type UploadFileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

export const getBase64 = (
  img: UploadFileType,
  callback: (url: string) => void
) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const isEmpty = (data: undefined | null | string) => {
  return data === undefined || data === null || data.toString().trim() === ''
}

export const getStaticUrl = (path: string) => {
  return new URL(config.STATIC_URL + path).toString()
}

export const normalizePath = (...paths: string[]) => {
  return paths.join('/').replace(/(?<!:)\/{2,}/g, '/')
}

export const getFileUrl = (uuid: string) => {
  if (isEmpty(uuid)) {
    return ''
  }

  let url = apiConfig.file.url
  if (!url.endsWith('/')) {
    url += '/'
  }

  if (uuid.startsWith('/')) {
    uuid = uuid.substring(1)
  }

  return new URL(normalizePath(config.API_GATEWAY, url + uuid)).toString()
}

export const escapeRegExp = (str: string) => {
  if (str === undefined || str === null) {
    return ''
  }
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const getUserLanguage = () => {
  return navigator.language
}

export const sleep = (delay: number) =>
  new Promise((fn) => setTimeout(fn, delay))

export function safeJsonParse<T = any>(jsonString: string): T | undefined
export function safeJsonParse<T = any>(jsonString: string, fallback: T): T
export function safeJsonParse<T = any>(
  jsonString: string,
  fallback?: any
): any {
  try {
    return JSON.parse(jsonString) as T
  } catch (error) {
    console.error('JSON parse error:', error)
    if (typeof fallback === 'function') return fallback()
    if (fallback === undefined) return fallback
  }
}

export const deepCopy = <T extends unknown>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj))
}

export const getQuery = <T extends NonNullable<Record<string, any>>>(
  search: string
): T => {
  const res = {} as T
  if (!search) return res
  const searchParams = new URLSearchParams(search)

  for (const i of searchParams.entries()) {
    const [k, v] = i
    Reflect.set(res, k, v)
  }
  return res
}

export const setQuery = (params: Record<string, any>): string =>
  Object.entries(params)
    .map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
    .join('&') ?? ''

export const addQueryParameter = (key: string, value: string) => {
  const url = new URL(location.href)
  url.searchParams.set(key, value)
  history.pushState({}, '', url)
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const validateRegex = (regex: string, ignoreEmpty: boolean = true) => {
  let message = ''
  try {
    if (!ignoreEmpty || regex !== '') {
      new RegExp(regex)
    }
  } catch (e) {
    message = (e as Error).message
  }
  return {
    valid: message === '',
    message,
  }
}
