import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { Permission, PermissionTree } from 'stores/models/permission'
import { OrganizationType } from 'stores/models/organization'
import { CodeMsgResponse } from './models/common'

class PermissionStore {
  permissionTree: PermissionTree = {
    permissions: [],
  }

  permissionsMap: Map<string, Permission> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  async getTree(type: OrganizationType) {
    const params: RequestData = {
      queryParams: {
        organizationType: type,
      },
    }
    const response = await createApiRequest<PermissionTree | CodeMsgResponse>(
      apiConfig.permissionTree,
      params
    )

    runInAction(() => {
      this.permissionTree = response.data as PermissionTree
      this.permissionsMap = this.convertPermissionTreeToMap(this.permissionTree)
    })

    return response
  }

  convertPermissionTreeToMap(tree: PermissionTree) {
    const map: Map<string, Permission> = new Map()
    const handlePermission = (permissions: Permission[]) => {
      permissions.forEach((permission) => {
        map.set(permission.identity, permission)
        if (permission.subs) {
          handlePermission(permission.subs)
        }
      })
    }
    handlePermission(tree.permissions)
    return map
  }

  getPermissionById(identity: string) {
    return this.permissionsMap.get(identity)
  }

  convertPermissionString(permissions: string) {
    return permissions.split(',').map((permission) => permission.trim())
  }
}

export default new PermissionStore()
