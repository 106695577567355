import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  ReactNode,
} from 'react'
import { observer } from 'mobx-react'
import { Table, Input, Button, Space, Popconfirm, App, Flex, Tag } from 'antd'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import roleStore from 'stores/role'
import permissionStore from 'stores/permission'
import { Role } from 'stores/models/role'
import { getMessageFromError } from 'utils/common'
import { OrganizationType } from 'stores/models/organization'
import { PermissionType } from 'stores/models/permission'

export enum ActionType {
  Edit = 'edit',
  Delete = 'delete',
}

export interface RoleTableProps {
  onAction?: (type: ActionType, role: Role) => void
}

export interface RoleTableRefProps {
  refresh: () => void
}

const RoleTable: ForwardRefRenderFunction<RoleTableRefProps, RoleTableProps> = (
  props,
  ref
) => {
  const { message, modal } = App.useApp()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deletingId, setDeletingId] = useState(0)

  useImperativeHandle(ref, () => ({
    refresh: () => load(1, size),
  }))

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      await permissionStore.getTree(OrganizationType.Tenant)
      await roleStore.getList(page, size)
      setTotal(roleStore.roleList.total_count)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  const deleteRole = async (id: number) => {
    setDeletingId(id)
    try {
      await roleStore.delete(id)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
    setDeletingId(0)
    load(page, size)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: string, record: Role) => {
        return <div key={'name_' + record.id}>{text}</div>
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: (text: boolean, record: Role) => {
        return (
          <div
            key={'owner_' + record.id}
            style={{ textTransform: 'capitalize' }}
          >
            {text ? 'true' : 'false'}
          </div>
        )
      },
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      render: (permissions: PermissionType[], record: Role) => {
        const tags: ReactNode[] = []
        if (record.owner) {
          tags.push(<Tag>All Permissions</Tag>)
        } else {
          tags.push(
            ...permissions.map((permission) => (
              <Tag>{permissionStore.getPermissionById(permission)?.name}</Tag>
            ))
          )
          if (tags.length === 0) tags.push(<Tag>No permissions</Tag>)
        }
        return <div key={'permissions_' + record.id}>{tags}</div>
      },
    },
    {
      title: 'Actions',
      render: (_: any, record: Role) => {
        return (
          <Flex key={'actions_' + record.id} gap="6px">
            <Button
              key={'edit_' + record.id}
              icon={<EditOutlined />}
              size="small"
              disabled={false}
              onClick={() => {
                props.onAction && props.onAction(ActionType.Edit, record)
              }}
            />
            <Popconfirm
              key={'popconfirm_' + record.id}
              title="Are you sure to delete the document?"
              onConfirm={() => deleteRole(record.id)}
            >
              <Button
                key={'delete_' + record.id}
                icon={<DeleteOutlined />}
                size="small"
                disabled={false}
                loading={deletingId === record.id}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={roleStore.roleList.roles}
      columns={columns}
      loading={loading}
      pagination={{
        current: page,
        pageSize: size,
        total: total,
        position: ['bottomLeft'],
        onChange: (page, size) => {
          setPage(page)
          setPage(size)
        },
      }}
    />
  )
}

export default observer(forwardRef(RoleTable))
