import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import { handleBeforeMounted } from './global'

import './assets/css/index.css'

handleBeforeMounted()

const rootElement = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
