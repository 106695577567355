// TemplateEditor.tsx
import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import { Button, Space, Tooltip, Divider, Modal } from 'antd';
import { PlusOutlined, FullscreenOutlined, BranchesOutlined, ReloadOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Node, Edge } from '@xyflow/react';
import EditorComponent from './editor-component'

interface Variable {
  name: string;
  nodeName: string;
  type: string;
  nodeID: string;
}

interface TemplateEditorProps {
  value: string;
  onChange: (value: string | undefined) => void;
  className?: string;
  currentNodeID: string;
  nodes: Node[];
  edges: Edge[];
}

const TemplateEditor: React.FC<TemplateEditorProps> = React.memo(({
  value,
  onChange,
  className = '',
  currentNodeID,
  nodes,
  edges,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [editorValue, setEditorValue] = useState(value);

  const getRelevantParentNodes = useCallback((
    nodeID: string,
    nodes: Node[],
    edges: Edge[],
    visited: Set<string> = new Set()
  ): Node[] => {
    if (visited.has(nodeID)) return [];
    visited.add(nodeID);

    const parentEdges = edges.filter(edge => edge.target === nodeID || edge.target.split('-')[0] === nodeID);
    const parentNodeIDs = parentEdges.map(edge => edge.source);
    const parentNodes = nodes.filter(node => parentNodeIDs.includes(node.id));

    let allRelevantParentNodes: Node[] = [...parentNodes];
    parentNodes.forEach(parentNode => {
      allRelevantParentNodes = [
        ...allRelevantParentNodes,
        ...getRelevantParentNodes(parentNode.id, nodes, edges, visited),
      ];
    });

    return allRelevantParentNodes;
  }, []);

  const calculateVariables = useCallback((nodeID: string, nodes: Node[], edges: Edge[]): Variable[] => {
    const relevantParentNodes = getRelevantParentNodes(nodeID, nodes, edges)
    const variables: Variable[] = [];
    const processedNodes = new Set<string>()

    const processOutputs = (outputs: any[], prefix: string = '', nodeID: string, nodeName: string) => {
      outputs.forEach((output) => {
        if (output.type === 'Object' && output.children) {
          processOutputs(output.children, `${prefix}${output.name}.`, nodeID, nodeName)
        } else {
          variables.push({
            name: `${prefix}${output.name}`,
            nodeName: nodeName,
            type: output.type,
            nodeID: nodeID,
          });
        }
      });
    };

    relevantParentNodes.forEach((node) => {
      if (processedNodes.has(node.id)) return
      processedNodes.add(node.id);

      const data = node.data.data as any
      if (data) {
        const nodeName = data.label || ''

        if (data && data.output && Array.isArray(data.output)) {
          processOutputs(data.output, `${node.id}.`, node.id, nodeName);
        }
        if (data.branchOutput) {
          Object.keys(data.branchOutput).forEach((handleKey) => {
            const isBranchConnected = edges.some(
              (edge) =>
                edge.source === node.id &&
                (edge.target === currentNodeID || relevantParentNodes.some(parentNode => edge.target === parentNode.id)) &&
                edge.sourceHandle === handleKey
            );
            if (isBranchConnected && data.branchOutput[handleKey].output) {
              processOutputs(data.branchOutput[handleKey].output, `${node.id}.${handleKey}.`, node.id, nodeName);
            }
          });
        }
      }

    });
    return variables;
  }, [getRelevantParentNodes, currentNodeID]);

  const variables = useMemo(() => {
    return calculateVariables(currentNodeID, nodes, edges);
  }, [calculateVariables, currentNodeID, nodes, edges]);

  const codeSnippets = useMemo(() => [
    {
      name: 'If-Else',
      code: `{{/* If-Else statement for tailoring LLM instructions based on context */}}
{{if .IsExpert}}
You are an expert in {{.Field}}. Please provide a detailed, technical explanation of {{.Topic}}.
{{else}}
Please explain {{.Topic}} in simple terms, as if you're talking to a beginner.
{{end}}

Additional context:
{{.AdditionalContext}}

Please structure your response as follows:
1. Brief introduction
2. Main explanation
3. Key takeaways`,
      icon: <BranchesOutlined />
    },
    {
      name: 'For Loop',
      code: `{{/* For loop for generating a prompt with multiple elements */}}
I will provide you with a list of items. For each item, please provide a brief description and its significance.

{{range $index, $item := .ItemList}}
Item {{inc $index}}: {{$item}}
{{end}}

For each item, structure your response as follows:
- Item name
- Brief description (1-2 sentences)
- Significance or impact

Please ensure your explanations are concise and clear.`,
      icon: <ReloadOutlined />
    },
    {
      name: 'With',
      code: `{{/* With statement for providing context in LLM prompt */}}
{{with .UserProfile}}
Given the following user profile, provide personalized recommendations:

Name: {{.Name}}
Age: {{.Age}}
Interests: {{join .Interests ", "}}
Occupation: {{.Occupation}}

{{end}}
Based on this profile, please provide:
1. Three book recommendations
2. Two hobby suggestions
3. One career development tip

For each recommendation, briefly explain why it's suitable for this user.`,
      icon: <ApartmentOutlined />
    },
  ], []);

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
  }, []);

  const handleChange = useCallback((newValue: string | undefined) => {
    setEditorValue(newValue || '');
    onChange(newValue);
  }, [onChange]);

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  const editorProps = {
    value: editorValue,
    onChange: handleChange,
    variables,
    codeSnippets,
    onToggleFullscreen: toggleFullscreen
  };

  return (
    <div className={`template-editor-container ${className}`}>
      <EditorComponent {...editorProps} isFullscreen={false} />
      <Modal
        title="Template Editor"
        open={isFullscreen}
        onCancel={toggleFullscreen}
        width="90%"
        footer={null}
        styles={{body: { height: 'calc(90vh - 110px)' }}}
      >
        <EditorComponent {...editorProps} isFullscreen={true} />
      </Modal>
    </div>
  );
});

export default TemplateEditor;