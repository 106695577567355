import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { RequestData } from 'services/request'
import { createApiRequest } from 'services/request'
import {
  Organization,
  OrganizationType,
  OrganizationList,
  OrganizationStatusType,
} from 'stores/models/organization'
import { CodeMsgResponse } from './models/common'

type CreateOrganizationType = Omit<
  Organization,
  'id' | 'status' | 'createdAt' | 'updatedAt'
>

class OrganizationStore {
  organizationList: OrganizationList = {
    totalCount: 0,
    organizations: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async create(org: CreateOrganizationType) {
    const params: RequestData = {
      body: {
        ...org,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationCreate,
      params
    )
    return response
  }

  async update(org: Organization) {
    const params: RequestData = {
      body: {
        ...org,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationUpdate,
      params
    )
    const index = this.organizationList.organizations.findIndex(
      (organization) => organization.id === org.id
    )
    if (index !== -1) {
      this.organizationList.organizations[index] = {
        ...this.organizationList.organizations[index],
        ...params.body,
      }
    }
    return response
  }

  async updateName(id: number, name: string) {
    const params: RequestData = {
      body: {
        id,
        name,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationUpdateName,
      params
    )
    const index = this.organizationList.organizations.findIndex(
      (organization) => organization.id === id
    )
    if (index !== -1) {
      this.organizationList.organizations[index] = {
        ...this.organizationList.organizations[index],
        ...params.body,
      }
    }
    return response
  }

  async updateLanguageCode(id: number, code: string) {
    const params: RequestData = {
      body: {
        id,
        language_code: code,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationUpdateLanguageCode,
      params
    )
    const index = this.organizationList.organizations.findIndex(
      (organization) => organization.id === id
    )
    if (index !== -1) {
      this.organizationList.organizations[index] = {
        ...this.organizationList.organizations[index],
        ...params.body,
      }
    }
    return response
  }

  async updateRequired2FA(id: number, enabled: boolean) {
    const params: RequestData = {
      body: {
        id,
        required_2fa: enabled,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationUpdateRequired2FA,
      params
    )
    const index = this.organizationList.organizations.findIndex(
      (organization) => organization.id === id
    )
    if (index !== -1) {
      this.organizationList.organizations[index] = {
        ...this.organizationList.organizations[index],
        ...params.body,
      }
    }
    return response
  }

  async updateStatus(id: number, status: OrganizationStatusType) {
    const params: RequestData = {
      body: {
        id,
        status,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationUpdateStatus,
      params
    )
    const index = this.organizationList.organizations.findIndex(
      (organization) => organization.id === id
    )
    if (index !== -1) {
      this.organizationList.organizations[index] = {
        ...this.organizationList.organizations[index],
        ...params.body,
      }
    }
    return response
  }

  async updateExpireDate(id: number, date: string) {
    const params: RequestData = {
      body: {
        id,
        expireDate: date,
      },
    }
    const response = await createApiRequest<Organization | CodeMsgResponse>(
      apiConfig.organizationUpdateExpireData,
      params
    )
    const index = this.organizationList.organizations.findIndex(
      (organization) => organization.id === id
    )
    if (index !== -1) {
      this.organizationList.organizations[index] = {
        ...this.organizationList.organizations[index],
        ...params.body,
      }
    }
    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        size,
      },
    }
    const response = await createApiRequest<OrganizationList | CodeMsgResponse>(
      apiConfig.organizationList,
      params
    )
    const data = response.data as OrganizationList
    runInAction(() => {
      this.organizationList = {
        totalCount: data.totalCount,
        organizations: data.organizations,
      }
    })

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest<string | CodeMsgResponse>(
      apiConfig.organizationDelete,
      {
        queryParams: { id },
      }
    )
    runInAction(() => {
      this.organizationList = {
        totalCount: this.organizationList.totalCount - 1,
        organizations: this.organizationList.organizations.filter(
          (organization) => organization.id !== id
        ),
      }
    })
    return response
  }

  getOrganizationById(id: number) {
    const organization = this.organizationList.organizations.find(
      (organization) => organization.id === id
    )
    return organization
  }
}

export default new OrganizationStore()
