import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { RequestData } from 'services/request'
import { createApiRequest } from 'services/request'
import { Knowledge, KnowledgeList } from 'stores/models/knowledge'

class KnowledgeStore {
  knowledgeList: KnowledgeList = {
    totalCount: 0,
    knowledges: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async create(name: string, description: string) {
    const params: RequestData = {
      body: {
        knowledgeName: name,
        description,
      },
    }
    const response = await createApiRequest<Knowledge>(
      apiConfig.knowledgeCreate,
      params
    )
    return response
  }

  async update(id: number, name: string, description: string) {
    const params: RequestData = {
      body: {
        id,
        knowledgeName: name,
        description,
      },
    }

    const response = await createApiRequest(apiConfig.knowledgeUpdate, params)
    const index = this.knowledgeList.knowledges.findIndex(
      (knowledge) => knowledge.id === id
    )
    if (index !== -1) {
      runInAction(() => {
        this.knowledgeList.knowledges[index] = {
          ...this.knowledgeList.knowledges[index],
          ...params.body,
        }
      })
    }
    return response
  }

  async getList(page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        page,
        size,
      },
    }

    const response = await createApiRequest<KnowledgeList>(
      apiConfig.knowledgeList,
      params
    )

    runInAction(() => {
      this.knowledgeList = {
        totalCount: response.data.totalCount,
        knowledges: response.data.knowledges
      }
    })

    return response
  }

  async delete(id: number) {
    const response = await createApiRequest(apiConfig.knowledgeDelete, {
      queryParams: { id },
    })
    runInAction(() => {
      this.knowledgeList = {
        totalCount: this.knowledgeList.totalCount - 1,
        knowledges: this.knowledgeList.knowledges.filter(
          (knowledge) => knowledge.id !== id
        ),
      }
    })
    return response
  }
}

export default new KnowledgeStore()
