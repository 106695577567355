import * as monaco from 'monaco-editor';
import { loader } from '@monaco-editor/react';
loader.config({ monaco });

export const registerGolangTemplateLanguage = (monaco: typeof import('monaco-editor')) => {
  monaco.languages.register({ id: 'golangTemplate' });

  monaco.languages.setMonarchTokensProvider('golangTemplate', {
    defaultToken: '',
    tokenPostfix: '.gotmpl',

    brackets: [
        { open: '{{', close: '}}', token: 'delimiter.curly' },
        { open: '(', close: ')', token: 'delimiter.parenthesis' },
        { open: '[', close: ']', token: 'delimiter.square' },
        { open: '{', close: '}', token: 'delimiter.brace' }
    ],

    keywords: [
        'if', 'else', 'range', 'end', 'with', 'template', 'define', 'block',
        'and', 'or', 'not', 'eq', 'ne', 'lt', 'le', 'gt', 'ge'
    ],

    operators: [
        '=', '||', '&&', '!', '<', '>', '|', ':', '.', ',',
        '+', '-', '*', '/', '%', '^'
    ],

    symbols: /[=><!~?:&|+\-*\/\^%]+/,

    tokenizer: {
        root: [
            [/{{-?/, { token: 'delimiter.curly', next: '@templateExpression' }],
            [/[{}()[\]]/, '@brackets'],
            [/\/\*/, { token: 'comment', next: '@comment' }],
            [/\/\//, { token: 'comment', next: '@lineComment' }],
            [/./, 'text']
        ],

        templateExpression: [
            [/-?}}/, { token: 'delimiter.curly', next: '@pop' }],
            { include: 'expression' }
        ],

        expression: [
            [/\s+/, 'white'],
            [/[{}()[\]]/, '@brackets'],
            [/\|/, 'operator'],
            [/(if|else|range|end|with|template|define|block)\b/, 'keyword.control'],
            [/(and|or|not)\b/, 'keyword.operator'],
            [/(eq|ne|lt|le|gt|ge)\b/, 'keyword.operator'],
            [/\.[\w.]+/, 'variable.property'],  // New rule for {{.xxx.xxx}} format
            [/\$[\w]+/, 'variable.predefined'],
            [/[a-zA-Z_]\w*(?=\()/, 'function'],
            [/[a-zA-Z_]\w*/, 'variable'],
            [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
            [/'/, { token: 'string.quote', bracket: '@open', next: '@string_single' }],
            [/`/, { token: 'string.quote', bracket: '@open', next: '@string_backtick' }],
            [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
            [/\d+/, 'number'],
            [/[;,.]/, 'delimiter'],
            [/[=+\-*/%^&|!<>]/, 'operator'],
        ],

        string: [
            [/[^"]+/, 'string'],
            [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
        ],

        string_single: [
            [/[^']+/, 'string'],
            [/'/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
        ],

        string_backtick: [
            [/[^`]+/, 'string'],
            [/`/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
        ],

        comment: [
            [/[^/*]+/, 'comment'],
            [/\*\//, { token: 'comment', next: '@pop' }],
            [/./, 'comment']
        ],

        lineComment: [
            [/[^//]+/, 'comment'],
            [/$/, { token: 'comment', next: '@pop' }]
        ]
    }
});

monaco.languages.setLanguageConfiguration('golangTemplate', {
    brackets: [
        ['{', '}'],
        ['[', ']'],
        ['(', ')'],
        ['{{', '}}']
    ],
    autoClosingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '{{', close: '}}' },
        { open: '"', close: '"' },
        { open: "'", close: "'" },
        { open: "`", close: "`" }
    ],
    surroundingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '{{', close: '}}' },
        { open: '"', close: '"' },
        { open: "'", close: "'" },
        { open: "`", close: "`" }
    ],
    comments: {
        lineComment: '//',
        blockComment: ['/*', '*/']
    }
});
};
  
let disposableCompletionItemProvider: monaco.IDisposable | null = null;

export const registerGolangTemplateCompletionItemProvider = (
  monaco: typeof import('monaco-editor'),
  variables: { nodeID:string; name: string; nodeName: string; type: string }[]
) => {
  // Dispose of the previous completion item provider if it exists
  if (disposableCompletionItemProvider) {
    disposableCompletionItemProvider.dispose();
    disposableCompletionItemProvider = null;
  }

  // Register the new completion item provider
  disposableCompletionItemProvider = monaco.languages.registerCompletionItemProvider('golangTemplate', {
    provideCompletionItems: (model, position) => {
      const wordUntilPosition = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: wordUntilPosition.startColumn,
        endColumn: wordUntilPosition.endColumn,
      };

      return {
        suggestions: variables.map((variable, index) => ({
          label: variable.name,
          kind: monaco.languages.CompletionItemKind.Variable,
          insertText: `{{.${variable.name}}}`,
          range: range,
          sortText: index.toString().padStart(5, '0'),
          detail: `[Node: ${variable.nodeName}, Data Type: ${variable.type}]`,
          documentation: {
            value: `Variable from node: ${variable.nodeName}\nData Type: ${variable.type}\nNode ID: ${variable.nodeID}`,
            isTrusted: true
          }
        })),
      };
    },
  });
};

