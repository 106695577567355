import { OrganizationType } from './organization'
import { PermissionType } from './permission'

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
  PendingChangePassword = 'pending_change_password',
  PendingSetup2FA = 'pending_setup_2fa',
}

export const UserStatusOptions = [
  { value: UserStatus.Active, label: 'Active' },
  { value: UserStatus.Inactive, label: 'Inactive' },
  { value: UserStatus.PendingChangePassword, label: 'Pending Change Password' },
  { value: UserStatus.PendingSetup2FA, label: 'Pending Setup 2FA' },
]

export enum LoginUserAction {
  ChangePassword = 'change_password',
  Setup2FA = 'setup_2fa',
  Validate2FA = 'validate_2fa',
}

export interface User {
  id: number
  name: string
  email: string
  languageCode: string
  status: UserStatus
  organizationID: number
  roleID: number
  createdAt: string
  updatedAt: string
}

export interface UserList {
  totalCount: number
  users: User[]
}

export interface LoginUser extends Omit<User, 'updatedAt' | 'createdAt'> {
  roleName: string
  permissions: PermissionType[]
  organizationName: string
  organizationType: OrganizationType
  avatarID: number
  avatarUUID: string
  sessionID?: string
  action?: LoginUserAction
  expireDate: Date
  updatedAt: Date
  createdAt: Date
}

export enum TwoFactorAuthStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface TwoFactorAuthUser {
  id: number
  name: string
  email: string
  permissions: PermissionType[]
  organizationID: number
  roleID: number
  status: TwoFactorAuthStatus
  updatedAt: string
}

export interface AvatarResponse {
  avatarID: number
  avatarUUID: string
}
