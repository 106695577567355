import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Pagination,
  message,
  Skeleton,
  PaginationProps,
  Flex,
  Empty,
} from 'antd'
import agentStore from 'stores/agent'
import AgentCard, { ActionType } from './agent-card'
import { observer } from 'mobx-react'
import { getMessageFromError } from 'utils/common'
import AgentModal, { AgentModalProps } from './agent-modal'
import { AgentType } from 'stores/models/agent'
import { useOutlet } from 'react-router-dom'
import './index.css'

const DEFAULT_AGENT_DATA: AgentModalProps['data'] = {
  name: '',
  description: '',
  agentType: AgentType.ChatBot,
  iconId: 0,
}

const Agent: React.FC = () => {
  const outlet = useOutlet()
  const [agent, setAgent] =
    useState<AgentModalProps['data']>(DEFAULT_AGENT_DATA)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false)

  const load = async (page: number, size: number) => {
    setLoading(true)
    try {
      await agentStore.getList(page, size)
      setTotal(agentStore.agentList.totalCount)
    } catch (error) {
      const errorMessage = getMessageFromError(error)
      message.error(errorMessage)
    }
    setLoading(false)
  }

  useEffect(() => {
    load(page, size)
  }, [page, size])

  const onChange: PaginationProps['onChange'] = (page, size) => {
    setPage(page)
    setSize(size)
  }

  const handleFinish = (agentUuid?: string) => {
    setIsAgentModalOpen(false)
    load(page, size)
  }

  const handleCreateAgentClick = () => {
    setAgent(DEFAULT_AGENT_DATA)
    setIsAgentModalOpen(true)
  }

  return (
    <div className="main-layout-main">
      {outlet ?? (
        <>
          <div className="navigation">
            <div className="title">
              <h1>Agent</h1>
              <p>
                Efficiently manage your AI agents with real-time monitoring,
                updates, and performance tracking
              </p>
            </div>
            <div className="operation">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleCreateAgentClick}
              >
                Create Agent
              </Button>
            </div>
          </div>
          <div className="agent-list">
            <Skeleton loading={loading} active>
              {agentStore.agentList.agents.length > 0 ? (
                agentStore.agentList.agents.map((agent, i) => (
                  <AgentCard
                    key={i}
                    data={{
                      id: agent.id,
                      uuid: agent.uuid,
                      name: agent.agentName,
                      description: agent.description,
                      agentType: agent.agentType,
                      iconId: agent.agentIconID,
                      iconUuid: agent.agentIconUUID,
                      organizationId: agent.organizationId,
                    }}
                    onAction={(type, agent) => {
                      if (type === ActionType.Edit) {
                        setAgent({
                          id: agent.id,
                          uuid: agent.uuid,
                          name: agent.agentName,
                          description: agent.description,
                          agentType: agent.agentType,
                          iconId: agent.agentIconID,
                          iconUuid: agent.agentIconUUID,
                        })
                        setIsAgentModalOpen(true)
                      }
                    }}
                  />
                ))
              ) : (
                <Flex justify="start" style={{ width: '100%' }}>
                  <Empty />
                </Flex>
              )}
            </Skeleton>
          </div>
          <div className="pagination">
            {!loading ? (
              <Pagination
                defaultCurrent={page}
                defaultPageSize={size}
                total={total}
                onChange={onChange}
              />
            ) : null}
          </div>
          <AgentModal
            open={isAgentModalOpen}
            okText="Submit"
            data={agent}
            onFinish={handleFinish}
            onCancel={() => setIsAgentModalOpen(false)}
          />
        </>
      )}
    </div>
  )
}

export default observer(Agent)
