import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Breadcrumb, Button } from 'antd'
import { observer } from 'mobx-react'
import FileTable from './table'
import { Link, useLocation } from 'react-router-dom'
import { useExNavigate } from 'hooks/use-ex-navigate'

const Document: React.FC = () => {
  const location = useLocation()
  const navigate = useExNavigate()
  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <Breadcrumb
              items={[
                {
                  title: <Link to="/portal/knowledge">Knowledge</Link>,
                },
                {
                  title: 'Document',
                },
              ]}
            />
            <h1>Documents</h1>
            <p>
              All files of the Knowledge are shown here, and the entire
              Knowledge can be linked to citations.
            </p>
          </div>
          <div className="operation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                navigate('/portal/knowledge/document/upload', {
                  state: {
                    knowledge: location.state.knowledge,
                  },
                })
              }
            >
              Add Document
            </Button>
          </div>
        </div>
        <FileTable />
      </div>
    </>
  )
}

export default observer(Document)
