import { ReportHandler } from 'web-vitals'

const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (!onPerfEntry || !(onPerfEntry instanceof Function)) return
  const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals')
  getCLS(onPerfEntry)
  getFID(onPerfEntry)
  getFCP(onPerfEntry)
  getLCP(onPerfEntry)
  getTTFB(onPerfEntry)
}

const init = () => {
  // If you want to start measuring performance in your app, pass a function
  if (process.env.NODE_ENV === 'development') {
    reportWebVitals(console.info)
  }
}

export default init
