import React, { useEffect, useRef, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Space,
  message,
  Flex,
  Tag,
  BreadcrumbProps,
} from 'antd'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import { WorkflowRef } from './workflow'
import {
  SaveOutlined,
  DeliveredProcedureOutlined,
  PlayCircleOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { Agent } from 'stores/models/agent'
import useHeaderSubMenu from 'hooks/use-header-sub-menu'
import { HeaderTabsProps } from 'layouts/portal/header'
import { capitalizeFirstLetter, getQuery } from 'utils/common'
import chatStore from 'stores/chat'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'

import './index.css'

const Studio = () => {
  const location = useLocation()
  const { uuid } = getQuery<StudioPageQueryType>(location.search)
  const menuItems: HeaderTabsProps = [
    {
      key: 'workflow',
      label: 'Workflow',
      link: `/portal/agent/studio/workflow${location.search}`,
    },
    {
      key: 'evaluation',
      label: 'Evaluation',
      link: `/portal/agent/studio/evaluation${location.search}`,
    },
  ]
  useHeaderSubMenu(menuItems)
  const [breadcrumbItems, setBreadcrumbItems] = useState<
    BreadcrumbProps['items']
  >([])

  const workflowRef = useRef<WorkflowRef>(null)
  const [agent, setAgent] = useState<Agent>()
  const [hasChanges, setHasChanges] = useState(false)
  const [lastSaveTime, setLastSaveTime] = useState<dayjs.Dayjs | null>(null)

  const handleFetchAgentData = async () => {
    const agentRes = await chatStore.getAgent(uuid ?? '')
    setAgent(agentRes)
  }

  const compareWorkflows = () => {
    if (workflowRef.current && WorkflowStore.workflow) {
      const currentNodes = JSON.stringify(workflowRef.current.getNodes())
      const currentEdges = JSON.stringify(workflowRef.current.getEdges())
      const storedNodes = WorkflowStore.workflow.nodes
      const storedEdges = WorkflowStore.workflow.edges
      const hasChanges =
        currentNodes !== storedNodes || currentEdges !== storedEdges
      return hasChanges
    }
  }

  const handleSave = () => {
    if (workflowRef.current && WorkflowStore.workflow) {
      const nodes = workflowRef.current.getNodes()
      const edges = workflowRef.current.getEdges()
      WorkflowStore.save(
        WorkflowStore.workflow.id,
        JSON.stringify(edges),
        JSON.stringify(nodes)
      )
        .then(() => {
          message.success('Workflow saved successfully')
          setHasChanges(false)
          setLastSaveTime(dayjs())
        })
        .catch((error) => {
          message.error('Failed to save workflow')
        })
    } else {
      message.error('Workflow not initialized')
    }
  }

  const handlePreview = () => {
    if (workflowRef.current) {
      workflowRef.current.fitView()
    }
  }

  const handlePublish = () => {
    if (workflowRef.current && WorkflowStore.workflow) {
      const nodes = workflowRef.current.getNodes()
      const edges = workflowRef.current.getEdges()

      WorkflowStore.publish(
        WorkflowStore.workflow.id,
        JSON.stringify(edges),
        JSON.stringify(nodes)
      )
        .then(() => {
          message.success('Workflow published successfully')
          setHasChanges(false)
          setLastSaveTime(dayjs())
        })
        .catch((error) => {
          message.error('Failed to publish workflow')
        })
    } else {
      message.error('Workflow not initialized')
    }
  }

  const getTimeSinceLastSave = () => {
    return lastSaveTime ? lastSaveTime.fromNow() : 'Not saved yet'
  }

  const getWorkflowStatus = () => {
    return WorkflowStore.workflow?.status === 'draft'
      ? 'Unpublished'
      : 'Published'
  }

  useEffect(() => {
    handleFetchAgentData()

    let hasChanges = false
    const intervalId = setInterval(() => {
      const newHasChanges = compareWorkflows()
      if (newHasChanges !== undefined && newHasChanges !== hasChanges) {
        hasChanges = newHasChanges
        setHasChanges(hasChanges)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    // First is ''
    const allPaths = location.pathname.split('/')
    const basePath = allPaths[1]
    const subPath = allPaths.slice(2)

    const items = subPath.reduce<NonNullable<BreadcrumbProps['items']>>(
      (pre, cur, idx) => {
        const curItem =
          idx === 0
            ? {
                href: `/${basePath}/${cur}${location.search}`,
                title: `${capitalizeFirstLetter(cur)}`,
              }
            : { title: `${capitalizeFirstLetter(cur)}` }

        pre.push(curItem)
        return pre

        // There is no route to jump to
        // const prePath = subPath.slice(0, idx + 1).join('/')
        // pre.push({
        //   href: `/${basePath}/${prePath}${location.search}`,
        //   title: `${capitalizeFirstLetter(cur)}`,
        // })
        // return pre
      },
      []
    )

    setBreadcrumbItems(items)
  }, [location.pathname])

  useEffect(() => {
    setLastSaveTime((prevTime) => (prevTime ? dayjs(prevTime) : null))
  }, [hasChanges])

  useEffect(() => {
    if (agent && agent.id) {
      if (WorkflowStore.workflow?.updatedAt) {
        setLastSaveTime(dayjs(WorkflowStore.workflow.updatedAt))
      }
    }
  }, [agent, WorkflowStore.workflow?.updatedAt])

  return (
    <>
      <div className="navigation">
        <Flex className="title" align="center" gap={16}>
          <Breadcrumb items={breadcrumbItems} />
          {agent && (
            <Flex justify="center" gap={16} style={{ fontWeight: 200 }}>
              -
              <Space size="small">
                <span>{agent?.agentName}</span>
                <Tag>{agent?.agentType}</Tag>
              </Space>
            </Flex>
          )}
        </Flex>
        <div className="operation">
          <div className="operation-time">
            {`Saved ${getTimeSinceLastSave()} · ${getWorkflowStatus()}`}
          </div>
          <Space.Compact>
            <Button icon={<PlayCircleOutlined />} onClick={handlePreview}>
              Preview
            </Button>
            <Button
              icon={<SaveOutlined />}
              onClick={handleSave}
              disabled={!hasChanges}
            >
              Save
            </Button>
            <Button
              icon={<DeliveredProcedureOutlined />}
              onClick={handlePublish}
            >
              Publish
            </Button>
            <Button
              icon={<CommentOutlined />}
              onClick={() => WorkflowStore.selectPanel('PublishChannelPanel')}
            >
              Channel
            </Button>
          </Space.Compact>
        </div>
      </div>

      <div className="studio">
        <Outlet context={{ workflowRef } satisfies StudioOutletContextProps} />
      </div>
    </>
  )
}

type StudioOutletContextProps = {
  workflowRef: React.RefObject<WorkflowRef>
}

export const useStudioOutletContext = () => {
  return useOutletContext<StudioOutletContextProps>()
}

export default observer(Studio)
