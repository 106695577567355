export enum AgentType {
  ChatBot = 'chatbot',
  SmartApi = 'smart_api'
}

export interface Agent {
  id: number;
  uuid: string
  agentName: string
  agentType: AgentType
  description: string
  agentIconUUID: string
  agentIconID: number
  organizationId: number
}

export interface AgentList {
  totalCount: number
  agents: Agent[]
}
