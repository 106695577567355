import React, { useEffect, useState } from 'react'
import { Handle, Position, useReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { AimOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  NodeData,
  CustomNodeProps,
  InputField,
  OutputField,
  IntentBranch,
  DataType,
  InputType,
  BranchOutput,
} from 'views/portal/agent/studio/workflow/model'
import DeleteButton from '../../components/delete-button'
import { getNewNodeLabel } from '../../utils'
import CustomHandle from '../../components/custom-handle'

// Define default values
const defaultInputs: InputField[] = [
  {
    name: 'query',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
  {
    name: 'chat_context',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: false,
    reference: '',
  },
  {
    name: 'model_id',
    type: 'input' as InputType,
    dataType: 'Integer' as DataType,
    value: null,
    reference: '',
  },
  {
    name: 'max_tokens',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 1024,
    reference: '',
  },
  {
    name: 'temperature',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 1,
    reference: '',
  },
  {
    name: 'top_p',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 1,
    reference: '',
  },
  {
    name: 'conversation_context_number',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 20,
    reference: '',
  },
  {
    name: 'system_prompt',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
]

const defaultOutputs: OutputField[] = [
  {
    name: 'intentName',
    type: 'String' as DataType,
    description: 'Detected Intent',
    required: true,
    children: [],
  },
  {
    name: 'confidence',
    type: 'Number' as DataType,
    description: 'Confidence Score',
    required: true,
    children: [],
  },
]

const defaultIntentBranches: IntentBranch[] = [
  {
    priority: 1,
    intent: 'Query Weather',
    description:
      'The user is trying to ask for weather information for a certain location',
    samples: [
      {
        question: "What's the weather in Singapore today?",
        variables: [{ city: 'Singapore' }],
      },
    ],
    output: [
      {
        name: 'city',
        type: 'String' as DataType,
        description: 'City name',
        required: true,
        children: [],
      },
    ],
  },
  {
    priority: -1,
    intent: 'ELSE',
    description: 'Fallback intent',
    isElse: true,
    samples: [],
    output: [],
  },
  {
    priority: -2,
    intent: 'ERROR',
    description: 'Error intent',
    isError: true,
    samples: [],
    output: [
      {
        name: 'error',
        type: 'String' as DataType,
        description: 'Error message',
        required: true,
        children: [],
      },
    ],
  },
]

const defaultBranchOutput: { [key: string]: BranchOutput } = {
  branch_1: {
    branchID: '1',
    output: [
      {
        name: 'city',
        type: 'String',
        description: 'City name',
        required: true,
        children: [],
      },
    ],
  },
  branch_else: {
    branchID: '-1',
    output: [],
  },
  branch_error: {
    branchID: '-2',
    output: [
      {
        name: 'error',
        type: 'String',
        description: 'Error message',
        required: true,
        children: [],
      },
    ],
  },
}

const getDefaultNodeData = (label: string): NodeData => ({
  label,
  description: 'Classify user intent',
  input: defaultInputs,
  output: [],
  intentBranch: defaultIntentBranches,
  conditionBranch: [],
  branchOutput: defaultBranchOutput,
})

const IntentNode: React.FC<CustomNodeProps> = observer((node) => {
  const { getNodes } = useReactFlow()
  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || getDefaultNodeData('Intent Classifier')
  )

  useEffect(() => {
    if (!node.data.data) {
      const newLabel = getNewNodeLabel(getNodes(), 'Intent')
      const newNodeData = getDefaultNodeData(newLabel)
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  useEffect(() => {
    const updatedBranchOutput: { [key: string]: BranchOutput } = {}

    nodeData.intentBranch.forEach((branch) => {
      updatedBranchOutput[branch.intent] = {
        branchID: `${branch.priority}`,
        output: branch.output,
      }
    })

    setNodeData((prevData) => ({
      ...prevData,
      branchOutput: updatedBranchOutput,
    }))
  }, [nodeData.intentBranch])

  const sortedIntentBranches = [...nodeData.intentBranch].sort((a, b) => {
    if (a.priority >= 0 && b.priority >= 0) {
      return a.priority - b.priority
    } else if (a.priority >= 0) {
      return -1
    } else if (b.priority >= 0) {
      return 1
    } else {
      return b.priority - a.priority
    }
  })

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node intent-node active'
          : 'custom-node intent-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <AimOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <div className="node-intents">
        {sortedIntentBranches.map((branch, index) => (
          <div key={`intent-${index}`} className="intent-row">
            <div className="intent-name">{branch.intent}</div>
            <CustomHandle
              node={node}
              type="source"
              position={Position.Right}
              id={
                branch.priority === -1
                  ? 'branch_else'
                  : branch.priority === -2
                    ? 'branch_error'
                    : `branch_${branch.priority}`
              }
              style={{
                background: branch.isElse
                  ? '#ffa500'
                  : branch.isError
                    ? '#ff0000'
                    : '#1a192b',
              }}
              isConnectable={node.isConnectable}
            />
          </div>
        ))}
      </div>
      <DeleteButton nodeID={node.id} />
    </div>
  )
})

export default IntentNode
