import React, { useState, useEffect } from 'react'
import copy from 'clipboard-copy'
import { observer } from 'mobx-react'
import {
  Button,
  Space,
  Divider,
  Table,
  message,
  Tooltip,
  Popconfirm,
  Switch,
} from 'antd'
import {
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import ChannelStore from 'stores/channel'
import CreateEditChannel from './components/create-edit-channel'
import { Channel, ChannelType } from 'stores/models/channel'
import { useLocation } from 'react-router-dom'
import { getBubbleScriptTemplate } from './constant'
import CustomModal from 'components/custom-modal'

import styles from './index.scss'
import { getQuery } from 'utils/common'
import { StudioPageQueryType } from 'views/portal/agent/agent-card'

const PublishChannelPanel: React.FC = observer(() => {
  const location = useLocation()
  const { id } = getQuery<StudioPageQueryType>(location.search)
  const [isCreateEditMode, setIsCreateEditMode] = useState(false)
  const [editingChannel, setEditingChannel] = useState<Channel | undefined>(
    undefined
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [isTableLoading, setIsTableLoading] = useState(false)

  useEffect(() => {
    fetchChannels()
  }, [currentPage, pageSize])

  const fetchChannels = async () => {
    if (!Number(id)) return
    setIsTableLoading(true)
    try {
      await ChannelStore.getList(Number(id) ?? 0, currentPage, pageSize)
    } catch (error) {
      console.log(location)
      console.log(error)
      message.error('Failed to fetch channels')
    } finally {
      setIsTableLoading(false)
    }
  }

  const handleStatusChange = async (checked: boolean, channel: Channel) => {
    setIsTableLoading(true)
    try {
      await ChannelStore.update(
        channel.id,
        channel.agentID,
        channel.channelType,
        checked,
        channel.extraData || '',
        channel.title
      )
      message.success(
        `Channel ${checked ? 'enabled' : 'disabled'} successfully`
      )
      fetchChannels()
    } catch (error) {
      message.error('Failed to update channel status')
    } finally {
      setIsTableLoading(false)
    }
  }

  const handleOnSubmit = () => {
    fetchChannels()
    setIsCreateEditMode(false)
    setEditingChannel(undefined)
  }

  const handleEditChannel = (channel: Channel) => {
    setEditingChannel(channel)
    setIsCreateEditMode(true)
  }

  const handleDeleteChannel = async (id: number) => {
    setIsTableLoading(true)
    try {
      await ChannelStore.delete(id)
      message.success('Channel deleted successfully')
      await fetchChannels()
    } catch (error) {
      message.error('Failed to delete the channel')
    } finally {
      setIsTableLoading(false)
    }
  }

  const handleVisitChannel = (channel: Channel) => {
    window.open(`/chat/${channel.uuid}`, '_blank', 'noopener,noreferrer')
  }

  const handleCopyBubbleScript = (channel: Channel) => {
    const { uuid } = channel
    // TODO@Tanner: change the url to script link
    const scriptCode = getBubbleScriptTemplate({ url: './src/main.js', uuid })
    copy(scriptCode)
      .then(() => {
        message.success('copy success')
      })
      .catch(() => message.error('copy error'))
  }

  const formatChannelType = (type: ChannelType): string => {
    switch (type) {
      case ChannelType.ChatPage:
        return 'Chat Page'
      case ChannelType.ChatBubble:
        return 'Chat Bubble'
      default:
        return 'Unknown'
    }
  }

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const handleCancelChannelModal = () => {
    setIsCreateEditMode(false)
    setEditingChannel(undefined)
  }

  const columns = [
    { title: 'Channel Name', dataIndex: 'title', key: 'title' },
    {
      title: 'Channel Type',
      dataIndex: 'channelType',
      key: 'channelType',
      render: (type: ChannelType) => formatChannelType(type),
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      key: 'status',
      render: (enabled: boolean, record: Channel) => (
        <Switch
          checked={enabled}
          onChange={(checked) => handleStatusChange(checked, record)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (i: number, record: Channel) => (
        <Space size="middle">
          {record.channelType === ChannelType.ChatPage ? (
            <Tooltip title="Open">
              <Button
                icon={<LinkOutlined />}
                onClick={() => handleVisitChannel(record)}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Copy">
              <Button
                icon={<CopyOutlined />}
                onClick={() => handleCopyBubbleScript(record)}
              />
            </Tooltip>
          )}
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditChannel(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this channel?"
              onConfirm={() => handleDeleteChannel(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  if (WorkflowStore.selectedPanel !== 'PublishChannelPanel') return null

  return (
    <div className={styles.publishChannelWrapper}>
      {isCreateEditMode ? (
        <CreateEditChannel
          onCancel={handleCancelChannelModal}
          onSubmit={handleOnSubmit}
          channel={editingChannel}
        />
      ) : (
        // Panel
        <CustomModal
          wrapperClassName={styles.publishChannelPanelWrapper}
          header={
            <div className={styles.publishChannelPanelNavbarWrapper}>
              <div className={styles.publishChannelPanelNavbarLeft}>
                <div className={styles.publishChannelPanelNavbarText}>
                  Channel
                </div>
              </div>

              <Space>
                <Tooltip title="Create Channel">
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => setIsCreateEditMode(true)}
                  />
                </Tooltip>

                <Divider type="vertical" />

                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => WorkflowStore.selectPanel('')}
                />
              </Space>
            </div>
          }
        >
          <Table
            columns={columns}
            dataSource={ChannelStore.channelList.publishChannels}
            rowKey="id"
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: ChannelStore.channelList.totalCount,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            onChange={handleTableChange}
            loading={isTableLoading}
          />
        </CustomModal>
      )}
    </div>
  )
})

export default PublishChannelPanel
