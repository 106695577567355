import { Node, Edge } from '@xyflow/react'

export const getNewNodeID = (nodes: Node[], type: string): string => {
  let counter = 1
  while (true) {
    const newId = `${type}_${counter}`.toLowerCase()
    if (!nodes.some((node) => node.id === newId)) {
      return newId
    }
    counter++
  }
}

export const getNewEdgeID = (edges: Edge[], count: number = 1): string[] => {
  let counter = 1
  const newIds: string[] = []

  while (newIds.length < count) {
    const newId = `edge_${counter}`
    if (!edges.some((edge) => edge.id === newId) && !newIds.includes(newId)) {
      newIds.push(newId)
    }
    counter++
  }

  return newIds
}

export const getNewNodeLabel = (nodes: Node[], type: string): string => {
  let counter = 1
  while (true) {
    const newLabel = `${type} ${counter}`
    if (
      !nodes.some(
        (node) =>
          (node.data as { data?: { label?: string } })?.data?.label === newLabel
      )
    ) {
      return newLabel
    }
    counter++
  }
}
