import { makeAutoObservable, runInAction } from 'mobx'
import apiConfig from 'services/api'
import { createApiRequest, RequestData } from 'services/request'
import { CodeMsgResponse } from './models/common'

export interface File {
  id: number
  filename: string
  path: string
  size: number
  organizationID: number
  userID: number
  username: string
  CreatedAt: string
  UpdatedAt: string
}

export interface DocumentCreateType {
  knowledgeID: number
  embeddingModelID: number
  fileID: number
  segmentIdentifier: string
  chunkLength: number
  chunkOverlap: number
  replaceConsecutiveSpaces: boolean
  replaceNewlines: boolean
  replaceTabs: boolean
  replaceURL: boolean
  replaceEmail: boolean
}

export enum DocumentStatusType {
  Pending = 'pending',
  Processing = 'processing',
  Processed = 'processed',
  Failed = 'failed',
}

// Define the file document structure according to API response
export interface Document extends DocumentCreateType {
  id: number
  status: DocumentStatusType
  source: string
  hitCount: number
  documentName: string
  organizationID: number
  createdAt: string
  updatedAt: string
  file: File
}

// Adjust the DocumentList to handle file documents
interface DocumentList {
  totalCount: number
  documents: Document[]
}

class DocumentStore {
  documentList: DocumentList = {
    totalCount: 0,
    documents: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  async getList(knowledgeId: number, page: number, size: number) {
    const params: RequestData = {
      queryParams: {
        knowledgeID: knowledgeId,
        page,
        size,
      },
    }

    const response = await createApiRequest<DocumentList>(
      apiConfig.documentList,
      params
    )

    runInAction(() => {
      this.documentList = {
        totalCount: response.data.totalCount,
        documents: response.data.documents,
      }
    })

    return response
  }

  async create(
    knowledgeId: number,
    fileId: number,
    segmentIdentifier: string,
    chunkLength: number,
    chunkOverlap: number,
    replaceConsecutiveSpaces: boolean,
    replaceEmails: boolean,
    replaceNewlines: boolean,
    replaceTabs: boolean,
    replaceURLs: boolean
  ) {
    const defaultEmbeddingModelID = 1
    const params: { body: DocumentCreateType } = {
      body: {
        knowledgeID: knowledgeId,
        embeddingModelID: defaultEmbeddingModelID,
        fileID: fileId,
        segmentIdentifier,
        chunkLength,
        chunkOverlap,
        replaceConsecutiveSpaces,
        replaceNewlines,
        replaceTabs,
        replaceURL: replaceURLs,
        replaceEmail: replaceEmails,
      },
    }
    const response = await createApiRequest<Document | CodeMsgResponse>(
      apiConfig.documentCreate,
      params
    )
    return response
  }

  async delete(id: number) {
    const response = await createApiRequest(apiConfig.documentDelete, {
      queryParams: { id },
    })
    return response
  }

  async update(id: number, documentName: string) {
    const params: RequestData = {
      body: {
        documentName: documentName,
        id,
      },
    }

    const response = await createApiRequest(apiConfig.documentUpdate, params)
    const index = this.documentList.documents.findIndex(
      (document) => document.id === id
    )
    if (index !== -1) {
      runInAction(() => {
        this.documentList.documents[index].documentName = documentName
      })
    }
    return response
  }
}

export default new DocumentStore()
