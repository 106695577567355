import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { CustomNodeProps } from 'views/portal/agent/studio/workflow/model'
import {
  RobotOutlined,
  FilterOutlined,
  AimOutlined,
  BranchesOutlined,
  CodeOutlined,
  ApiOutlined,
  BookOutlined,
} from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { Menu } from 'antd'
import type { MenuProps } from 'antd'
import { FlowContext } from '../../context'
import { Node, MarkerType } from '@xyflow/react'
import { getNewEdgeID, getNewNodeID, getNewNodeLabel } from '../../utils'

interface ToolItem {
  icon: React.ReactNode
  name: string
  color: string
  type: string
}

interface ToolSection {
  title: string
  tools: ToolItem[]
}

const toolSections: ToolSection[] = [
  {
    title: 'Models',
    tools: [
      { icon: <RobotOutlined />, name: 'LLM', color: '#1890ff', type: 'LLM' },
      {
        icon: <FilterOutlined />,
        name: 'PII Filter',
        color: '#52c41a',
        type: 'PII',
      },
    ],
  },
  {
    title: 'Logic',
    tools: [
      {
        icon: <AimOutlined />,
        name: 'Intent Detector',
        color: '#faad14',
        type: 'Intent',
      },
      {
        icon: <CodeOutlined />,
        name: 'Code Interpreter',
        color: '#eb2f96',
        type: 'Code',
      },
      {
        icon: <BranchesOutlined />,
        name: 'Logic Condition',
        color: '#722ed1',
        type: 'Condition',
      },
    ],
  },
  {
    title: 'Knowledge',
    tools: [
      {
        icon: <ApiOutlined />,
        name: 'HTTP Request',
        color: '#13c2c2',
        type: 'HTTPRequest',
      },
      {
        icon: <BookOutlined />,
        name: 'Knowledge Retrieval',
        color: '#fa8c16',
        type: 'Knowledge',
      },
    ],
  },
]

const ToolNode: React.FC<CustomNodeProps> = observer(() => {
  const flowContext = useContext(FlowContext)

  if (!flowContext) {
    throw new Error('FlowContext not found')
  }

  const { nodes, setNodes, edges, setEdges } = flowContext

  const createNewNode = (type: string) => {
    const sourceNode = nodes.find(
      (node) => node.id === WorkflowStore.toolNodeSource
    )
    const targetNode = nodes.find(
      (node) => node.id === WorkflowStore.toolNodeTarget
    )

    if (!sourceNode || !targetNode) {
      console.error('Source or target node not found')
      return
    }

    const newNodeId = getNewNodeID(nodes, type)
    const newNode = {
      id: newNodeId,
      type,
      position: {
        x: sourceNode.position.x + 350,
        y: sourceNode.position.y,
      },
      data: {},
    }

    // Adjust positions of subsequent nodes
    const nodesToAdjust = findSubsequentNodes(sourceNode.id)
    const adjustedNodes = nodesToAdjust.map((node) => ({
      ...node,
      position: {
        ...node.position,
        x: node.position.x + 350,
      },
    }))

    // Create new edges considering handles
    let targetHandle = undefined
    if (type === 'Intent' || type === 'Condition') {
      targetHandle = 'branch_1'
    }
    const edgeIDs = getNewEdgeID(edges, 2)
    const sourceEdgeID = edgeIDs[0]
    const targetEdgeID = edgeIDs[1]
    const newSourceEdge = createEdge(
      sourceEdgeID,
      sourceNode.id,
      newNodeId,
      WorkflowStore.toolNodeSourceHandleID
    )
    const newTargetEdge = createEdge(
      targetEdgeID,
      newNodeId,
      targetNode.id,
      targetHandle,
      WorkflowStore.toolNodeTargetHandleID
    )

    // Update existing edge if necessary
    const existingEdge = edges.find(
      (edge) =>
        edge.source === sourceNode.id &&
        edge.target === targetNode.id &&
        edge.sourceHandle === WorkflowStore.toolNodeSourceHandleID &&
        edge.targetHandle === WorkflowStore.toolNodeTargetHandleID
    )

    let updatedEdges = edges.filter((edge) => edge !== existingEdge)
    updatedEdges = [...updatedEdges, newSourceEdge, newTargetEdge]

    setNodes([
      ...nodes.filter((node) => !nodesToAdjust.includes(node)),
      newNode,
      ...adjustedNodes,
    ])
    setEdges(updatedEdges)

    WorkflowStore.toggleToolNode(false, '', '', '', '')
  }

  const createEdge = (
    id: string,
    source: string,
    target: string,
    sourceHandle?: string | null | undefined,
    targetHandle?: string | null | undefined
  ) => {
    return {
      id: id,
      source,
      target,
      sourceHandle,
      targetHandle,
      type: 'custom',
      animated: true,
      style: { strokeWidth: 3 },
      markerEnd: { type: MarkerType.ArrowClosed },
    }
  }

  const findSubsequentNodes = (startNodeId: string): Node[] => {
    const result: Node[] = []
    let currentNodeId = startNodeId

    while (currentNodeId) {
      const edge = edges.find((e) => e.source === currentNodeId)
      if (edge) {
        const node = nodes.find((n) => n.id === edge.target)
        if (node) {
          result.push(node)
          currentNodeId = node.id
        } else {
          break
        }
      } else {
        break
      }
    }

    return result
  }

  const handleToolClick = (tool: ToolItem) => {
    createNewNode(tool.type)
  }

  const menuItems: MenuProps['items'] = toolSections.map((section, index) => ({
    key: `section-${index}`,
    type: 'group',
    label: section.title,
    children: section.tools.map((tool, toolIndex) => ({
      key: `${section.title}-${toolIndex}`,
      icon: <span style={{ color: tool.color }}>{tool.icon}</span>,
      label: tool.name,
      onClick: () => handleToolClick(tool),
    })),
  }))

  if (!WorkflowStore.toolNodeVisible) {
    return null
  }

  return (
    <div className={'custom-node tool-node nowheel nopan nodrag'}>
      <Menu mode="vertical" items={menuItems} style={{ width: '220px' }} />
    </div>
  )
})

export default ToolNode
