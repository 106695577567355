import { useContext, useEffect, useMemo, useState } from 'react'
import { App, Button, Input, Select, Slider, Flex, Switch } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { deepCopy } from 'utils/common'
import WorkflowStore from 'stores/workflow'
import InputField from '../../components/input-field'
import { InputField as InputFieldType, NodeData, DataType } from '../../model'
import { FlowContext } from '../../context'
import './knowledge-settings.css'

type SearchByData = {
  field: {
    type: 'content' | 'metadata'
    data: {
      dataType: DataType
      value: string
    }
  }
  query: {
    type: 'input' | 'reference'
    data: {
      dataType: DataType
      value: string
    }
  }
}

export type KnowledgeData = {
  knowledge_id?: number
  search_by: SearchByData[]
  search_type: string
  vector_score_threshold: number
  vector_top_k: number
  full_text_score_threshold: number
  full_text_top_k: number
  hybrid_weighted_score_ratio: number
  full_text_content_enabled: boolean
}

interface KnowledgeSettingsProps {
  knowledgeList: KnowledgeData[]
  onChange?: (knowledges: KnowledgeData[]) => void
}

interface SearchByProps {
  knowledgeData: KnowledgeData
  customSearchByData?: SearchByData[]
  fixCustomData?: boolean
  fieldDisabled?: boolean
  keepCustomData?: boolean
  onChange?: (searchByData: SearchByData[]) => void
}

const DEFAULT_KNOWLEDGE_DATA: KnowledgeData = {
  knowledge_id: undefined,
  search_by: [],
  search_type: 'vector_search',
  vector_score_threshold: 0.5,
  vector_top_k: 5,
  full_text_score_threshold: 0.5,
  full_text_top_k: 5,
  hybrid_weighted_score_ratio: 0.5,
  full_text_content_enabled: true,
}

const DEFAULT_SEARCH_BY_DATA: SearchByData[] = [
  {
    field: {
      type: 'content',
      data: {
        dataType: 'String',
        value: '',
      },
    },
    query: {
      type: 'input',
      data: {
        dataType: 'String',
        value: '',
      },
    },
  },
]

const SearchByComponent: React.FC<SearchByProps> = (props) => {
  const context = useContext(FlowContext)
  const currentNodeId = WorkflowStore.selectedNode?.id || ''
  const [searches, setSearches] = useState<SearchByData[]>(
    props.knowledgeData.search_by || []
  )

  useEffect(() => {
    const defaultData = props.customSearchByData
      ? deepCopy(props.customSearchByData)
      : deepCopy(DEFAULT_SEARCH_BY_DATA)
    const currentData = props.knowledgeData.search_by
    setSearches(currentData?.length > 0 ? currentData : defaultData)
  }, [props.knowledgeData.search_by, props.customSearchByData])

  useEffect(() => {
    if (!props.customSearchByData) {
      return
    }
    const defaultData = deepCopy(props.customSearchByData)
    const searchBy = props.knowledgeData.search_by
    defaultData.forEach((data, index) => {
      if (index >= searchBy.length) {
        return
      }
      if (data.field.type !== searchBy[index].field.type) {
        return
      }
      defaultData[index] = deepCopy(searchBy[index])
    })
    if (!props.fixCustomData) {
      for (let i = defaultData.length; i < searchBy.length; i++) {
        defaultData.push(deepCopy(searchBy[i]))
      }
    }
    setSearches(defaultData)
  }, [props.customSearchByData, props.fixCustomData])

  return (
    <Flex className="search-by-component" gap={12} vertical>
      {searches.map((searchBy, index) => (
        <Flex key={index} className="search-by-item" gap={4} vertical>
          <Flex gap={4}>
            <label>Field</label>
            <div className="search-by-type">
              <Select
                value={searchBy.field.type}
                onChange={(value) => {
                  const updatedSearches = [...searches]
                  updatedSearches[index].field.type = value
                  setSearches(updatedSearches)
                  props.onChange?.(updatedSearches)
                }}
                disabled={
                  props.fixCustomData ||
                  props.fieldDisabled ||
                  (props.keepCustomData && props.customSearchByData
                    ? index < props.customSearchByData.length
                    : false)
                }
                style={{ width: '100%' }}
              >
                <Select.Option key="content" value="content">
                  Content
                </Select.Option>
                <Select.Option key="metadata" value="metadata">
                  Metadata
                </Select.Option>
              </Select>
            </div>
            <div className="search-by-data">
              <Input
                value={searchBy.field.data.value}
                onChange={(e) => {
                  const updatedSearches = [...searches]
                  updatedSearches[index].field.data.value = e.target.value
                  setSearches(updatedSearches)
                  props.onChange?.(updatedSearches)
                }}
                disabled={searchBy.field.type === 'content'}
              />
            </div>
          </Flex>
          <Flex gap={4}>
            <label>Query</label>
            <div className="search-by-type">
              <Select
                value={searchBy.query.type}
                onChange={(value) => {
                  const updatedSearches = [...searches]
                  updatedSearches[index].query.type = value
                  updatedSearches[index].query.data.value = ''
                  setSearches(updatedSearches)
                  props.onChange?.(updatedSearches)
                }}
                style={{ width: '100%' }}
              >
                <Select.Option key="input" value="input">
                  Input
                </Select.Option>
                <Select.Option key="reference" value="reference">
                  Reference
                </Select.Option>
              </Select>
            </div>
            <div className="search-by-data">
              {searchBy.query.type === 'input' && (
                <Input
                  value={searchBy.query.data.value}
                  onChange={(e) => {
                    const updatedSearches = [...searches]
                    updatedSearches[index].query.data.value = e.target.value
                    setSearches(updatedSearches)
                    props.onChange?.(updatedSearches)
                  }}
                  placeholder="Input your value"
                />
              )}
              {searchBy.query.type === 'reference' && (
                <InputField
                  index={index}
                  item={{
                    name: 'query',
                    type: searchBy.query.type,
                    dataType: searchBy.query.data.dataType,
                    value: '',
                    reference: searchBy.query.data.value,
                  }}
                  currentNodeId={currentNodeId}
                  nodes={context?.nodes || []}
                  edges={context?.edges || []}
                  allowEditFieldName={false}
                  supportArray={false}
                  supportObject={false}
                  displayDataType={false}
                  displayFieldName={false}
                  displayInputType={false}
                  handleFormChange={(index, key, value) => {
                    const updatedSearches = [...searches]
                    updatedSearches[index].query.data.value = value
                    setSearches(updatedSearches)
                    props.onChange?.(updatedSearches)
                  }}
                />
              )}
            </div>
          </Flex>
          <Flex gap={4}>
            <label></label>
            <Button
              key={'delete_' + index}
              icon={<DeleteOutlined />}
              style={{ width: '72px' }}
              disabled={
                props.fixCustomData ||
                (props.keepCustomData && props.customSearchByData
                  ? index < props.customSearchByData.length
                  : false)
              }
              onClick={() => {
                const updatedSearches = [...searches]
                updatedSearches.splice(index, 1)
                setSearches(updatedSearches)
                if (index < props.knowledgeData.search_by.length) {
                  props.onChange?.(updatedSearches)
                }
              }}
            />
          </Flex>
        </Flex>
      ))}
      <Button
        icon={<PlusOutlined />}
        onClick={() => {
          const defaultData = deepCopy(DEFAULT_SEARCH_BY_DATA[0])
          const searchByData = props.knowledgeData.search_by
          defaultData.field.type =
            searchByData[searchByData.length - 1].field.type
          const updatedSearches = [...searches, defaultData]
          setSearches(updatedSearches)
          props.onChange?.(updatedSearches)
        }}
        type="dashed"
        disabled={props.fixCustomData}
        style={{ width: '100%' }}
      >
        Add
      </Button>
    </Flex>
  )
}

const KnowledgeSettings: React.FC<KnowledgeSettingsProps> = (props) => {
  const { message } = App.useApp()
  const [knowledgesLoading, setKnowledgesLoading] = useState(false)
  const [knowledgeList, setKnowledgeList] = useState(props.knowledgeList)

  useEffect(() => {
    // Load knowledges
    setKnowledgesLoading(true)
    WorkflowStore.getKnowledges(1, 100)
      .catch((error) => {
        console.error('Failed to retrieve knowledges:', error)
        message.error('Failed to retrieve knowledges.')
      })
      .finally(() => setKnowledgesLoading(false))
  }, [])

  useEffect(() => {
    setKnowledgeList(
      props.knowledgeList.length > 0
        ? props.knowledgeList
        : [deepCopy(DEFAULT_KNOWLEDGE_DATA)]
    )
  }, [props.knowledgeList])

  const renderKnowledgeSetting = (
    knowledgeData: KnowledgeData,
    index: number
  ) => {
    const hasVectorSearch = ['vector_search', 'hybrid_search'].includes(
      knowledgeData.search_type
    )

    const hasFullTextSearch = ['full_text_search'].includes(
      knowledgeData.search_type
    )
    return (
      <div className="knowledge-setting-item">
        <div className="template-editor-item">
          <Flex justify="space-between">
            <label>Knowledge</label>
            <Button
              key={'delete_' + index}
              icon={<DeleteOutlined />}
              style={{ width: '48px' }}
              onClick={() => {
                const updatedKnowledges = [...knowledgeList]
                updatedKnowledges.splice(index, 1)
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
            />
          </Flex>
        </div>
        <div className="template-editor-item">
          <div className="element">
            <Select
              value={knowledgeData.knowledge_id}
              onChange={(value) => {
                const updatedKnowledges = [...knowledgeList]
                updatedKnowledges[index].knowledge_id = value
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
              style={{ width: '100%' }}
              loading={knowledgesLoading}
              showSearch
              optionFilterProp="children"
            >
              {WorkflowStore.knowledgeList.knowledges.map((knowledge) => (
                <Select.Option key={knowledge.id} value={knowledge.id}>
                  {knowledge.knowledgeName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="template-editor-item">
          <label>Search Type</label>
          <div className="element">
            <Select
              value={knowledgeData.search_type}
              onChange={(value) => {
                const updatedKnowledges = [...knowledgeList]
                updatedKnowledges[index].search_type = value
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
              style={{ width: '100%' }}
            >
              <Select.Option key="vector_search" value="vector_search">
                Vector Search
              </Select.Option>
              <Select.Option key="full_text_search" value="full_text_search">
                Full Text Search
              </Select.Option>
              <Select.Option key="hybrid_search" value="hybrid_search">
                Hybrid Search
              </Select.Option>
            </Select>
          </div>
        </div>
        <div className="setting-item">
          <label>Semantic Search Score Threshold</label>
          <div className="element">
            <Slider
              value={
                hasVectorSearch
                  ? knowledgeData.vector_score_threshold
                  : knowledgeData.full_text_score_threshold
              }
              onChange={(value) => {
                const updatedKnowledges = [...knowledgeList]
                if (hasVectorSearch) {
                  updatedKnowledges[index].vector_score_threshold = value
                } else if (hasFullTextSearch) {
                  updatedKnowledges[index].full_text_score_threshold = value
                }
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
              min={0.01}
              max={1}
              step={0.01}
              style={{ width: '205px' }}
            />
          </div>
        </div>
        <div className="setting-item">
          <label>Semantic Search Top K</label>
          <div className="element">
            <Slider
              value={
                hasVectorSearch
                  ? knowledgeData.vector_top_k
                  : knowledgeData.full_text_top_k
              }
              onChange={(value) => {
                const updatedKnowledges = [...knowledgeList]
                if (hasVectorSearch) {
                  updatedKnowledges[index].vector_top_k = value
                } else if (hasFullTextSearch) {
                  updatedKnowledges[index].full_text_top_k = value
                }
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
              min={1}
              max={50}
              step={1}
              style={{ width: '205px' }}
            />
          </div>
        </div>
        {['hybrid_search'].includes(knowledgeData.search_type) && (
          <>
            <div className="setting-item">
              <label>Full Text Search Score Threshold</label>
              <div className="element">
                <Slider
                  value={knowledgeData.full_text_score_threshold}
                  onChange={(value) => {
                    const updatedKnowledges = [...knowledgeList]
                    updatedKnowledges[index].full_text_score_threshold = value
                    setKnowledgeList(updatedKnowledges)
                    props.onChange?.(updatedKnowledges)
                  }}
                  min={0.01}
                  max={1}
                  step={0.01}
                  style={{ width: '205px' }}
                />
              </div>
            </div>
            <div className="setting-item">
              <label>Semantic to Full Text Weighted Score Ratio</label>
              <div className="element">
                <Slider
                  value={knowledgeData.hybrid_weighted_score_ratio}
                  onChange={(value) => {
                    const updatedKnowledges = [...knowledgeList]
                    updatedKnowledges[index].hybrid_weighted_score_ratio = value
                    setKnowledgeList(updatedKnowledges)
                    props.onChange?.(updatedKnowledges)
                  }}
                  min={0.01}
                  max={1}
                  step={0.01}
                  style={{ width: '205px' }}
                />
              </div>
            </div>
          </>
        )}
        {['full_text_search'].includes(knowledgeData.search_type) && (
          <div className="setting-item">
            <label>Content Enabled</label>
            <Switch
              checked={knowledgeData.full_text_content_enabled}
              onChange={(checked) => {
                const updatedKnowledges = [...knowledgeList]
                updatedKnowledges[index].full_text_content_enabled = checked
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
            />
          </div>
        )}
        <div className="template-editor-item">
          <label>Search By</label>
          <div className="element">
            <SearchByComponent
              knowledgeData={knowledgeData}
              customSearchByData={DEFAULT_SEARCH_BY_DATA}
              onChange={(searchBy) => {
                const updatedKnowledges = [...knowledgeList]
                updatedKnowledges[index].search_by = searchBy
                setKnowledgeList(updatedKnowledges)
                props.onChange?.(updatedKnowledges)
              }}
              keepCustomData
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {knowledgeList.map((knowledge, index) =>
        renderKnowledgeSetting(knowledge, index)
      )}
      <Button
        icon={<PlusOutlined />}
        onClick={() => {
          setKnowledgeList([...knowledgeList, deepCopy(DEFAULT_KNOWLEDGE_DATA)])
        }}
        type="dashed"
        style={{ width: '100%' }}
      >
        Add Knowledge
      </Button>
    </>
  )
}

export default observer(KnowledgeSettings)
