import React, { useEffect, useState } from 'react'
import { Handle, Position, useReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { BookOutlined } from '@ant-design/icons'
import WorkflowStore from 'stores/workflow'
import { observer } from 'mobx-react'
import {
  OutputField,
  InputField,
  DataType,
  InputType,
  CustomNodeProps,
  NodeData,
} from 'views/portal/agent/studio/workflow/model'
import DeleteButton from '../../components/delete-button'
import { getNewNodeLabel } from '../../utils'
import CustomHandle from '../../components/custom-handle'

// Define default values
const defaultInputs: InputField[] = [
  {
    name: 'query',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: '',
    reference: '',
  },
  {
    name: 'search_type',
    type: 'input' as InputType,
    dataType: 'String' as DataType,
    value: 'vector_search',
    reference: '',
  },
  {
    name: 'vector_score_threshold',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 0.5,
    reference: '',
  },
  {
    name: 'vector_top_k',
    type: 'input' as InputType,
    dataType: 'Integer' as DataType,
    value: 5,
    reference: '',
  },
  {
    name: 'full_text_score_threshold',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 0.5,
    reference: '',
  },
  {
    name: 'full_text_top_k',
    type: 'input' as InputType,
    dataType: 'Integer' as DataType,
    value: 5,
    reference: '',
  },
  {
    name: 'knowledges',
    type: 'input' as InputType,
    dataType: 'Array<Integer>' as DataType,
    value: [],
    reference: '',
  },
  {
    name: 'rerank_enabled',
    type: 'input' as InputType,
    dataType: 'Boolean' as DataType,
    value: false,
    reference: '',
  },
  {
    name: 'rerank_score_threshold',
    type: 'input' as InputType,
    dataType: 'Number' as DataType,
    value: 0.5,
    reference: '',
  },
  {
    name: 'rerank_model',
    type: 'input' as InputType,
    dataType: 'Integer' as DataType,
    value: '',
    reference: '',
  },
  {
    name: 'rerank_count',
    type: 'input' as InputType,
    dataType: 'Integer' as DataType,
    value: 10,
    reference: '',
  },
]

const defaultOutputs: OutputField[] = [
  {
    name: 'result',
    type: 'Array<Object>' as DataType,
    description: 'Knowledge search results',
    required: true,
    children: [
      {
        name: '_id',
        type: 'String' as DataType,
        description: 'Result ID',
        required: true,
        children: [],
      },
      {
        name: 'content',
        type: 'String' as DataType,
        description: 'Result content',
        required: true,
        children: [],
      },
      {
        name: 'knowledge_id',
        type: 'Integer' as DataType,
        description: 'Knowledge ID',
        required: true,
        children: [],
      },
      {
        name: 'file_id',
        type: 'Integer' as DataType,
        description: 'File ID',
        required: true,
        children: [],
      },
      {
        name: 'document_id',
        type: 'Integer' as DataType,
        description: 'Document ID',
        required: true,
        children: [],
      },
      {
        name: 'metadata',
        type: 'Object' as DataType,
        description: 'Result metadata',
        required: true,
        children: [],
      },
    ],
  },
]

const getDefaultNodeData = (label: string): NodeData => ({
  label,
  description: 'Search and retrieve relevant knowledge',
  input: defaultInputs,
  output: defaultOutputs,
  intentBranch: [],
  conditionBranch: [],
  branchOutput: {},
})
const KnowledgeNode: React.FC<CustomNodeProps> = observer((node) => {
  const { getNodes } = useReactFlow()
  const [nodeData, setNodeData] = useState<NodeData>(
    node.data.data || getDefaultNodeData('Knowledge Retrieval')
  )

  useEffect(() => {
    if (!node.data.data) {
      const newLabel = getNewNodeLabel(getNodes(), 'Knowledge')
      const newNodeData = getDefaultNodeData(newLabel)
      setNodeData(newNodeData)
      if (node.data.onChange) {
        node.data.onChange(node.id, { data: newNodeData })
      }
    } else if (node.data.data.label !== nodeData.label) {
      setNodeData(node.data.data)
    }
  }, [node.data, getNodes])

  useEffect(() => {
    if (node.selected) {
      WorkflowStore.selectNode(node)
    }
  }, [node.selected])

  return (
    <div
      className={
        node.id === WorkflowStore.selectedNode?.id
          ? 'custom-node knowledge-node active'
          : 'custom-node knowledge-node'
      }
    >
      <CustomHandle
        node={node}
        type="target"
        position={Position.Left}
        isConnectable={node.isConnectable}
      />
      <div className="node-title">
        <div className="icon">
          <BookOutlined />
        </div>
        <div className="text">{nodeData.label}</div>
      </div>
      <div className="node-desc">{nodeData.description}</div>
      <DeleteButton nodeID={node.id} />
      <CustomHandle
        node={node}
        type="source"
        position={Position.Right}
        isConnectable={node.isConnectable}
      />
    </div>
  )
})

export default KnowledgeNode
