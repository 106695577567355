import React, { ChangeEvent, memo } from 'react'
import {
  BankOutlined,
  CloseOutlined,
  SmallDashOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  FunctionOutlined,
} from '@ant-design/icons'

import WorkflowStore from 'stores/workflow'
import CustomModal, { CustomModalType } from 'components/custom-modal'
import { Button, Divider, Input, Space } from 'antd'
import { NodeData } from '../../model'
import { NODE_CONFIG } from './constant'

import styles from './index.scss'

import type { NodeTypeEnum } from './constant'

type NodeSidebarType = {
  children?: React.ReactNode
  nodeData?: NodeData

  nodeType: NodeTypeEnum
  onChangeNodeName?: (e: ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
} & CustomModalType

const NodeSidebar = (props: NodeSidebarType) => {
  const {
    children,
    nodeData,
    nodeType,
    onChangeNodeName,
    onClose,
    ...resetProps
  } = props

  const config = Reflect.get(NODE_CONFIG, nodeType)

  if (!config) return <></>

  return (
    <CustomModal
      header={
        <div className="custom-node-sidebar-title">
          <div className="custom-node-sidebar-title-left">
            <div className="icon">{config.icon}</div>
            <div className="text">
              <Input
                className="editable-label"
                value={nodeData?.label ?? ''}
                onChange={onChangeNodeName}
              />
            </div>
          </div>
          <Space>
            <Button type="text" icon={<SmallDashOutlined />} />
            <Divider type="vertical" />
            <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
          </Space>
        </div>
      }
      wrapperClassName={styles.nodeSidebarWrapper}
      contentWrapperClassName={styles.nodeSidebarContentWrapper}
      {...resetProps}
    >
      {children}
    </CustomModal>
  )
}

export default memo(NodeSidebar)
