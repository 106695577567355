import { Button, Form, Input, message, Modal, Switch } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import userStore from 'stores/user'
import roleStore from 'stores/role'
import permissionStore from 'stores/permission'
import { getMessageFromError } from 'utils/common'
import { observer } from 'mobx-react'
import { Role } from 'stores/models/role'
import { PermissionType } from 'stores/models/permission'
import PermissionsTree, {
  convertPermissionTreeData,
} from '../../permission/permissions-tree'

export interface RoleModalProps {
  data: {
    id?: number
    organizationId?: number
    name: string
    owner: boolean
    permissions: PermissionType[]
  }
  title?: ReactNode
  okText?: string
  open?: boolean
  onFinish?: (roleId?: number) => void
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const RoleModal: React.FC<RoleModalProps> = (props) => {
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isOwner, setIsOwner] = useState(props.data.owner)
  const isAdmin = useMemo(
    () => userStore.loginUser?.roleName.toLowerCase() === 'admin',
    [userStore.loginUser?.roleName]
  )

  const permissions = useMemo(
    () =>
      convertPermissionTreeData(
        permissionStore.permissionTree?.permissions ?? [],
        {
          disableCheckbox: false,
        }
      ),
    [permissionStore.permissionTree?.permissions]
  )

  useEffect(() => {
    if (props.open) {
      setIsOwner(props.data.owner)
      form.setFieldsValue(props.data)
    }
  }, [props.open, form, props.data])

  const handleSave = async (values: RoleModalProps['data']) => {
    setConfirmLoading(true)
    try {
      if (props.data.id == undefined) {
        const response = await roleStore.create(
          values.name,
          values.owner,
          values.permissions
        )
        const data = response.data as Role
        return data.id
      } else {
        await roleStore.update(
          props.data.id,
          values.name,
          values.owner,
          values.permissions
        )
      }
      return props.data.id
    } catch (error) {
      message.error(getMessageFromError(error))
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleFinish = async (values: RoleModalProps['data']) => {
    const roleId = await handleSave(values)
    if (roleId === undefined) {
      return
    }
    if (props.onFinish) {
      props.onFinish(roleId)
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    form.resetFields()
    if (props.onCancel) {
      props.onCancel(e)
    }
  }

  return (
    <Modal
      title={props.title ?? (props.data.id ? 'Role Edit' : 'Role Create')}
      open={props.open}
      okText="Save"
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      footer={[]}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        style={{ maxWidth: 620 }}
        initialValues={props.data}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="owner"
          label="Owner"
          rules={[{ required: true, message: '' }]}
        >
          <Switch
            disabled={!isAdmin}
            onChange={(value) => {
              setIsOwner(value)
            }}
          />
        </Form.Item>
        <Form.Item
          name="permissions"
          label="Permissions"
          rules={[{ required: true, message: '' }]}
          style={{ display: isOwner ? 'none' : undefined }}
        >
          <PermissionsTree
            permissions={permissions}
            checkedKeys={props.data.permissions}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" shape="round">
            {props.okText || 'Ok'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default observer(RoleModal)
