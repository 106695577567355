import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Form, Input } from 'antd'
import DynamicForm, { DynamicFormRefProps } from 'components/dynamic-form'
import {
  convertIntentParameterToField,
  convertFieldToIntentParameter,
} from './converter'
import { Intent } from 'stores/models/intent'
import { observer } from 'mobx-react'
const { TextArea } = Input

interface CreateIntentGeneralProps {
  data: Intent
  style?: React.CSSProperties
}

export interface IntentGeneralFormRefProps {
  resetFields: () => void
  validateFields: () => Promise<void>
  getFieldsValue: () => Promise<{
    intent: string
    description: string
    parameters: any[]
  }>
}

const IntentGeneralForm: ForwardRefRenderFunction<
  IntentGeneralFormRefProps,
  CreateIntentGeneralProps
> = (props, ref) => {
  const [form] = Form.useForm()
  const dynamicFormRef = useRef<DynamicFormRefProps>(null)

  useEffect(() => {
    form.resetFields()
    dynamicFormRef.current?.resetFields()
  }, [props.data])

  useImperativeHandle(ref, () => ({
    resetFields: () => {
      form.resetFields()
      dynamicFormRef.current?.resetFields()
    },
    validateFields: async () => {
      await form.validateFields()
      const errors = dynamicFormRef.current?.validateFields()
      if (errors && errors.length > 0) {
        throw new Error('Validation failed')
      }
    },
    getFieldsValue: () => {
      const values = form.getFieldsValue()
      if (dynamicFormRef.current) {
        const dynamicFields = dynamicFormRef.current.getFieldsValue()
        const parameters = convertFieldToIntentParameter(dynamicFields)
        return {
          ...values,
          parameters,
        }
      }
    },
  }))

  const fields = convertIntentParameterToField(props.data?.parameters)

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={props.data}
      style={{ ...props.style }}
    >
      <Form.Item
        name="intent"
        label="Intent"
        rules={[{ required: true, message: 'Please input the intent!' }]}
      >
        <Input placeholder="Query Weather" />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <TextArea />
      </Form.Item>
      <Form.Item label="Output Variables">
        <DynamicForm
          ref={dynamicFormRef}
          maxLevel={3}
          allowObject={false}
          allowArray={true}
          value={fields}
        />
      </Form.Item>
    </Form>
  )
}

export default observer(forwardRef(IntentGeneralForm))
