import { memo, useContext, useState } from 'react'
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  MarkerType,
  Node,
} from '@xyflow/react'
import cls from 'classnames'
import Icon from '@ant-design/icons'
import { Popover } from 'antd'
import SvgPlus from 'assets/images/plus.svg'
import ToolMenu from '../tool-menu'
import { FlowContext } from '../../context'

import { CustomNodeTypes } from '../../nodes/types'
import { getNewEdgeID, getNewNodeID } from '../../utils'

import styles from './index.scss'

const CustomEdge = (props: EdgeProps) => {
  const {
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    source,
    target,
    sourceHandleId,
    targetHandleId,
  } = props

  const flowContext = useContext(FlowContext)
  if (!flowContext) return null
  const { nodes, setNodes, edges, setEdges } = flowContext

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const handleToolClick = (toolKey: CustomNodeTypes) => {
    if (!toolKey) return
    const sourceNode = nodes.find((n) => n.id === source)
    const targetNode = nodes.find((n) => n.id === target)
    if (!sourceNode || !targetNode) return

    // create node
    const newNode = {
      id: getNewNodeID(nodes, toolKey),
      type: toolKey,
      position: {
        x: sourceNode.position.x + 350,
        y: sourceNode.position.y,
      },
      data: {},
    }
    // Adjust positions of subsequent nodes
    const nodesToAdjust = findSubsequentNodes(sourceNode.id)
    const adjustedNodes = nodesToAdjust.map((node) => ({
      ...node,
      position: { ...node.position, x: node.position.x + 350 },
    }))

    // create edge
    const [sourceEdgeID, targetEdgeID] = getNewEdgeID(edges, 2)

    const multipleHandleNodes: CustomNodeTypes[] = ['Intent', 'Condition']
    const newSourceEdge = createNewEdge(
      sourceEdgeID,
      sourceNode.id,
      newNode.id,
      sourceHandleId
    )

    const newTargetEdge = createNewEdge(
      targetEdgeID,
      newNode.id,
      targetNode.id,
      multipleHandleNodes.includes(toolKey) ? 'branch_1' : targetHandleId
    )

    // remove the old edge
    const existingEdge = edges.find(
      (edge) =>
        edge.source === sourceNode.id &&
        edge.target === targetNode.id &&
        edge.sourceHandle === sourceHandleId &&
        edge.targetHandle === targetHandleId
    )

    setNodes([
      ...nodes.filter((node) => !nodesToAdjust.includes(node)),
      newNode,
      ...adjustedNodes,
    ])

    setEdges([
      ...edges.filter((edge) => edge !== existingEdge),
      newSourceEdge,
      newTargetEdge,
    ])
  }

  const createNewEdge = (
    id: string,
    source: string,
    target: string,
    sourceHandle?: string | null | undefined,
    targetHandle?: string | null | undefined
  ) => {
    return {
      id: id,
      source,
      target,
      sourceHandle,
      targetHandle,
      type: 'custom',
      animated: true,
      style: { strokeWidth: 3 },
      markerEnd: { type: MarkerType.ArrowClosed },
    }
  }

  const findSubsequentNodes = (startNodeId: string): Node[] => {
    const result: Node[] = []
    let currentNodeId = startNodeId

    while (currentNodeId) {
      const edge = edges.find((e) => e.source === currentNodeId)
      if (!edge) break
      const node = nodes.find((n) => n.id === edge.target)
      if (!node) break
      result.push(node)
      currentNodeId = node.id
    }

    return result
  }

  return (
    <>
      <BaseEdge
        {...props}
        path={edgePath}
        className="react-flow__edge-path custom-edge"
      />
      <EdgeLabelRenderer>
        <div
          className={cls('nodrag', 'nopan', styles.customEdge)}
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <Popover
            placement="right"
            trigger={['hover', 'click']}
            mouseEnterDelay={1}
            destroyTooltipOnHide
            content={<ToolMenu onClick={handleToolClick} />}
          >
            <Icon className={styles.customEdgeLabelMid} component={SvgPlus} />
          </Popover>
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default memo(CustomEdge)
