import React, { useEffect, useMemo } from 'react'
import { App } from 'antd'
import { observer } from 'mobx-react'
import PermissionsTree, { convertPermissionTreeData } from './permissions-tree'
import permissionStore from 'stores/permission'
import { getMessageFromError } from 'utils/common'
import { OrganizationType } from 'stores/models/organization'
import './index.css'

const PermissionPage: React.FC = () => {
  const { message, modal } = App.useApp()
  const permissions = useMemo(
    () =>
      convertPermissionTreeData(
        permissionStore.permissionTree?.permissions ?? [],
        {
          disableCheckbox: false,
        }
      ),
    [permissionStore.permissionTree?.permissions]
  )

  const loadPermissions = async () => {
    try {
      await permissionStore.getTree(OrganizationType.Tenant)
    } catch (error) {
      message.error(getMessageFromError(error))
    }
  }

  useEffect(() => {
    loadPermissions()
  }, [])

  return (
    <>
      <div className="main-layout-main">
        <div className="navigation">
          <div className="title">
            <h1>Permission</h1>
            <p>This page displays the permissions available in the system.</p>
          </div>
          <div className="operation"></div>
        </div>
        <div className="permissions-tree">
          <PermissionsTree permissions={permissions} defaultExpandAll={true} />
        </div>
      </div>
    </>
  )
}

export default observer(PermissionPage)
